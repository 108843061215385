import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Pagination, Table, Icon, Tooltip, Popover, Select } from "antd";
import { isArray, isEmpty, map } from "lodash";
//Files
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { Util } from "../../util/util";
import {
	adminDownloadArtistAction,
	getArtistsList,
	setArtistFeaturedAction,
	toggleArtistInfoDialog,
	updateArtistInfoAction,
	blockArtistAction,
} from "../../redux/actions/ManageArtistsAction";
// SCSS
import "./ManageArtists.scss";
import KycDocumentDialog from "../ManageUsers/KycDocumentDialog/KycDocumentDialog";
import {
	getUserKycUser,
	toggleKYCDialog,
	approveKYC,
	updateRoleChangeAction,
	updateRoleFromManageArtistChangeAction,
} from "../../redux/actions/ManageusersAction";
import AddCareerLevelDialog from "./AddCareerLevelDialog/AddCareerLevelDialog";
import { getAllCountryListAction } from "../../redux/actions/CountriesTaxAction";
import MultiSelectDropdown from "../../components/MultiSelectDropdown/MultiSelectDropdown";
import ConfirmationMessageDialog from "../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";
import { authorizedGet } from "../../util/apiConfig";
const { Option } = Select;

const kycStatusList = [
	{ label: "KYC All Status", value: null },
	{ label: "Pending", value: 0 },
	{ label: "Completed", value: 1 },
	{ label: "Approved", value: 2 },
	{ label: "Not Applicable", value: 3 },
];

const artistStatusList = [
	{ label: "Artist All Status", value: null },
	{ label: "Pending", value: 0 },
	{ label: "Approved", value: 1 },
	{ label: "Rejected", value: 2 },
	{ label: "Blocked", value: 3 },
];
class ManageArtists extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchValue: "",
			pageNumValue: 1,
			userId: "",
			orderSort: 1,
			orderOption: 1,
			selectedRecordToPerformAction: null,
			selectedCoutry: [],
			kycFilter: null,
			artistStatus: null,
			unblockconfirmationDialog: false,
			approveconfirmationDialog: false,
			rejectconfirmationDialog: false,
			blockconfirmationDialog: false,
			status: null,
			userId: "",
			allLiaisonsList: [],
			selectedLiaison: null,
		};
	}

	componentDidMount() {
		// List Sellers by fetching details from URL - When Page Loads
		this.setSearchParams();
		this.props.getAllCountryListAction();
		this.getLiaisonsList();
	}

	getLiaisonsList = () => {
		authorizedGet("UserManagment/api/Artists/GetAllLiaisons").then((res) => {
			console.log("🚀 ~ file: ManageArtists.js ~ line 82 ~ ManageArtists ~ authorizedGet ~ res", res);
			if (res.resultCode === 1) {
				this.setState({
					allLiaisonsList: res.response && res.response.data,
				});
			}
		});
	};

	componentDidUpdate(prevProps) {
		// List Sellers by fetching details from URL - When URL change occur
		const locationChanged = this.props.location !== prevProps.location;
		if (locationChanged) {
			this.setSearchParams();
		}
	}

	render() {
		const { artistsListLoader, savedArtistsListCount, savedArtistsList, artistEditDialog } = this.props.manageArtistsReducer;
		const { kycDocumentResult, kycDialog } = this.props.manageUsersReducer;
		const options = { year: "numeric", month: "long", day: "numeric" };

		const columns = [
			{
				title: ({ sortOrder, sortColumn, filters }) => {
					if (sortOrder === "descend") return <Tooltip title="Click to cancel sort">Name</Tooltip>;
					else if (sortOrder === "ascend") return <Tooltip title="Click sort by descend">Name</Tooltip>;
					else return <Tooltip title="Click sort by ascend">Name</Tooltip>;
				},
				className: "sorter-no-tooltip",
				dataIndex: "name",
				key: "name",
				render: (text, record) => (
					<span className="colWrapper" onClick={() => this.props.history.push("/manage-artists/user-profile/" + record.userId)}>
						{record.profilePhoto ? <img className="colImage" src={record.profilePhoto} /> : <Icon className="icon-image" type="user" />}
						<Tooltip title={text ? text : "Nil"}>
							<p className="colText">{text ? text : "Nil"}</p>
						</Tooltip>
					</span>
				),
				sorter: true,
				sortDirections: ["ascend", "descend"],
			},
			{
				title: ({ sortOrder, sortColumn, filters }) => {
					if (sortOrder === "descend") return <Tooltip title="Click to cancel sort">Join Date</Tooltip>;
					else if (sortOrder === "ascend") return <Tooltip title="Click sort by descend">Join Date</Tooltip>;
					else return <Tooltip title="Click sort by ascend">Join Date</Tooltip>;
				},
				className: "sorter-no-tooltip",
				dataIndex: "joiningDate",
				key: "joiningDate",
				render: (text, record) => (
					<p className="textBlack" onClick={() => this.props.history.push("/manage-artists/user-profile/" + record.userId)}>
						{new Date(text).toLocaleDateString("en-IN", options)}
					</p>
				),
				sorter: true,
				sortDirections: ["ascend", "descend"],
			},
			{
				title: "Owned Artworks",
				dataIndex: "ownedArtWorksCount",
				key: "ownedArtWorksCount",
				sorter: true,
				sortDirections: ["ascend", "descend"],
				render: (text, record) => (
					<p className="artworkcountText" onClick={() => this.props.history.push("/manage-artists/user-profile/" + record.userId)}>
						{text}
					</p>
				),
			},
			{
				title: "Country",
				dataIndex: "place",
				key: "place",
				sorter: true,
				sortDirections: ["ascend", "descend"],
				render: (text, record) => (
					<p className="textBlack" onClick={() => this.props.history.push("/manage-artists/user-profile/" + record.userId)}>
						{text}
					</p>
				),
			},
			{
				title: "Career Level",
				dataIndex: "careerLevel",
				key: "careerLevel",
				sorter: true,
				sortDirections: ["ascend", "descend"],
				render: (text, record) => (
					<p className="textBlack" onClick={() => this.props.history.push("/manage-artists/user-profile/" + record.userId)}>
						{text}
					</p>
				),
			},
			{
				title: "Liaison",
				dataIndex: "liaison",
				key: "liaison",
				render: (text, record) => (
					<p className="textBlack" onClick={() => this.props.history.push("/manage-artists/user-profile/" + record.userId)}>
						{text}
					</p>
				),
			},
			{
				title: "Approval Date",
				dataIndex: "approvedDate",
				key: "approvedDate",
				sorter: true,
				sortDirections: ["ascend", "descend"],
				render: (text, record) => (
					<p className="textBlack" onClick={() => this.props.history.push("/manage-artists/user-profile/" + record.userId)}>
						{text ? new Date(text).toLocaleDateString("en-IN", options) : ""}
					</p>
				),
			},
			{
				title: "Last Featured Date",
				dataIndex: "lastFeaturedDate",
				key: "lastFeaturedDate",
				sorter: true,
				sortDirections: ["ascend", "descend"],
				render: (text, record) => (
					<p className="textBlack" onClick={() => this.props.history.push("/manage-artists/user-profile/" + record.userId)}>
						{text ? new Date(text).toLocaleDateString("en-IN", options) : ""}
					</p>
				),
			},
			{
				title: "Featured in Social Media",
				dataIndex: "isSocialMediaFeatured",
				key: "isSocialMediaFeatured",
				sorter: true,
				sortDirections: ["ascend", "descend"],
				render: (text, record) => (
					<p className="textBlack" onClick={() => this.props.history.push("/manage-artists/user-profile/" + record.userId)}>
						{text === true ? "Yes" : "No"}
					</p>
				),
			},
			{
				title: ({ sortOrder, sortColumn, filters }) => {
					if (sortOrder === "descend") return <Tooltip title="Click to cancel sort">Featured</Tooltip>;
					else if (sortOrder === "ascend") return <Tooltip title="Click sort by descend">Featured</Tooltip>;
					else return <Tooltip title="Click sort by ascend">Featured</Tooltip>;
				},
				className: "sorter-no-tooltip",
				key: "isFeatured",
				dataIndex: "isFeatured",
				render: (text, record) => {
					if (!text) {
						return (
							<img
								className="star-image"
								src={require("../../assets/images/common/Star.svg")}
								onClick={this.onFeatured.bind(this, record)}
								alt="star"
							/>
						);
					} else {
						return (
							<img
								className="star-image"
								src={require("../../assets/images/common/Star_Red.svg")}
								onClick={this.onUnFeatured.bind(this, record)}
								alt="star"
							/>
						);
					}
				},
				sorter: true,
				sortDirections: ["ascend", "descend"],
			},
			{
				title: "KYC",
				key: "kycStatus",
				dataIndex: "kycStatus",
				sorter: true,
				sortDirections: ["ascend", "descend"],
				render: (text) => {
					return (
						<div>
							{text === 0 && <p className="textOrange">Pending</p>}
							{text === 1 && <p className="textBrown">Completed</p>}
							{text === 2 && <p className="textGreen">Approved</p>}
							{text === 3 && <p className="textGreen">Not Applicable</p>}
						</div>
					);
				},
			},
			{
				title: "Artist Status",
				key: "status",
				dataIndex: "status",
				sorter: true,
				sortDirections: ["ascend", "descend"],
				render: (text) => {
					return (
						<div>
							{text === 0 && <p className="textOrange">Pending</p>}
							{text === 1 && <p className="textGreen">Approved</p>}
							{text === 2 && <p className="textBrown">Rejected</p>}
							{text === 3 && <p className="textRed">Blocked</p>}
						</div>
					);
				},
			},
			{
				title: "Action",
				key: "action",
				fixed: "right",
				render: (record) => (
					<Popover
						placement="bottom"
						content={content(record)}
						overlayClassName="manage-artworks-action-popover"
					// onVisibleChange={this.onVisibleChangeOfArtworkRecord.bind(this, record)}
					>
						<img className="action-image" src={require("../../assets/images/common/artworksActionIcon.svg")} alt="action" />
					</Popover>
				),
			},
		];
		const content = (record) => (
			<div>
				{record.status === 0 && (
					<span className="action-span" onClick={this.toggleApproveConfirmationDialog.bind(this, record, 1)}>
						{/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
						<Icon type="check" />
						<p className="pop-item">Approve</p>
					</span>
				)}
				{record.status === 0 && (
					<span className="action-span" onClick={this.toggleRejectConfirmationDialog.bind(this, record, 2)}>
						{/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
						<Icon type="close" />
						<p className="pop-item">Reject</p>
					</span>
				)}
				{record.kycStatus !== 2 && record.kycStatus !== 3 && (
					<span className="action-span" onClick={this.onClickUserKyc.bind(this, true, record)}>
						{/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
						<Icon type="close" />
						<p className="pop-item">KYC clearance </p>
					</span>
				)}
				{record.kycStatus === 2 && (
					<span className="action-span" onClick={this.onClickUserKyc.bind(this, true, record)}>
						{/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
						<Icon type="close" />
						<p className="pop-item">Review KYC</p>
					</span>
				)}

				<div className="action-span" onClick={this.onClickEditArtistInfo.bind(this, true, record)}>
					<img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" />
					<p className="pop-item">Edit</p>
				</div>
				<div className="action-span" onClick={this.onClickBlockArtist.bind(this, true, record)}>
					<img src={require("../../assets/images/common/Edit_New.svg")} alt="Block" />
					<p className="pop-item">Block</p>
				</div>
			</div>
		);
		const { artistsDownloadLoader } = this.props.manageArtistsReducer;
		const { countriesList } = this.props.countriesTaxReducer;
		const countryList = map(countriesList, (o) => o.name) || [];
		const { allLiaisonsList } = this.state;
		return (
			<Root>
				<Topbar title={"Manage Artists"} showSearch searchValue={this.state.searchValue} onChangeSearch={this.onChangeSearch.bind(this)} />
				<div className="manageartists-wrapper">
					<div className="filter-wrapper">
						<button className="btn-orange" onClick={this.downloadArtists.bind(this)} disabled={artistsDownloadLoader}>
							Export to Excel
							{artistsDownloadLoader && <CircularProgress size={24} className="login-progress" />}
						</button>
						<MultiSelectDropdown
							data={countryList}
							showCheckbox={true}
							selectedCoutry={this.state.selectedCoutry} // Preselected value to persist in dropdown
							onSetCountryArray={this.onSetCountryArray.bind(this)} // Function will trigger on select event
							onRemoveCountryArray={this.onRemoveCountryArray.bind(this)} // Function will trigger on remove event
							isObject={false}
							placeholder="Choose Country"
						/>
						<Select className="filter-field" value={this.state.kycFilter} onChange={this.onChangeFilterKyc(this)}>
							{kycStatusList.map((item, index) => (
								<Option key={index} value={item.value}>
									{item.label}
								</Option>
							))}
						</Select>
						<Select className="filter-field" value={this.state.artistStatus} onChange={this.onChangeFilterArtistStatus(this)}>
							{artistStatusList.map((item, index) => (
								<Option key={index} value={item.value}>
									{item.label}
								</Option>
							))}
						</Select>
						<Select className="filter-field" value={this.state.selectedLiaison} onChange={this.onChangeFilterLiaisons(this)}>
							<Option value={null}>Liaisons</Option>
							{allLiaisonsList &&
								allLiaisonsList.map((item, index) => (
									<Option key={index} value={item}>
										{item}
									</Option>
								))}
						</Select>
					</div>
					{savedArtistsList && savedArtistsList.length !== 0 ? (
						<Table
							rowKey={(record) => record._id}
							columns={columns}
							dataSource={savedArtistsList}
							pagination={false}
							showSorterTooltip
							onChange={this.onChangeForSort.bind(this)}
							scroll={{ x: 1500 }}
							sticky
						/>
					) : artistsListLoader ? (
						<CircularProgress className="circular-loader" size={30} />
					) : (
						<>
							<Table rowKey={(record) => record._id} columns={columns} dataSource={[]} pagination={false} showSorterTooltip sticky />

							{/* <div className="empty-table">No Artists Found.</div> */}
						</>
					)}
					{savedArtistsListCount > 10 && (
						<Pagination
							current={parseInt(this.state.pageNumValue)}
							pageSize={12}
							total={savedArtistsListCount}
							onChange={this.onSelectPageNumber(this)}
						/>
					)}
				</div>
				<KycDocumentDialog
					open={kycDialog}
					approveKYC={this.approveKYC.bind(this)}
					handleClose={this.onToggleKycModel.bind(this, false)}
					data={kycDocumentResult && kycDocumentResult}
				/>
				<AddCareerLevelDialog
					open={artistEditDialog}
					updateArtistInfo={this.updateArtistInfo.bind(this)}
					updateData={this.state.selectedRecordToPerformAction}
					handleClose={this.toggleArtistInfoDialog.bind(this, false)}
				/>
				<ConfirmationMessageDialog
					open={this.state.blockconfirmationDialog}
					handleClose={this.toggleBlockConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to block this user?"}
					onConfirm={this.onBlockUser.bind(this)}
				/>

				<ConfirmationMessageDialog
					open={this.state.approveconfirmationDialog}
					handleClose={this.toggleApproveConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to approve this user?"}
					onConfirm={this.onApproveUser.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.rejectconfirmationDialog}
					handleClose={this.toggleRejectConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to reject this user?"}
					onConfirm={this.onRejectUser.bind(this)}
				/>
			</Root>
		);
	}

	onChangeForSort(pagination, filters, sorter) {
		let sortOptionData = [
			{ label: "name", value: 0 },
			{ label: "joiningDate", value: 1 },
			{ label: "isFeatured", value: 2 },
			{ label: "isSocialMediaFeatured", value: 3 },
			{ label: "kycStatus", value: 4 },
			{ label: "status", value: 5 },
			{ label: "place", value: 7 },
			{ label: "lastFeaturedDate", value: 9 },
			{ label: "ownedArtWorksCount", value: 10 },
			{ label: "approvedDate", value: 11 },
			{ label: "careerLevel", value: 12 },
		];
		if (!isEmpty(sorter)) {
			let findItem = sortOptionData.find((element) => element.label === sorter.columnKey);

			this.setState(
				{
					orderSort: sorter.order === "descend" ? 1 : 0,
					orderOption: findItem.value,
				},
				() => {
					let request = {
						pageNum: parseInt(this.state.pageNumValue),
						pageSize: 12,
						searchKey: this.state.searchValue || "",
						sortOrder: sorter.order === "descend" ? 1 : 0,
						userSortOptions: findItem.value,
						CountryName: this.state.selectedCoutry || [],
						KYCStatus: this.state.kycFilter,
						ArtistStatus: this.state.artistStatus,
					};
					this.props.getArtistsList(request, true);
				}
			);
		} else {
			this.setState(
				{
					orderSort: null,
					orderOption: null,
				},
				() => {
					let request = {
						pageNum: parseInt(this.state.pageNumValue),
						pageSize: 12,
						searchKey: this.state.searchValue || "",
					};
					this.props.getArtistsList(request);
				}
			);
		}
	}
	// ------------------------ When Page loads/ URL changes ------------------------- //
	// Call API by fetching details from URL
	setSearchParams() {
		const { location } = this.props;

		// When filter/Search params exist
		if (location.search) {
			let parsedQuery = Util.queryStringHandling(location.search, "PARSE");
			this.setState(
				{
					searchValue: parsedQuery.Search,
					pageNumValue: parseInt(this.props.match.params.pageNum),
					selectedCoutry: parsedQuery.Country ? this.stringToArray(parsedQuery.Country) : [],
				},
				() => {
					this.doSearchFilterUsers(parsedQuery);
				}
			);
		}

		// When there is no filter/Search params
		else {
			this.setState({ pageNumValue: this.props.match.params.pageNum }, () => {
				this.doSearchFilterUsers();
			});
		}
	}

	// --------------------- When filter/search/pagination events trigger --------------------- //

	//Pagination
	onSelectPageNumber = (name) => (event) => {
		this.setState({ pageNumValue: event }, () => {
			this.doChangeUrlWithQuery();
		});
	};

	// Search
	onChangeSearch(search) {
		this.setState(
			{
				searchValue: search,
				pageNumValue: 1,
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	}
	stringToArray(arr) {
		return isArray(arr) ? arr : [...[], arr];
	}
	// Change URL based on filter/search/Page values
	doChangeUrlWithQuery() {
		let searchCriteria = {
			Search: this.state.searchValue,
			Country: this.state.selectedCoutry,
		};
		const queryString = Util.queryStringHandling(searchCriteria, "STR");
		this.props.history.push("/manage-artists/" + this.state.pageNumValue + "?" + queryString);
	}

	// ----------------------- API - Fetch Sellers ----------------------------- //
	doSearchFilterUsers() {
		// POST request body
		if (this.state.orderSort === null && this.state.orderOption === null) {
			let request = {
				pageNum: this.state.pageNumValue,
				pageSize: 12,
				searchKey: this.state.searchValue || "",
				CountryName: this.state.selectedCoutry || [],
				KYCStatus: this.state.kycFilter,
				ArtistStatus: this.state.artistStatus,
				Liaison: this.state.selectedLiaison,
			};
			this.props.getArtistsList(request);
		} else {
			let request = {
				pageNum: this.state.pageNumValue,
				pageSize: 12,
				searchKey: this.state.searchValue || "",
				sortOrder: this.state.orderSort,
				userSortOptions: this.state.orderOption,
				CountryName: this.state.selectedCoutry || [],
				KYCStatus: this.state.kycFilter,
				ArtistStatus: this.state.artistStatus,
				Liaison: this.state.selectedLiaison,
			};
			this.props.getArtistsList(request, true);
		}
	}
	//Reset fields
	handleReset() {
		this.setState({
			...this.state,
			blockconfirmationDialog: false,
			unblockconfirmationDialog: false,
			approveconfirmationDialog: false,
			rejectconfirmationDialog: false,
			status: null,
			userId: "",
		});
	}
	//-------------Approve User Confirm---------------------//
	async onApproveUser() {
		await this.props.updateRoleFromManageArtistChangeAction(this.state.userId, this.state.status, "ARTIST");
		await this.handleReset();
		await this.doSearchFilterUsers();
	}

	//-------------Reject User Confirm---------------------//
	async onRejectUser() {
		await this.props.updateRoleFromManageArtistChangeAction(this.state.userId, this.state.status, "ARTIST");
		await this.handleReset();
		await this.doSearchFilterUsers();
	}

	//----------------Dialog for approve artwork-----------------//
	toggleApproveConfirmationDialog(item, status) {
		console.log("🚀 ~ file: ManageArtists.js ~ line 562 ~ ManageArtists ~ toggleApproveConfirmationDialog ~ item", item);
		this.setState(
			{
				approveconfirmationDialog: !this.state.approveconfirmationDialog,
			},
			() => {
				this.setState({
					userId: item.userId,
					status: status,
					userRole: "ARTIST",
				});
			}
		);
	}
	//-------------Dialog for reject artwork---------------------//
	toggleRejectConfirmationDialog(item, status) {
		this.setState(
			{
				rejectconfirmationDialog: !this.state.rejectconfirmationDialog,
			},
			() => {
				this.setState({
					userId: item.userId,
					status: status,
				});
			}
		);
	}

	// -------------------------- User Profile -------------------------- //
	toggleUserProfile(path, item) {
		window.location.href = "/#/" + path + "/" + item.userId;
		this.props.setUserProfile(item);
	}

	async onClickUserKyc(value, item) {
		await this.props.toggleKYCDialog(value);
		await this.props.getUserKycUser(item.userId);
	}
	async approveKYC(userId, isType) {
		await this.props.approveKYC(userId, isType);
		await this.doSearchFilterUsers();
	}
	onToggleKycModel(value) {
		// Toggle dialog
		this.props.toggleKYCDialog(value);
	}

	//Set Featured Artist
	onFeatured(item) {
		let request = {
			userId: item.userId,
			featureStatus: true,
		};
		let artistData = {
			pageNum: this.state.pageNumValue,
			pageSize: 12,
			searchValue: this.state.searchValue || "",
		};
		this.props.setArtistFeaturedAction(request, artistData);
	}
	//Set Featured Artist
	onUnFeatured(item) {
		let request = {
			userId: item.userId,
			featureStatus: false,
		};
		let artistData = {
			pageNum: this.state.pageNumValue,
			pageSize: 12,
			searchValue: this.state.searchValue || "",
		};
		this.props.setArtistFeaturedAction(request, artistData);
	}
	// Download artists list
	downloadArtists = () => {
		this.props.adminDownloadArtistAction();
	};
	onSetCountryArray = (list) => {
		this.setState(
			{
				selectedCoutry: list,
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	};
	onRemoveCountryArray = (list) => {
		this.setState(
			{
				selectedCoutry: list,
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	};
	onClickEditArtistInfo = (value, item) => {
		this.setState(
			{
				selectedRecordToPerformAction: item,
			},
			() => {
				// Toggle dialog
				this.props.toggleArtistInfoDialog(value);
			}
		);
	};
	onClickBlockArtist = (value, item) => {
		this.setState(
			{
				selectedRecordToPerformAction: item,
				blockconfirmationDialog: true,
			},
			() => {
				this.setState({
					userId: item.userId,
					status: 3,
				});
			}
		);
	};
	toggleBlockConfirmationDialog = () => {
		this.setState(
			{
				blockconfirmationDialog: !this.state.blockconfirmationDialog,
			},
			() => {
				// Toggle dialog
			}
		);
	};
	async onBlockUser() {
		// this.props.blockArtistAction(this.state.selectedRecordToPerformAction.userId);
		await this.props.updateRoleFromManageArtistChangeAction(this.state.userId, 3, "ARTIST");
		await this.handleReset();
		await this.doSearchFilterUsers();
		// this.toggleBlockConfirmationDialog();
		// let request = {
		// 	pageNum: this.state.pageNumValue,
		// 	pageSize: 12,
		// 	searchKey: this.state.searchValue || "",
		// 	CountryName: this.state.selectedCoutry || [],
		// };
		// this.props.getArtistsList(request);
	}
	async toggleArtistInfoDialog(value) {
		// Toggle dialog
		this.setState(
			{
				selectedRecordToPerformAction: null,
			},
			() => {
				// Toggle dialog
				this.props.toggleArtistInfoDialog(value);
			}
		);
	}

	onChangeFilterArtistStatus = (name) => (event) => {
		this.setState(
			{
				artistStatus: event,
			},
			() => {
				let request = {
					pageNum: this.state.pageNumValue,
					pageSize: 12,
					searchKey: this.state.searchValue || "",
					CountryName: this.state.selectedCoutry || [],
					KYCStatus: this.state.kycFilter,
					ArtistStatus: this.state.artistStatus,
					Liaison: this.state.selectedLiaison,
				};
				this.props.getArtistsList(request, true);
			}
		);
	};
	onChangeFilterLiaisons = (val) => (event) => {
		this.setState(
			{
				selectedLiaison: event,
			},
			() => {
				let request = {
					pageNum: this.state.pageNumValue,
					pageSize: 12,
					searchKey: this.state.searchValue || "",
					CountryName: this.state.selectedCoutry || [],
					KYCStatus: this.state.kycFilter,
					ArtistStatus: this.state.artistStatus,
					Liaison: this.state.selectedLiaison,
				};
				this.props.getArtistsList(request, true);
			}
		);
	};
	onChangeFilterKyc = (name) => (event) => {
		this.setState(
			{
				kycFilter: event,
			},
			() => {
				let request = {
					pageNum: this.state.pageNumValue,
					pageSize: 12,
					searchKey: this.state.searchValue || "",
					CountryName: this.state.selectedCoutry || [],
					KYCStatus: this.state.kycFilter,
					ArtistStatus: this.state.artistStatus,
					Liaison: this.state.selectedLiaison,
				};
				this.props.getArtistsList(request, true);
			}
		);
	};

	async updateArtistInfo(userId, data) {
		await this.props.updateArtistInfoAction(userId, data);
		await this.doSearchFilterUsers();
		await this.props.toggleArtistInfoDialog(false);
	}
}
const mapStateToProps = (state) => {
	return {
		manageArtistsReducer: state.manageArtistsReducer,
		manageUsersReducer: state.manageUsersReducer,
		countriesTaxReducer: state.countriesTaxReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getArtistsList: (artistinput, isSort) => {
			dispatch(getArtistsList(artistinput, isSort));
		},
		setArtistFeaturedAction: (featureData, artistinput) => {
			dispatch(setArtistFeaturedAction(featureData, artistinput));
		},
		getUserKycUser: (userId) => {
			dispatch(getUserKycUser(userId));
		},
		approveKYC: (userId, isType) => {
			dispatch(approveKYC(userId, isType));
		},
		toggleKYCDialog: (value) => {
			dispatch(toggleKYCDialog(value));
		},
		adminDownloadArtistAction: () => {
			dispatch(adminDownloadArtistAction());
		},
		toggleArtistInfoDialog: (value) => {
			dispatch(toggleArtistInfoDialog(value));
		},
		updateArtistInfoAction: (id, request) => {
			dispatch(updateArtistInfoAction(id, request));
		},
		getAllCountryListAction: () => {
			dispatch(getAllCountryListAction());
		},
		blockArtistAction: (userId) => {
			dispatch(blockArtistAction(userId));
		},
		updateRoleChangeAction: (userId, status) => {
			dispatch(updateRoleChangeAction(userId, status));
		},
		updateRoleFromManageArtistChangeAction: (userId, status, userRole) => {
			dispatch(updateRoleFromManageArtistChangeAction(userId, status, userRole));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ManageArtists);
