import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent, Slide, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// SCSS
import "./EditorModal.scss";

//Components
import { popToast } from "../Toast/Toast";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class EditorModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            editorState: EditorState.createEmpty(),
            mediaThumb: "",
            media: "",
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.open && this.props.open) {
            const updateData = this.props.data;
            this.setState({
                title: (updateData && updateData.title) || "",
                editorState: (updateData && updateData.body && EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(updateData.body)))) || EditorState.createEmpty(),
                mediaThumb: (updateData && updateData.image) || ""
            });
        }
    }

    render() {
        const { editorState } = this.state;
        return (
            <Dialog
                fullScreen
                open={this.props.open}
                onClose={this.props.handleClose}
                TransitionComponent={Transition}
                BackdropProps={{ classes: { root: "dialog-backdrop" } }}
                className="editor-modal-dialog"
            >
                <DialogTitle className="dialog-title">Add {this.props.type}</DialogTitle>
                <DialogContent className="editor-modal-content">
                    <div className="editor-main-wrapper">
                        {this.state.mediaThumb ? (
                            <label className="upload-box bg-white" onClick={e => this.attach_media.click()}>
                                <img className="media-thumb" src={this.state.mediaThumb} alt="Attach media" />
                            </label>
                        ) : (
                                <div className="upload-box" onClick={e => this.attach_media.click()}>
                                    <img className="image-upload" src={require("../../assets/images/common/AddPhoto.svg")} alt="Upload" />
									Upload Image
                                </div>
                            )}
                        <input
                            type="file"
                            style={{ display: "none" }}
                            accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
                            onChange={e => this.onImageSelect(e)}
                            ref={ref => (this.attach_media = ref)}
                        />
                        <div className="form-control">
                            <label className="form-label">Title</label>
                            <input
                                type="text"
                                autoFocus
                                className="form-input"
                                value={this.state.title}
                                onChange={this.onHandleInput("title")}
                            />
                        </div>
                        <Editor
                            toolbar={
                                {
                                    options: ['blockType', 'inline', 'list', 'image'],
                                    inline: {
                                        visible: true,
                                        inDropdown: false,
                                        options: ['bold', 'italic', 'underline'],
                                    },
                                    image: {
                                        urlEnabled: true,
                                        uploadEnabled: false,
                                        alignmentEnabled: true,
                                        previewImage: true,
                                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                        alt: { present: false, mandatory: false },
                                        defaultSize: {
                                            height: 'auto',
                                            width: 'auto',
                                        },
                                    },
                                    inputAccept: 'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel'
                                }
                            }
                            editorState={editorState}
                            onEditorStateChange={this.onEditorStateChange()}
                            editorStyle={{
                                height: "300px",
                                border: "1px solid #E5E5E5",
                                padding: "10px",
                                fontFamily: "Times New Roman",
                                color: "black",
                                fontWeight: "100",
                                width: "100%"
                            }}
                            placeholder="Add some contents here"
                        />
                        <div className="ButtonsWrapper">
                            <button className="CancelButton" onClick={this.onClickCancel.bind(this)}>
                                CANCEL
                            </button>
                            <button disabled={this.props.loader} className="PublishButton" onClick={this.onClickCreate.bind(this)}>
                                PUBLISH
                                 {this.props.loader && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
                            </button>
                        </div>
                    </div>
                </DialogContent>

            </Dialog>
        );
    }

    // Handle inputs
    onHandleInput = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };
    // On select image
    onImageSelect(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let image = "";
        if (file) {
            reader.onloadend = () => {
                this.setState({
                    media: file,
                    mediaThumb: reader.result
                });
                image = reader.result;
            };
            reader.readAsDataURL(file);
        }
    }

    isFormValid() {
        if (!this.state.title.trim()) {
            popToast.show("Please enter title");
            return false;
        }
        if (this.state.editorState) {
            let content = this.state.editorState.getCurrentContent();
            let currentPlainText = content.getPlainText();
            let lengthOfTrimmedContent = currentPlainText.trim().length;
            if (lengthOfTrimmedContent < 1) {
                popToast.show("Please enter contents");
                return false;
            }
            else if (lengthOfTrimmedContent < 10) {
                popToast.show("Content is too short");
                return false;
            }
        }
        if (!this.state.mediaThumb) {
            if (!this.state.media) {
                popToast.show("Please choose an image");
                return false;
            }
            if (this.state.media.type && !this.state.media.type.startsWith("image/")) {
                popToast.show("Please choose a valid image file");
                return false;
            }
        }
        return true;
    }
    onEditorStateChange = () => (editorState) => {
        this.setState({
            editorState,
        });
    };

    onClickCreate() {
        if (this.isFormValid()) {
            let request = {};
            if (this.state.media) {
                request = {
                    Title: this.state.title,
                    Body: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
                    BlogImages: this.state.media
                }
            }
            else {
                request = {
                    Title: this.state.title,
                    Body: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
                }
            }

            if (this.props.update) {
                if (this.props.type === "News") {
                    this.props.updateNews(request, this.props.data._id);
                } else {
                    this.props.updateBlogs(request, this.props.data._id);
                }
            } else {
                if (this.props.type === "News") {
                    this.props.addNews(request);
                } else {
                    this.props.addBlog(request);
                }
            }
        }
    }

    onClickCancel() {
        this.setState({
            ...this.state,
            title: "",
            editorState: EditorState.createEmpty(),
            mediaThumb: "",
            media: "",
        });
        this.props.handleClose();
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorModal);
