import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Pagination, Table, Popover, Tooltip, Select, Icon } from "antd";
//Files
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { Util } from "../../util/util";
// SCSS
import "./Quotes.scss";
import ConfirmationMessageDialog from "../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";
import { updateQuotePriceStatusAction, getAllQuotesListAction } from "../../redux/actions/QuotesAction";
import propertiesList from "../../properties.json";

const { Option } = Select;

class Quotes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			approveconfirmationDialog: false,
			rejectconfirmationDialog: false,
			searchValue: "",
			filterValue: null,
			pageNumValue: 1,
			quoteId: null,
			status: null,
		};
	}

	componentDidMount() {
		// List Sellers by fetching details from URL - When Page Loads
		this.setSearchParams();
	}

	componentDidUpdate(prevProps) {
		// List Sellers by fetching details from URL - When URL change occur
		const locationChanged = this.props.location !== prevProps.location;
		if (locationChanged) {
			this.setSearchParams();
		}
	}

	render() {
		const FilterList = [
			{ label: "All", value: null },
			{ label: "Pending", value: 0 },
			{ label: "Approved", value: 1 },
			{ label: "Rejected", value: 2 },
		];
		const options = { year: "numeric", month: "long", day: "numeric" };
		const columns = [
			{
				title: "Product",
				dataIndex: "productName",
				key: "productName",
				render: (text, record) => (
					<a href={`${propertiesList.fontEndUrl}art/medium/${record.productDetail.slugId}`} rel="noopener noreferrer" target="_blank">
						<span className="colWrapper">
							<img
								className="colImage"
								src={
									record.productDetail && record.productDetail.displayPicture
										? record.productDetail.displayPicture
										: require("../../assets/images/common/No_Image.png")
								}
								alt="productImage"
							/>
							<div className="colDiv">
								<Tooltip title={record.productDetail && record.productDetail.productName ? record.productDetail.productName : "Nil"}>
									<p className="colText">
										{record.productDetail && record.productDetail.productName ? record.productDetail.productName : "Nil"}
									</p>
								</Tooltip>
								<p className="colGreyText">
									{record.productDetail && record.productDetail.oneLineDescription
										? record.productDetail.oneLineDescription
										: "Nil"}
								</p>
							</div>
						</span>
					</a>
				),
			},
			{
				title: "Artist",
				dataIndex: "artistName",
				key: "artistName",
				render: (text, record) => (
					<span
						className="colWrapper nav-link"
						onClick={() => this.props.history.push("/manage-artists/user-profile/" + record.productDetail.artistId)}
					>
						<Tooltip title={record.productDetail && record.productDetail.artistName ? record.productDetail.artistName : "Unknow"}>
							<p className="colText">
								{record.productDetail && record.productDetail.artistName ? record.productDetail.artistName : "Unknow"}
							</p>
						</Tooltip>
					</span>
				),
			},
			{
				title: "User",
				dataIndex: "userName",
				key: "userName",
				render: (text, record) => (
					<span className="colWrapper nav-link" onClick={() => this.props.history.push("/manage-users/user-profile/" + record.userId)}>
						<Tooltip title={text ? text : "Nil"}>
							<p className="colText">{text ? text : "Nil"}</p>
						</Tooltip>
					</span>
				),
			},
			{
				title: "Price",
				dataIndex: "amount",
				key: "amount",
				render: (text) => (
					<p className="textCenterBlack">
						{"USD"}&nbsp;&nbsp;{text}
					</p>
				),
			},
			{
				title: "Artwork Price",
				dataIndex: "amount",
				key: "amount",
				render: (text, record) => (
					<p className="textCenterBlack">
						{"USD"}&nbsp;&nbsp;{record.productDetail && record.productDetail.price}
					</p>
				),
			},
			{
				title: "Buyer Country",
				dataIndex: "buyerCountry",
				key: "buyerCountry",
				render: (text) => <p className="textCenterBlack">{text}</p>,
			},
			{
				title: "Buyer Mobile",
				dataIndex: "buyerMobile",
				key: "buyerMobile",
				render: (text) => <p className="textCenterBlack">{text}</p>,
			},
			{
				title: "Date",
				dataIndex: "date",
				key: "date",
				render: (text) => <p className="textCenterBlack">{new Date(text).toLocaleDateString("en-IN", options)}</p>,
			},
			{
				title: "Approval Date",
				dataIndex: "approvalDate",
				key: "approvalDate",
				render: (text, record) => <p className="">{text !== null ? new Date(text).toLocaleDateString("en-IN", options) : "Nill"}</p>,
			},
			{
				title: "Message",
				dataIndex: "message",
				key: "message",
				render: (text) => (
					<Tooltip title={text}>
						<p className="colGreyText">{text}</p>
					</Tooltip>
				),
			},
			{
				title: "Status",
				key: "statusBySeller",
				dataIndex: "statusBySeller",
				render: (text) => {
					return (
						<div>
							{text === 0 && <p className="textBlue">Pending</p>}
							{text === 1 && <p className="textGreen">Approved</p>}
							{text === 2 && <p className="textRed">Rejected</p>}
						</div>
					);
				},
			},
		];

		const { quotesListLoader, quotesList, quotesListCount } = this.props.quotesReducer;

		return (
			<Root>
				<Topbar title={"Quotes"} />
				<div className="quotes-wrapper">
					<div className="filter-wrapper">
						<img className="filter-img" src={require("../../assets/images/common/Filter.svg")} alt="filter" />
						<Select style={{ width: 200 }} placeholder="Filtered by Status" onChange={this.onChangeFilter(this)}>
							{FilterList &&
								FilterList.map((item, index) => (
									<Option key={index} value={item.value}>
										{item.label}
									</Option>
								))}
						</Select>
					</div>
					<div className="sub-wrapper">
						{quotesList && quotesList.length !== 0 ? (
							<Table
								rowKey={(record) => record._id}
								columns={columns}
								dataSource={quotesList}
								pagination={false}
								scroll={{ x: 2000 }}
							/>
						) : quotesListLoader ? (
							<CircularProgress className="circular-loader" size={30} />
						) : (
							<div className="empty-table">No Quotes Found.</div>
						)}
						{!quotesListLoader && (
							<Pagination
								current={parseInt(this.state.pageNumValue)}
								total={quotesListCount}
								pageSize={10}
								onChange={this.onSelectPageNumber(this)}
							/>
						)}
					</div>
				</div>
				<ConfirmationMessageDialog
					open={this.state.approveconfirmationDialog}
					handleClose={this.toggleApproveConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to approve this quote price request?"}
					onConfirm={this.onApproveQuote.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.rejectconfirmationDialog}
					handleClose={this.toggleRejectConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to reject this quote price request?"}
					onConfirm={this.onRejectQuote.bind(this)}
				/>
			</Root>
		);
	}
	// ------------------------ When Page loads/ URL changes ------------------------- //
	// Call API by fetching details from URL
	setSearchParams() {
		const { location } = this.props;

		// When filter/Search params exist
		if (location.search) {
			let parsedQuery = Util.queryStringHandling(location.search, "PARSE");
			this.setState(
				{
					filterValue: parsedQuery.Filter,
					searchValue: parsedQuery.Search,
					pageNumValue: this.props.match.params.pageNum,
				},
				() => {
					this.doSearchFilterSellers(parsedQuery);
				}
			);
		}

		// When there is no filter/Search params
		else {
			this.setState({ pageNumValue: this.props.match.params.pageNum }, () => {
				this.doSearchFilterSellers();
			});
		}
	}

	// --------------------- When filter/search/pagination events trigger --------------------- //

	//Pagination
	onSelectPageNumber = (name) => (event) => {
		this.setState({ pageNumValue: event }, () => {
			this.doChangeUrlWithQuery();
		});
	};

	// Filter
	onChangeFilter = (name) => (event) => {
		this.setState(
			{
				filterValue: event,
				pageNumValue: 1,
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	};

	// Search
	onChangeSearch(search) {
		this.setState(
			{
				searchValue: search,
				pageNumValue: 1,
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	}

	// Change URL based on filter/search/Page values
	doChangeUrlWithQuery() {
		let searchCriteria = {
			Filter: this.state.filterValue,
			Search: this.state.searchValue,
		};
		const queryString = Util.queryStringHandling(searchCriteria, "STR");
		this.props.history.push("/quotes/" + this.state.pageNumValue + "?" + queryString);
	}

	// ----------------------- API - Fetch Sellers ----------------------------- //
	doSearchFilterSellers() {
		if (this.state.filterValue) {
			let request = {
				PageNum: this.state.pageNumValue,
				PageSize: 10,
				AdminStatus: this.state.filterValue,
			};
			this.props.getAllQuotesListAction(request);
		} else {
			let request = {
				PageNum: this.state.pageNumValue,
				PageSize: 10,
			};
			this.props.getAllQuotesListAction(request);
		}
	}

	//----------------Dialog for approve quote price request-----------------//
	toggleApproveConfirmationDialog(item, status) {
		this.setState(
			{
				approveconfirmationDialog: !this.state.approveconfirmationDialog,
			},
			() => {
				this.setState({
					quoteId: item._id,
					status: status,
				});
			}
		);
	}

	//-------------Dialog for reject quote price request---------------------//
	toggleRejectConfirmationDialog(item, status) {
		this.setState(
			{
				rejectconfirmationDialog: !this.state.rejectconfirmationDialog,
			},
			() => {
				this.setState({
					quoteId: item._id,
					status: status,
				});
			}
		);
	}
	//-------------Approve quote price request Confirm---------------------//
	async onApproveQuote() {
		await this.props.updateQuotePriceStatusAction(this.state.quoteId, this.state.status);
		await this.doSearchFilterSellers();
		await this.handleReset();
	}

	//-------------Reject quote price request Confirm---------------------//
	async onRejectQuote() {
		await this.props.updateQuotePriceStatusAction(this.state.quoteId, this.state.status);
		await this.doSearchFilterSellers();
		await this.handleReset();
	}
	//Reset fields
	handleReset() {
		this.setState({
			...this.state,
			approveconfirmationDialog: false,
			rejectconfirmationDialog: false,
			quoteId: null,
			status: null,
		});
	}
}

const mapStateToProps = (state) => {
	return {
		quotesReducer: state.quotesReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAllQuotesListAction: (request) => {
			dispatch(getAllQuotesListAction(request));
		},
		updateQuotePriceStatusAction: (quoteId, status) => {
			dispatch(updateQuotePriceStatusAction(quoteId, status));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Quotes);
