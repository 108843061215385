import React, { Component } from "react";
import Root from "../../Root/Root";
import Topbar from "../../../components/Topbar/Topbar";
import "./News.scss";
import { connect } from "react-redux";
import TileComponent from "../../../components/TileComponent/TileComponent";
import { Pagination } from "antd";
import { getNewsAction, createNewsAction } from "../../../redux/actions/NewsAction";
import { CircularProgress, Tooltip } from "@material-ui/core";
import EditorModal from "../../../components/EditorModal/EditorModal";
class News extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAddClicked: false
        };
    }

    componentDidMount() {
        this.props.getNews(1, 10);
    }
    render() {
        const NewsList = this.props.getNewsList.newsList;
        const TotalCount = this.props.getNewsList.count;
        const Loader = this.props.getNewsLoader;
        return (
            <Root>
                <Topbar title={"News"} />

                {Loader ? (
                    <div className="manageusers-wrapper">
                        <CircularProgress className="circular-loader" size={50} />
                    </div>
                ) : (
                        <div className="container">
                            {NewsList && NewsList.length !== 0 && NewsList.map(news => <TileComponent key={news._id} newsData={news} />)}
                            {TotalCount > 10 && (
                                <div className="paginationWrapper">
                                    <Pagination current={0} total={TotalCount || 0} onChange={this.onSelectPageNumber.bind(this)} />
                                </div>
                            )}
                            <div className="add-button-box" onClick={this.togglePopup.bind(this)}>
                                <Tooltip placement="top" title={"Add News"}>
                                    <span className="add-btn">
                                        <p>+</p> Add News
                                </span>
                                </Tooltip>
                            </div>
                            <EditorModal
                                type="News"
                                open={this.state.isAddClicked}
                                handleClose={this.togglePopup.bind(this)}
                                addNews={this.addNewsAction.bind(this)}
                                loader={this.props.addNewsLoader}
                            />
                        </div>
                    )}
            </Root>
        );
    }
    onSelectPageNumber(pageNum) {
        this.props.getNews(pageNum, 10);
    }

    togglePopup() {
        this.setState({
            isAddClicked: !this.state.isAddClicked
        });
    }

    async addNewsAction(value) {
        let body = {
            Title: value.Title,
            NewsImages: value.BlogImages,
            Content: value.Body
        };

        await this.props.addNews(body);
        this.togglePopup();
    }
}

const mapStateToProps = state => {
    return {
        getNewsList: state.newsReducer.newsList,
        getNewsLoader: state.newsReducer.getNewsLoader,
        addNewsResult: state.newsReducer.addNewsResult,
        addNewsLoader: state.newsReducer.addNewsLoader
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getNews: (pageNum, pageSize) => {
            dispatch(getNewsAction(pageNum, pageSize));
        },
        addNews: requestBody => {
            dispatch(createNewsAction(requestBody));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
