import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";
import { Util } from "../../util/util";

export class ManageArtistsService {
	static getArtistsList(artistdata, isSort) {
		if (isSort) {
			let url = APIUrlConstants.getApiUrl("getArtistsList");
			return APIService.doPost(url, artistdata).then(resp => {
				if (resp) {
					return resp;
				}
				throw resp;
			});
		}
		else {
			let url = APIUrlConstants.getApiUrl("getArtistsList");
			return APIService.doPost(url, artistdata).then(resp => {
				if (resp) {
					return resp;
				}
				throw resp;
			});
		}
	}
	static setArtistFeatured(artistdata) {
		let url = APIUrlConstants.getApiUrl("setArtistFeatured") + "?artistID=" + artistdata.userId + "&IsFeatured=" + artistdata.featureStatus;
		return APIService.doPut(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static getOrderHistory(orderdata) {
		if (orderdata.status) {
			let url =
				APIUrlConstants.getApiUrl("getOrderHistory") +
				"?pageNum=" +
				orderdata.PageNum +
				"&pageSize=" +
				orderdata.PageSize +
				"&UserId=" +
				orderdata.UserId +
				"&status=" +
				orderdata.status;
			return APIService.doGet(url).then(resp => {
				if (resp) {
					return resp;
				}
				throw resp;
			});
		} else {
			let url =
				APIUrlConstants.getApiUrl("getOrderHistory") +
				"?pageNum=" +
				orderdata.PageNum +
				"&pageSize=" +
				orderdata.PageSize +
				"&UserId=" +
				orderdata.UserId;
			return APIService.doGet(url).then(resp => {
				if (resp) {
					return resp;
				}
				throw resp;
			});
		}
	}
	static blockUser(userid) {
		let url = APIUrlConstants.getApiUrl("blockUser");
		url = Util.beautifyUrl(url, [userid]);
		return APIService.doPut(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
	static unblockUser(userid) {
		let url = APIUrlConstants.getApiUrl("unblockUser");
		url = Util.beautifyUrl(url, [userid]);
		return APIService.doPut(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
	static getUserDetails(userid) {
		let url = APIUrlConstants.getApiUrl("getUserDetails");
		url = Util.beautifyUrl(url, [userid]);
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static getShipAddress(userid) {
		let url = APIUrlConstants.getApiUrl("getShipAddress");
		url = Util.beautifyUrl(url, [userid]);
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
	static adminDownloadArtists() {
		let url = APIUrlConstants.getApiUrl("downloadArtistList");
		return APIService.doPostDownload(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
	// Update artist info
	static updateArtistInfo(artistId, request) {
		let url = APIUrlConstants.getApiUrl("updateArtistProfileByAdmin") + "?ArtistId=";
		url = url + artistId;
		return APIService.doPut(url, request).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	// Block User
	static blockArtistService(userId) {
		let url = APIUrlConstants.getApiUrl("blockArtist") + "?userId=";
		url = url + userId;
		return APIService.doPut(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
}
