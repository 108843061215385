import React from "react";
import { Badge, Icon } from "antd";
// Redux
import { connect } from "react-redux";
import { logout, toggleLogoutDialog } from "../../redux/actions/CommonAction";
import ConfirmationMessageDialog from "../ConfirmationMessageDialog/ConfirmationMessageDialog";
// SCSS
import "./Navbar.scss";
class Navbar extends React.Component {
    state = {
        open: false,
        currentItems: [],
        isMore:
            window.location.href.endsWith("news") || window.location.href.endsWith("blog") || window.location.href.endsWith("report") ? true : false
    };

    componentDidMount() {
        let list = ["manage-users", "manage-artists", "manage-gallery", "countries-taxes", "quotes", "collections", "orders", "shipment-providers", "shipping-charges", "news", "blog", "report"]
        let location = list.find(item => window.location.href.includes(item));
        switch (location) {
            case "manage-users":
                this.scrollToView("manage-users");
                break;

            case "manage-artists":
                this.scrollToView("manage-artists");
                break;

            case "manage-gallery":
                this.scrollToView("manage-gallery");
                break;

            case "countries-taxes":
                this.scrollToView("countries-taxes");
                break;

            case "quotes":
                this.scrollToView("quotes");
                break;

            case "collections":
                this.scrollToView("collections");
                break;

            case "orders":
                this.scrollToView("orders");
                break;

            case "shipment-providers":
                this.scrollToView("shipment-providers");
                break;

            case "shipping-charges":
                this.scrollToView("shipping-charges");
                break;

            case "news":
                this.scrollToView("moreItem");
                break;

            case "blog":
                this.scrollToView("moreItem");
                break;

            case "report":
                this.scrollToView("moreItem");
                break;

            default:
                // this.scrollToView("notification");
                break;
        }
    }

    // Function lets you scroll to the element in the div, based on id
    scrollToView = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({ behavior: "smooth" });
    };

    render() {
        // const userData = Util.getUserDetails();

        const { logoutDialog } = this.props.loginReducer;

        return (
            <div className="navbar-wrapper">
                <div className="logo-box">
                    <img onClick={() => (window.location.href = "/#/dashboard")} src={require("../../assets/images/common/Logo.svg")} alt="" />
                </div>

                <div className="navbar-list">
                    <ul>
                        <li
                            id="notification"
                            className={"navbar-list-item" + (window.location.href.endsWith("notification") ? " active" : "")}
                            onClick={() => this.nextPath("notification", window.location.hash)}
                        >
                            <div className="list-image">
                                {/* <Badge count={3} overflowCount={999}> */}
                                <img
                                    alt=""
                                    src={
                                        window.location.href.endsWith("notification")
                                            ? require("../../assets/images/sidenav/Notification_active.svg")
                                            : require("../../assets/images/sidenav/Notification.svg")
                                    }
                                />
                                {/* </Badge> */}
                            </div>
                            Notification
                        </li>
                        <li
                            className={"navbar-list-item" + (window.location.href.endsWith("dashboard") ? " active" : "")}
                            onClick={() => this.nextPath("dashboard", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.endsWith("dashboard")
                                            ? require("../../assets/images/sidenav/dashboard_active.svg")
                                            : require("../../assets/images/sidenav/dashboard.svg")
                                    }
                                />
                            </div>
                            Dashboard
                        </li>
                        <li
                            className={"navbar-list-item" + (window.location.href.includes("categories") ? " active" : "")}
                            onClick={() => this.nextPath("categories", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.includes("categories")
                                            ? require("../../assets/images/sidenav/categories_active.svg")
                                            : require("../../assets/images/sidenav/categories.svg")
                                    }
                                />
                            </div>
                            Categories
                        </li>
                        <li
                            className={"navbar-list-item" + (window.location.href.includes("lookup") ? " active" : "")}
                            onClick={() => this.nextPath("lookup", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.includes("categories")
                                            ? require("../../assets/images/sidenav/categories_active.svg")
                                            : require("../../assets/images/sidenav/categories.svg")
                                    }
                                />
                            </div>
                            Lookups
                        </li>
                        <li
                            className={"navbar-list-item" + (window.location.href.includes("filter") ? " active" : "")}
                            onClick={() => this.nextPath("filter", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.includes("filter")
                                            ? require("../../assets/images/sidenav/Collection.svg")
                                            : require("../../assets/images/sidenav/Collection_active.svg")
                                    }
                                />
                            </div>
                            Filter Rules
                        </li>
                        <li
                            className={"navbar-list-item" + (window.location.href.endsWith("manage-home") ? " active" : "")}
                            onClick={() => this.nextPath("manage-home", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.endsWith("manage-home")
                                            ? require("../../assets/images/sidenav/Home View_active.svg")
                                            : require("../../assets/images/sidenav/Home View.svg")
                                    }
                                />
                            </div>
                            Home View
                        </li>
                        <li
                            className={
                                "navbar-list-item" +
                                (window.location.href.endsWith("manage-artworks") ||
                                    window.location.href.includes("seller-profile") ||
                                    window.location.href.includes("manage-artworks")
                                    ? " active"
                                    : "")
                            }
                            onClick={() => this.nextPath("manage-artworks", window.location.hash)}
                        >
                            <div className="list-image">
                                {/* <Badge count={100} overflowCount={999}> */}
                                <img
                                    alt=""
                                    src={
                                        window.location.href.endsWith("manage-artworks") ||
                                            window.location.href.includes("seller-profile") ||
                                            window.location.href.includes("manage-artworks")
                                            ? require("../../assets/images/sidenav/Manage Gallery_active.svg")
                                            : require("../../assets/images/sidenav/Manage Gallery.svg")
                                    }
                                />
                                {/* </Badge> */}
                            </div>
                            Manage Artworks
                        </li>
                        <li
                            className={"navbar-list-item" + (window.location.href.includes("coupons") ? " active" : "")}
                            onClick={() => this.nextPath("coupons", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.includes("categories")
                                            ? require("../../assets/images/sidenav/categories_active.svg")
                                            : require("../../assets/images/sidenav/categories.svg")
                                    }
                                />
                            </div>
                            Manage Coupons
                        </li>
                        <li
                            className={
                                "navbar-list-item" +
                                (window.location.href.endsWith("offline-services") || window.location.href.includes("offline-services")
                                    ? " active"
                                    : "")
                            }
                            onClick={() => this.nextPath("offline-services", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.endsWith("offline-services")
                                            ? require("../../assets/images/sidenav/Manage Gallery_active.svg")
                                            : require("../../assets/images/sidenav/Manage Gallery.svg")
                                    }
                                />
                            </div>
                            Offline services
                        </li>
                        <li
                            id="manage-users"
                            className={
                                "navbar-list-item" +
                                (window.location.href.endsWith("manage-users") ||
                                    window.location.href.includes("manage-users/user-profile") ||
                                    window.location.href.includes("manage-users")
                                    ? " active"
                                    : "")
                            }
                            onClick={() => this.nextPath("manage-users", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.endsWith("manage-users") ||
                                            window.location.href.includes("manage-users/user-profile") ||
                                            window.location.href.includes("manage-users")
                                            ? require("../../assets/images/sidenav/Manage Users_active.svg")
                                            : require("../../assets/images/sidenav/Manage Users.svg")
                                    }
                                />
                            </div>
                            Manage Users
                        </li>
                        <li
                            id="manage-artists"
                            className={
                                "navbar-list-item" +
                                (window.location.href.endsWith("manage-artists") ||
                                    window.location.href.includes("manage-artists/user-profile") ||
                                    window.location.href.includes("manage-artists")
                                    ? " active"
                                    : "")
                            }
                            onClick={() => this.nextPath("manage-artists", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.endsWith("manage-artists") ||
                                            window.location.href.includes("manage-artists/user-profile") ||
                                            window.location.href.includes("manage-artists")
                                            ? require("../../assets/images/sidenav/Manage Users_active.svg")
                                            : require("../../assets/images/sidenav/Manage Users.svg")
                                    }
                                />
                            </div>
                            Manage Artists
                        </li>
                        <li
                            id="manage-gallery"
                            className={
                                "navbar-list-item" +
                                (window.location.href.endsWith("manage-gallery") ||
                                    window.location.href.includes("manage-galler/user-profile") ||
                                    window.location.href.includes("manage-gallery")
                                    ? " active"
                                    : "")
                            }
                            onClick={() => this.nextPath("manage-gallery", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.endsWith("manage-gallery") ||
                                            window.location.href.includes("manage-galler/user-profile") ||
                                            window.location.href.includes("manage-gallery")
                                            ? require("../../assets/images/sidenav/Manage Users_active.svg")
                                            : require("../../assets/images/sidenav/Manage Users.svg")
                                    }
                                />
                            </div>
                            Manage Gallery
                        </li>
                        <li
                            id="countries-taxes"
                            className={"navbar-list-item" + (window.location.href.endsWith("countries-taxes") ||
                                window.location.href.includes("countries-taxes") ? " active" : "")}
                            onClick={() => this.nextPath("countries-taxes", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.endsWith("countries-taxes") ||
                                            window.location.href.includes("countries-taxes")
                                            ? require("../../assets/images/sidenav/Collection.svg")
                                            : require("../../assets/images/sidenav/Collection_active.svg")
                                    }
                                />
                            </div>
                            Countries & Taxes
                        </li>
                        <li
                            id="quotes"
                            className={"navbar-list-item" + (window.location.href.endsWith("quotes") ||
                                window.location.href.includes("quotes") ? " active" : "")}
                            onClick={() => this.nextPath("quotes", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.endsWith("quotes") ||
                                            window.location.href.includes("quotes")
                                            ? require("../../assets/images/sidenav/Collection.svg")
                                            : require("../../assets/images/sidenav/Collection_active.svg")
                                    }
                                />
                            </div>
                            Quotes
                        </li>
                        <li
                            id="collections"
                            className={"navbar-list-item" + (window.location.href.endsWith("collections") ? " active" : "")}
                            onClick={() => this.nextPath("collections", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.endsWith("collections")
                                            ? require("../../assets/images/sidenav/Collection.svg")
                                            : require("../../assets/images/sidenav/Collection_active.svg")
                                    }
                                />
                            </div>
                            Collections
                        </li>

                        <li
                            id="orders"
                            className={
                                "navbar-list-item" +
                                (window.location.href.endsWith("orders") || window.location.href.includes("orders") ? " active" : "")
                            }
                            onClick={() => this.nextPath("orders", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.endsWith("orders") || window.location.href.includes("orders")
                                            ? require("../../assets/images/sidenav/Orders_active.svg")
                                            : require("../../assets/images/sidenav/Orders.svg")
                                    }
                                />
                            </div>
                            Orders
                        </li>
                        <li
                            id="orders"
                            className={
                                "navbar-list-item" +
                                (window.location.href.endsWith("bulk-artworks-upload") || window.location.href.includes("bulk-artworks-upload") ? " active" : "")
                            }
                            onClick={() => this.nextPath("bulk-artworks-upload", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.endsWith("bulk-artworks-upload") ||
                                            window.location.href.includes("bulk-artworks-upload")
                                            ? require("../../assets/images/sidenav/Manage Gallery_active.svg")
                                            : require("../../assets/images/sidenav/Manage Gallery.svg")
                                    }
                                />
                            </div>
                            Bulk Upload
                        </li>

                        <li
                            id="shipment-providers"
                            className={"navbar-list-item" + (window.location.href.endsWith("shipment-providers") ||
                                window.location.href.includes("shipment-providers") ? " active" : "")}
                            onClick={() => this.nextPath("shipment-providers", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.endsWith("shipment-providers") ||
                                            window.location.href.includes("shipment-providers")
                                            ? require("../../assets/images/sidenav/Collection.svg")
                                            : require("../../assets/images/sidenav/Collection_active.svg")
                                    }
                                />
                            </div>
                            Shipment Providers
                        </li>
                        <li
                            id="shipping-charges"
                            className={"navbar-list-item" + (window.location.href.endsWith("shipping-charges") ||
                                window.location.href.includes("shipping-charges") ? " active" : "")}
                            onClick={() => this.nextPath("shipping-charges", window.location.hash)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        window.location.href.endsWith("shipping-charges") ||
                                            window.location.href.includes("shipping-charges")
                                            ? require("../../assets/images/sidenav/Collection.svg")
                                            : require("../../assets/images/sidenav/Collection_active.svg")
                                    }
                                />
                            </div>
                            Shipping Charges
                        </li>
                        <li
                            id="moreItem"
                            className={
                                "navbar-list-item" +
                                (this.state.isMore ||
                                    window.location.href.endsWith("news") ||
                                    window.location.href.endsWith("blog") ||
                                    window.location.href.endsWith("report")
                                    ? " active"
                                    : "")
                            }
                            onClick={this.handleMoreClick.bind(this)}
                        >
                            <div className="list-image">
                                <img
                                    alt=""
                                    src={
                                        this.state.isMore ||
                                            window.location.href.endsWith("news") ||
                                            window.location.href.endsWith("blog") ||
                                            window.location.href.endsWith("report")
                                            ? require("../../assets/images/sidenav/more.svg")
                                            : require("../../assets/images/sidenav/more_active.svg")
                                    }
                                />
                            </div>
                            More
                            {this.state.isMore && (
                                <img alt="downArrow" src={require("../../assets/images/sidenav/Arrow_down.svg")} style={{ marginLeft: "50px" }} />
                            )}
                        </li>
                        {this.state.isMore && (
                            <div style={{ marginLeft: "50px" }}>
                                <li
                                    className={window.location.href.endsWith("news") ? "navbar-list-item active" : "navbar-list-item"}
                                    onClick={() => this.nextPath("news", window.location.hash)}
                                >
                                    News
                                </li>
                                <li
                                    className={window.location.href.endsWith("blog") ? "navbar-list-item active" : "navbar-list-item"}
                                    onClick={() => this.nextPath("blog", window.location.hash)}
                                >
                                    Blog
                                </li>
                                {/* <li
                                    className={window.location.href.endsWith("report") ? "navbar-list-item active" : "navbar-list-item"}
                                    onClick={() => this.nextPath("report", window.location.hash)}
                                >
                                    Report
                                </li> */}
                            </div>
                        )}

                        <li className="navbar-list-item" onClick={this.onLogout.bind(this, true)}>
                            <div className="list-image">
                                <Icon className="logout" type="poweroff" />
                            </div>
                            Logout
                        </li>
                    </ul>
                </div>

                {/* <div className="navbar-bottom">
                    <p className="animated-border" onClick={this.onLogout.bind(this, true)}><span>Logout</span></p>
                </div> */}

                {/* Logout Confirmation */}
                <ConfirmationMessageDialog
                    open={logoutDialog}
                    handleClose={this.onLogout.bind(this, false)}
                    confirmTitle={"Log Out"}
                    confirmMessage={"Are you sure you want to logout?"}
                    confirmButton={"Log Out"}
                    onConfirm={this.onConfirmLogout.bind(this)}
                />
            </div>
        );
    }

    // Routing to component
    nextPath(path, currentPath = "") {
        // window.location.href = "/#/" + path;
        this.props.history.push(`/${path}`);
        // TODO : If user is clicking in a tab staying on same tab itself
        // Then refresh the page contents
    }

    handleMoreClick() {
        this.setState({
            isMore: !this.state.isMore
        });
    }
    // Logout
    onLogout(value) {
        this.props.toggleLogoutDialog(value);
    }
    onConfirmLogout() {
        this.props.logout();
    }
}

const mapStateToProps = state => {
    return {
        loginReducer: state.loginReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(logout());
        },
        toggleLogoutDialog: value => {
            dispatch(toggleLogoutDialog(value));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
