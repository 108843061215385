import React, { Component } from "react";
import Topbar from "../../../components/Topbar/Topbar";
import Root from "../../Root/Root";
import { connect } from "react-redux";
import TileComponent from "../../../components/TileComponent/TileComponent";
import { Pagination } from "antd";
import { getBlogsAction, createBlogsAction } from "../../../redux/actions/BlogAction";
import { CircularProgress, Tooltip } from "@material-ui/core";
import './Blog.scss'
import EditorModalDialog from "../../../components/EditorModalDialog/EditorModalDialog";

class Blog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAddClicked: false
        };
    }
    componentDidMount() {
        this.props.getBlog(1, 10);
    }
    render() {
        const BlogList = this.props.getBlogList;
        const Loader = this.props.getBlogLoader;
        return (
            <Root>
                <Topbar title={"Blogs"} />
                {Loader ? (
                    <div className="manageusers-wrapper">
                        <CircularProgress className="circular-loader" size={50} />
                    </div>
                ) : (
                        <div className="blogcontainer">
                            {BlogList && BlogList.length !== 0 && BlogList.map(blog => <TileComponent key={blog._id} BlogData={blog} />)}
                            {this.props.blogCount > 10 && (
                                <div className="paginationWrapper">
                                    <Pagination current={0} total={this.props.blogCount || 0} onChange={this.onSelectPageNumber.bind(this)} />
                                </div>
                            )}
                            <div className="add-button-box" onClick={this.togglePopup.bind(this)}>
                                <Tooltip placement="top" title={"Add Blogs"}>
                                    <span className="add-btn">
                                        <p>+</p> Add Blogs
                                </span>
                                </Tooltip>
                            </div>
                            <EditorModalDialog
                                type="Blogs"
                                open={this.state.isAddClicked}
                                handleClose={this.togglePopup.bind(this)}
                                addBlog={this.addBlogAction.bind(this)}
                                loader={this.props.addBlogLoader}
                            />
                        </div>
                    )}
            </Root>
        );
    }
    onSelectPageNumber(PageNum) {
        this.props.getBlog(PageNum, 10);
    }
    togglePopup() {
        this.setState({
            isAddClicked: !this.state.isAddClicked
        });
    }

    async addBlogAction(value) {
        let body = {
            Title: value.Title,
            SlugId: value.SlugId,
            SeoDescription: value.SeoDescription,
            Body: value.Body,
            BlogImages: value.BlogImages
        };

        await this.props.addBlog(body);
        this.togglePopup();
    }
}

const mapStateToProps = state => {
    return {
        getBlogList: state.blogReducer.blogList,
        getBlogLoader: state.blogReducer.getBlogLoader,
        blogCount: state.blogReducer.blogCount,
        addBlogLoader: state.blogReducer.addBlogLoader
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getBlog: (pageNum, pageSize) => {
            dispatch(getBlogsAction(pageNum, pageSize));
        },
        addBlog: requestBody => {
            dispatch(createBlogsAction(requestBody));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
