import { APIUrlConstants } from "../../../util/apiUrl.constant";
import { APIService } from "../../../util/api.service";
import { Util } from "../../../util/util";

export class BlogService {
    static getBlogList(pageNum, pageSize) {
        let url = APIUrlConstants.getApiUrl("getBlog") + "?pageNum=" + pageNum + "&pageSize=" + pageSize;
        return APIService.doGet(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static updateBlog(blogId, request) {
        let url = APIUrlConstants.getApiUrl("updateBlog") + "?BlogId=" + blogId;
        let formBodyRequest = Util.objectToFormData(request);
        return APIService.doPutMultipart(url, formBodyRequest).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static deleteBlog(blogId) {
        let url = APIUrlConstants.getApiUrl("deleteBlog") + "?BlogId=" + blogId;
        return APIService.doDelete(url).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }

    static addBlog(request) {
        let url = APIUrlConstants.getApiUrl("addBlog");
        let formBodyRequest = Util.objectToFormData(request);
        return APIService.doUploadMultipart(url,formBodyRequest).then(resp => {
            if (resp) {
                return resp;
            }
            throw resp;
        });
    }
}
