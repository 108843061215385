import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
import { Pagination, Table, Icon, Tooltip, Popover } from "antd";
import { connect } from "react-redux";
import { isArray, isEmpty, map, includes } from "lodash";

// Components
import Root from "../../Root/Root";
import Topbar from "../../../components/Topbar/Topbar";
import FilterDropdown from "../../Collections/AddCollectionDialog/FilterDropdown/FilterDropdown";
import FilterSlider from "../../Collections/AddCollectionDialog/FilterSlider/FilterSlider";
import { popToast } from "../../../components/Toast/Toast";

//Actions
import { getAllLookUpProperty } from '../../../redux/actions/LookupAction'
import { getAllArtists } from "../../../redux/actions/CollectionAction";
import { getAllCountryListAction } from "../../../redux/actions/CountriesTaxAction";
import { insertFilterRule, getAllFilterRulesById, updateFilterRule } from '../../../redux/actions/FilterRuleAction'


import "./AddFilter.scss"
// const { Option } = Select;
//Product Size
const productSize = [
    { name: "Small (< 18 in)", value: 0 },
    { name: "Medium (18 - 48 in)", value: 1 },
    { name: "Large (48 - 72 in)", value: 2 },
    { name: "Extra-Large (> 72 in)", value: 3 }
];

//Orientation
const orientation = [
    { name: "Portrait", value: 0 },
    { name: "Landscape", value: 1 },
    { name: "Square", value: 2 },
];

//Packaging
const packaging = [
    { name: "Canvas Role", value: 0 },
    { name: "Boxed", value: 1 },
    { name: "Crate", value: 2 },
];
//Panel
const panel = [
    { name: "Single Panel", value: 0 },
    { name: "Multi Panel", value: 1 },
];
//Framed
const framed = [
    { name: "Framed", value: 0 },
    { name: "Not Framed", value: 1 },
];
//Limited Edition
const limitedEdition = [
    { name: "Unique", value: 0 },
    { name: "Limited Edition", value: 1 },

];
const abstract = [
    { name: "Abstract", value: 0 },
    { name: "Figurative", value: 1 },
];
class UpdateFilterRule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSubject: [],
            selectedStyle: [],
            selectedMaterial: [],
            selectedMedium: [],
            selectedOrientation: [],
            selectedSize: [],
            selectedPanel: [],
            selectedFrame: [],
            selectedLimitedEdition: [],
            selectedPackaging: [],
            selectedPrice: [],
            selectedArtists: [],
            selectedAbstract: [],
            minPrice: 0,
            maxPrice: 0,
            selectedArtistCountry: '',
            ruleName: "",
            buttonList: [],
            filterId: ''

        };
    }

    componentDidMount() {

        const filterId = this.props.match && this.props.match.params && this.props.match.params.filterId;
        // let body = {
        //     "PropertyNames": [
        //         "STYLE",
        //         "SUBJECT",
        //         "MATERIALS",
        //         "MEDIUM"
        //     ]
        // }
        // this.props.getAllLookUpProperty(body);
        this.props.getAllArtists();
        this.props.getAllCountryListAction();
        this.props.getAllFilterRulesById(filterId)
    }
    componentWillReceiveProps(prevProps, prevState) {

        if (prevProps.filterRuleReducer.isUpdated !== this.props.filterRuleReducer.isUpdated) {
            if (prevProps.filterRuleReducer.isUpdated) {

            }
        }
        if (prevProps.filterRuleReducer.singleFilter !== this.props.filterRuleReducer.singleFilter) {
            console.log("🚀 ~ file: UpdateFilterRule.js ~ line 111 ~ UpdateFilterRule ~ componentWillReceiveProps ~ this.props.filterRuleReducer.singleFilter", this.props.filterRuleReducer)

            if (prevProps.filterRuleReducer.singleFilter && !isEmpty(prevProps.filterRuleReducer.singleFilter)) {
                const { artistCountry, artistId, isFramed, isLatest, isLimitedEdition, isSinglePanel, materialsUsed, medium,
                    orientation, packaging, priceMin, priceMax, productSize, style, subject, isAbstract } = prevProps.filterRuleReducer.singleFilter.filterObject
                this.setState({
                    selectedSubject: subject,
                    selectedStyle: style,
                    selectedMaterial: materialsUsed,
                    selectedMedium: medium,
                    selectedOrientation: orientation,
                    selectedSize: productSize,
                    selectedPanel: isSinglePanel !== null ? isSinglePanel ? [0] : [1] : [],
                    selectedFrame: isFramed !== null ? isFramed ? [0] : [1] : [],
                    selectedLimitedEdition: isLimitedEdition !== null ? isLimitedEdition ? [1] : [0] : [],
                    selectedPackaging: packaging,
                    selectedPrice: [],
                    selectedArtists: artistId,
                    minPrice: priceMin,
                    maxPrice: priceMax,
                    selectedAbstract: isAbstract !== null && isAbstract !== undefined ? isAbstract ? [0] : [1] : [],
                    selectedArtistCountry: artistCountry,
                    ruleName: prevProps.filterRuleReducer.singleFilter.name,
                    filterId: prevProps.filterRuleReducer.singleFilter._id

                }, () => {
                    console.log("🚀 ~ file: UpdateFilterRule.js ~ line 136 ~ UpdateFilterRule ~ componentWillReceiveProps ~ this.state", this.state)
                    this.bindSelectedButtons()
                })
            }
        }
        if (this.props.lookupReducer.allPropertyList !== prevProps.lookupReducer.allPropertyList) {
            if (prevProps.lookupReducer.allPropertyList && prevProps.lookupReducer.allPropertyList.length > 0) {
                this.bindSelectedButtons()
            }
        }
    }
    onSetSelectedArray = (selectType, list) => {
        if (selectType === 'subject') {
            this.setState({
                selectedSubject: list
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'style') {
            this.setState({
                selectedStyle: list
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'materials') {
            this.setState({
                selectedMaterial: list
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'medium') {
            this.setState({
                selectedMedium: list
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'orientation') {
            this.setState({
                selectedOrientation: list
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'size') {
            this.setState({
                selectedSize: list
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'panel') {
            this.setState({
                selectedPanel: list
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'frame') {
            this.setState({
                selectedFrame: list
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'packaging') {
            this.setState({
                selectedPackaging: list
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'limited') {
            this.setState({
                selectedLimitedEdition: list
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'artist') {
            this.setState({
                selectedArtists: list
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'abstract_key') {
            this.setState({
                selectedAbstract: []
            }, () => {
                this.bindSelectedButtons();
            })
        }
        else if (selectType === 'abstract') {
            this.setState({
                selectedAbstract: list
            }, () => {
                this.bindSelectedButtons();
            })
        }


    };
    //Remove Selected
    onRemoveSelectedArray = (selectType) => {
        if (selectType === 'subject_key') {
            this.setState({
                selectedSubject: []
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'style_key') {
            this.setState({
                selectedStyle: []
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'material_key') {
            this.setState({
                selectedMaterial: []
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'medium_key') {
            this.setState({
                selectedMedium: []
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'orientation_key') {
            this.setState({
                selectedOrientation: []
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'size_key') {
            this.setState({
                selectedSize: []
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'panel_key') {
            this.setState({
                selectedPanel: []
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'frame_key') {
            this.setState({
                selectedFrame: []
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'packaging_key') {
            this.setState({
                selectedPackaging: []
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'limited_key') {
            this.setState({
                selectedLimitedEdition: []
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'artist_key') {
            this.setState({
                selectedArtists: []
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'price_key') {
            this.setState({
                minPrice: null,
                maxPrice: null
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'artist_country_key') {
            this.setState({
                selectedArtistCountry: ''
            }, () => {
                this.bindSelectedButtons();
            })
        } else if (selectType === 'abstract_key') {
            this.setState({
                selectedAbstract: []
            }, () => {
                this.bindSelectedButtons();
            })
        }





    };
    // Set Price
    onSetPrice = (min, max) => {
        this.setState(
            {
                minPrice: min,
                maxPrice: max
            }, () => {

                this.bindSelectedButtons()
            })

    };
    //Artist Country 

    onChangeArtistCountry(e) {
        this.setState(
            {
                selectedArtistCountry: e.target.value,

            }, () => {
                this.bindSelectedButtons()
            });
    };
    handleChangeText(e) {
        this.setState({
            ruleName: e.target.value,
        })
    }
    onClickSaveRules = () => {
        const { ruleName, minPrice, maxPrice, selectedSize, selectedMaterial,
            selectedMedium, selectedOrientation, selectedPackaging,
            selectedStyle, selectedSubject, selectedPanel, selectedFrame, selectedArtistCountry, selectedArtists, selectedLimitedEdition, selectedAbstract } = this.state;
        if (!ruleName.trim()) {
            popToast.show("Please enter the rule name");
        } else {

            let isPaneled = null;
            let isFramed = null;
            let isLimited = null;
            let isAbstract = null;
            if (selectedPanel && selectedPanel.length > 0) {
                isPaneled = includes(selectedPanel, 0) ? true : false;
            }
            if (selectedFrame && selectedFrame.length > 0) {
                isFramed = includes(selectedFrame, 0) ? true : false;
            }
            if (selectedLimitedEdition && selectedLimitedEdition.length > 0) {
                isLimited = includes(selectedLimitedEdition, 1) ? true : false;
            }
            if (selectedAbstract && selectedAbstract.length > 0) {
                if (selectedAbstract.length === 2)
                    isAbstract = null;
                else
                    isAbstract = includes(selectedAbstract, 0) ? true : false;
            }

            var filterOBj = {
                "ArtistId": selectedArtists,
                "Style": selectedStyle,
                "Subject": selectedSubject,
                "MaterialsUsed": selectedMaterial,
                "Packaging": selectedPackaging,
                "IsFramed": isFramed,
                "IsSinglePanel": isPaneled,
                "IsLimitedEdition": isLimited,
                "Orientation": selectedOrientation,
                "ProductSize": selectedSize,
                "ArtistCountry": selectedArtistCountry,
                "IsLatest": null,
                "IsFeatured": null,
                "PriceMin": minPrice === 0 ? null : minPrice,
                "PriceMax": maxPrice === 0 ? null : maxPrice,
                "Sort": 2,
                "PageNum": 1,
                "PageSize": 50,
                "Medium": selectedMedium,
                "isAbstract": isAbstract

            };

            let url = `artworks?IsFeatured=true&IsLatest=true`
            if (selectedArtistCountry !== '') {
                url = url + `&ArtistCountry=${selectedArtistCountry}`;
            }
            if (selectedArtists && selectedArtists.length > 0) {
                url = url + `&ArtistId=${selectedArtists.join(',')}`;
            }
            if (selectedFrame && selectedFrame.length > 0) {
                url = url + `&Framed=${selectedFrame.join(',')}`;
            }
            if (selectedLimitedEdition && selectedLimitedEdition.length > 0) {
                url = url + `&LimitedEdition=${selectedLimitedEdition.join(',')}`;
            }
            if (selectedMaterial && selectedMaterial.length > 0) {
                url = url + `&MaterialsUsed=${selectedMaterial.join(',')}`;
            }
            if (selectedOrientation && selectedOrientation.length > 0) {
                url = url + `&Orientation=${selectedOrientation.join(',')}`;
            }
            if (selectedPanel && selectedPanel.length > 0) {
                url = url + `&Panel=${selectedPanel.join(',')}`;
            }
            if (maxPrice !== 0) {
                url = url + `&PriceMax=${maxPrice}`;
            }
            if (minPrice !== 0) {
                url = url + `&PriceMin=${minPrice}`;
            }
            if (selectedPackaging && selectedPackaging.length > 0) {
                url = url + `&Packaging=${selectedPackaging.join(',')}`;
            }
            if (selectedStyle && selectedStyle.length > 0) {
                url = url + `&Style=${selectedStyle.join(',')}`;
            }
            if (selectedSubject && selectedSubject.length > 0) {
                url = url + `&Subject=${selectedSubject.join(',')}`;
            }
            if (selectedMedium && selectedMedium.length > 0) {
                url = url + `&Medium=${selectedMedium.join(',')}`;

            }
            if (selectedSize && selectedSize.length > 0) {
                url = url + `&ProductSize=${selectedSize.join(',')}`;

            }

            let body = {
                "Name": this.state.ruleName,
                "FilterURL": url,
                "FilterObject": filterOBj

            }
            console.log("🚀 ~ file: UpdateFilterRule.js ~ line 428 ~ UpdateFilterRule ~ body", body)
            this.props.updateFilterRule(body, this.state.filterId);
            this.props.history.push(`/filter`);
        }
    }

    stringToArray = arr => {
        return isArray(arr) ? arr : [...[], arr];
    };
    bindSelectedButtons() {
        const { minPrice, maxPrice, selectedSize, selectedMaterial,
            selectedMedium, selectedOrientation, selectedPackaging,
            selectedStyle, selectedSubject, selectedPanel, selectedFrame, selectedArtistCountry, selectedArtists, selectedLimitedEdition, selectedAbstract } = this.state;
        const { allPropertyList } = this.props.lookupReducer;
        const { artistsList } = this.props.collectionReducer;
        let btnLists = [];

        //Price Button
        if (maxPrice !== null || minPrice !== null) {
            if (maxPrice !== 0 || minPrice !== 0) {
                var obj = {
                    text: [`USD ${minPrice} - ${maxPrice}`],
                    id: 'price_key',
                    name: 'Price'
                };
                btnLists.push(obj);
            }
        }

        //Size filter
        if (selectedSize) {
            var sizeText = [];
            this.stringToArray(selectedSize).map((Number) => {
                if (Number === 0) {
                    sizeText = [...sizeText, 'Small']
                } else if (Number === 1) {
                    sizeText = [...sizeText, 'Medium']
                } else if (Number === 2) {
                    sizeText = [...sizeText, 'Large']
                } else if (Number === 3) {
                    sizeText = [...sizeText, 'Extra-Large']
                }
            })
            if (sizeText && sizeText.length > 0) {
                var obj = {
                    text: sizeText,
                    id: "size_key",
                    name: 'Size'
                };
                btnLists.push(obj);
            }
        }

        //Selected MaterialsUsed List For button
        if (selectedMaterial) {
            var materialButtonText = [];
            this.stringToArray(selectedMaterial).map((value) => {
                allPropertyList && allPropertyList.materials && allPropertyList.materials.filter(item => item.slugId === value).map((data) => {
                    materialButtonText = [...materialButtonText, data.name]
                })
            })
            if (materialButtonText && materialButtonText.length > 0) {
                var obj = {
                    text: materialButtonText,
                    id: "material_key",
                    name: 'Materials'
                };
                btnLists.push(obj);
            }
        }
        //Selected Subject List For button
        if (selectedSubject) {
            var subjectButtonText = [];
            this.stringToArray(selectedSubject).map((value) => {
                allPropertyList && allPropertyList.artworkSubject && allPropertyList.artworkSubject.filter(item => item.slugId === value).map((data) => {
                    subjectButtonText = [...subjectButtonText, data.name]
                })
            })
            if (subjectButtonText && subjectButtonText.length > 0) {
                var obj = {
                    text: subjectButtonText,
                    id: "subject_key",
                    name: 'Subject'

                };
                btnLists.push(obj);
            }
        }

        //Selected Style List For button
        if (selectedStyle) {
            var styleButtonText = [];
            this.stringToArray(selectedStyle).map((value) => {

                allPropertyList && allPropertyList.artworkStyle && allPropertyList.artworkStyle.filter(item => item.slugId === value).map((data) => {
                    styleButtonText = [...styleButtonText, data.name]
                })
            })
            if (styleButtonText && styleButtonText.length > 0) {
                var obj = {
                    text: styleButtonText,
                    id: "style_key",
                    name: 'Style'

                };
                btnLists.push(obj);
            }
        }
        if (selectedArtists) {
            var artistText = [];
            this.stringToArray(selectedArtists).map((value) => {
                artistsList && artistsList.artistList && artistsList.artistList.filter(item => item.userId === value).map((data) => {
                    artistText = [...artistText, data.name]
                })
            })
            if (artistText && artistText.length > 0) {
                var obj = {
                    text: artistText,
                    id: "artist_key",
                    name: 'Artist'

                };
                btnLists.push(obj);
            }
        }
        //Selected Medium List For button
        if (selectedMedium) {
            var mediumButtonText = [];
            this.stringToArray(selectedMedium).map((value) => {

                allPropertyList && allPropertyList.artworkMedium && allPropertyList.artworkMedium.filter(item => item.slugId === value).map((data) => {
                    mediumButtonText = [...mediumButtonText, data.name]
                })
            })
            if (mediumButtonText && mediumButtonText.length > 0) {
                var obj = {
                    text: mediumButtonText,
                    id: "medium_key",
                    name: 'Medium'

                };
                btnLists.push(obj);
            }
        }
        //Selected Packaging List For button
        if (selectedPackaging) {
            var packagingButtonText = [];
            this.stringToArray(selectedPackaging).map((value) => {
                if (value === 0) {
                    packagingButtonText = [...packagingButtonText, 'Canvas Role']
                } else if (value === 1) {
                    packagingButtonText = [...packagingButtonText, 'Boxed']
                } else if (value === 2) {
                    packagingButtonText = [...packagingButtonText, 'Crate']
                }
            })
            if (packagingButtonText && packagingButtonText.length > 0) {
                var obj = {
                    text: packagingButtonText,
                    id: "packaging_key",
                    name: 'Package'

                };
                btnLists.push(obj);
            }
        }

        // Orientation
        if (selectedOrientation) {
            var orientationText = [];
            this.stringToArray(selectedOrientation).map((Number) => {
                if (Number === 0) {
                    orientationText = [...orientationText, 'Portrait']
                } else if (Number === 1) {
                    orientationText = [...orientationText, 'Landscape']
                } else if (Number === 3) {
                    orientationText = [...orientationText, 'Square']
                }
            })
            if (orientationText && orientationText.length > 0) {
                var obj = {
                    text: orientationText,
                    id: "orientation_key",
                    name: 'Orientation'

                };
                btnLists.push(obj);
            }

        }

        //Selected Panel List For button
        if (selectedPanel) {
            var panelButton = [];
            this.stringToArray(selectedPanel).map((value) => {
                if (value === 0) {
                    panelButton = [...panelButton, 'Single Panel']
                } else if (value === 1) {
                    panelButton = [...panelButton, 'Multi Panel']
                }
            })
            if (panelButton && panelButton.length > 0) {
                var obj = {
                    text: panelButton,
                    id: "panel_key",
                    name: 'Panel'

                };
                btnLists.push(obj);
            }
        }

        //Selected Limited edition
        if (selectedLimitedEdition) {
            var limitedButton = [];
            this.stringToArray(selectedLimitedEdition).map((value) => {
                if (value === 0) {
                    limitedButton = [...limitedButton, 'Unique']
                } else if (value === 1) {
                    limitedButton = [...limitedButton, 'Limited Edition']
                }
            })
            if (limitedButton && limitedButton.length > 0) {
                var obj = {
                    text: limitedButton,
                    id: "limited_key",
                    name: 'RARITY'

                };
                btnLists.push(obj);
            }
        }

        //Selected Frame List For button
        if (selectedFrame) {
            var frameButton = [];
            this.stringToArray(selectedFrame).map((value) => {
                if (value === 0) {
                    frameButton = [...frameButton, 'Framed']
                } else if (value === 1) {
                    frameButton = [...frameButton, 'Not Framed']
                }
            })
            if (frameButton && frameButton.length > 0) {
                var obj = {
                    text: frameButton,
                    id: "frame_key",
                    name: 'Frame'

                };
                btnLists.push(obj);
            }
        }

        //Selected Abstract List For button
        if (selectedAbstract) {
            var abstractButton = [];
            this.stringToArray(selectedAbstract).map((value) => {
                if (value === 0) {
                    abstractButton = [...abstractButton, 'Abstract']
                } else if (value === 1) {
                    abstractButton = [...abstractButton, 'Figurative']
                }
            })
            if (abstractButton && abstractButton.length > 0) {
                var obj = {
                    text: abstractButton,
                    id: "abstract_key",
                    name: 'Figurative/Abstract'

                };
                btnLists.push(obj);
            }
        }

        if (selectedArtistCountry !== '') {

            var obj = {
                text: [`${selectedArtistCountry}`],
                id: "artist_country_key",
                name: 'Artist Country'

            };
            btnLists.push(obj);
        }


        this.setState({
            buttonList: btnLists
        })

    }
    render() {
        const { allPropertyList } = this.props.lookupReducer;
        const { artistsList } = this.props.collectionReducer;
        const { countriesList } = this.props.countriesTaxReducer;
        const { isLoading, isAdded } = this.props.filterRuleReducer;

        const countryList = map(countriesList, o => (o.name)) || [];
        const { buttonList } = this.state;
        console.log("🚀 ~ file: AddFilterRule.js ~ line 218 ~ AddFilterRule ~ render ~ buttonList", buttonList)

        return (
            <Root>
                <Topbar title={"Update Rule"} showHead showBreadCrumbs />
                <div className="save-entry-wrapper">
                    <p>Enter rule name  </p>
                    <input type="text" className="search-text" name="ruleName" value={this.state.ruleName} onChange={this.handleChangeText.bind(this)} />

                    <button disabled={isLoading} onClick={() => this.onClickSaveRules()}>
                        {isLoading ? 'Please wait..' : 'Update'}
                        {isLoading && <CircularProgress size={24} className="circular-loader modal-button-loader" />}</button>
                </div>
                <div className="new-filter-wrapper">

                    <div className="main-list">
                        {/* price */}
                        <FilterSlider
                            filterFor="Price"
                            title="Price"
                            defaultMinValue={1}
                            defaultMaxValue={100000}
                            minValue={this.state.minPrice}
                            maxValue={this.state.maxPrice}
                            returnBackPriceObj={this.onSetPrice.bind(this)}
                        />
                        {/* Size */}
                        <FilterDropdown
                            selectedList={this.state.selectedSize}
                            checkboxList={productSize}
                            idToFetch="value"
                            nameToDisplay="name"
                            filterFor="Size"
                            returnBackQueryArray={this.onSetSelectedArray.bind(this, 'size')}
                        />
                        {/* Medium */}
                        <FilterDropdown
                            selectedList={this.state.selectedMedium}
                            checkboxList={allPropertyList && allPropertyList.artworkMedium}
                            idToFetch="slugId"
                            nameToDisplay="name"
                            filterFor="Medium"
                            returnBackQueryArray={this.onSetSelectedArray.bind(this, 'medium')}
                        />
                        {/* Material */}
                        <FilterDropdown
                            selectedList={this.state.selectedMaterial}
                            checkboxList={allPropertyList && allPropertyList.materials}
                            idToFetch="slugId"
                            nameToDisplay="name"
                            filterFor="Materials Used"
                            returnBackQueryArray={this.onSetSelectedArray.bind(this, 'materials')}
                        />
                        {/* RARITY (UNIQUE / LE) */}
                        <FilterDropdown
                            selectedList={this.state.selectedLimitedEdition}
                            checkboxList={limitedEdition}
                            idToFetch="value"
                            nameToDisplay="name"
                            filterFor="RARITY (UNIQUE / LE) "
                            returnBackQueryArray={this.onSetSelectedArray.bind(this, 'limited')}
                        />
                        {/* Artist Country list */}

                        <select placeholder="Select Artist Country" className="filter-field" onChange={this.onChangeArtistCountry.bind(this)} value={this.state.selectedArtistCountry}>
                            <option value="0">Select Artist Country</option>
                            {countriesList &&
                                countriesList.map((item, index) => (
                                    <option key={index} value={item.value}>
                                        {item.name}
                                    </option>
                                ))}
                        </select>
                        {/* Artist List */}
                        <FilterDropdown
                            selectedList={this.state.selectedArtists}
                            checkboxList={artistsList && artistsList.artistList}
                            idToFetch="userId"
                            nameToDisplay="name"
                            filterFor="Artist"
                            returnBackQueryArray={this.onSetSelectedArray.bind(this, 'artist')}
                        />

                        {/* Style List */}
                        <FilterDropdown
                            selectedList={this.state.selectedStyle}
                            checkboxList={allPropertyList && allPropertyList.artworkStyle}
                            idToFetch="slugId"
                            nameToDisplay="name"
                            filterFor="Style"
                            returnBackQueryArray={this.onSetSelectedArray.bind(this, 'style')}
                        />

                        {/* Subject List */}
                        <FilterDropdown
                            selectedList={this.state.selectedSubject}
                            checkboxList={allPropertyList && allPropertyList.artworkSubject}
                            idToFetch="slugId"
                            nameToDisplay="name"
                            filterFor="Subject"
                            returnBackQueryArray={this.onSetSelectedArray.bind(this, 'subject')}
                        />

                        {/* Orientation */}
                        <FilterDropdown
                            selectedList={this.state.selectedOrientation}
                            checkboxList={orientation}
                            idToFetch="value"
                            nameToDisplay="name"
                            filterFor="Orientation"
                            returnBackQueryArray={this.onSetSelectedArray.bind(this, 'orientation')}
                        />

                        {/* Panel */}
                        <FilterDropdown
                            selectedList={this.state.selectedPanel}
                            checkboxList={panel}
                            idToFetch="value"
                            nameToDisplay="name"
                            filterFor="Panel"
                            returnBackQueryArray={this.onSetSelectedArray.bind(this, 'panel')}
                        />
                        {/* Framed */}
                        <FilterDropdown
                            selectedList={this.state.selectedFrame}
                            checkboxList={framed}
                            idToFetch="value"
                            nameToDisplay="name"
                            filterFor="Framed"
                            returnBackQueryArray={this.onSetSelectedArray.bind(this, 'frame')}
                        />
                        {/* Packaging */}
                        <FilterDropdown
                            selectedList={this.state.selectedPackaging}
                            checkboxList={packaging}
                            idToFetch="value"
                            nameToDisplay="name"
                            filterFor="Packaging"
                            returnBackQueryArray={this.onSetSelectedArray.bind(this, 'packaging')}
                        />
                        {/* Abstract Figurative */}
                        <FilterDropdown
                            selectedList={this.state.selectedAbstract}
                            checkboxList={abstract}
                            idToFetch="value"
                            nameToDisplay="name"
                            filterFor="Figurative/Abstract"
                            returnBackQueryArray={this.onSetSelectedArray.bind(this, 'abstract')}
                        />

                    </div>
                    <div className="button-box-wrapper">
                        {buttonList && buttonList.length > 0 && buttonList.map((data, key) =>
                            <div className="button-box">
                                <p>{`${data.name}:${data.text.join('+')}`}</p>
                                <span onClick={this.onRemoveSelectedArray.bind(this, data.id)}>x</span>
                            </div>
                        )}


                    </div>

                </div>
            </Root>
        )
    }
}
const mapStateToProps = state => {
    return {
        filterRuleReducer: state.filterRuleReducer,
        lookupReducer: state.lookupReducer,
        collectionReducer: state.collectionReducer,
        countriesTaxReducer: state.countriesTaxReducer,
        filterRuleReducer: state.filterRuleReducer

    };
};

const mapDispatchToProps = dispatch => {
    return {

        getAllLookUpProperty: (body) => {
            dispatch(getAllLookUpProperty(body))
        },
        getAllArtists: () => {
            dispatch(getAllArtists());
        },
        getAllCountryListAction: () => {
            dispatch(getAllCountryListAction());
        },
        insertFilterRule: (body) => {
            dispatch(insertFilterRule(body))
        },
        getAllFilterRulesById: (filterId) => {
            dispatch(getAllFilterRulesById(filterId))
        },
        updateFilterRule: (body, id) => {
            dispatch(updateFilterRule(body, id))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateFilterRule);
