import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";
import { Util } from "../../util/util";

export class ManageArtworkService {
	static getArtworksList(body) {
		let url = APIUrlConstants.getApiUrl("getArtworksList");
		return APIService.doPost(url, body).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static updateProductStatus(updateData) {
		let url = APIUrlConstants.getApiUrl("updateProductStatus") + "?stockId=" + updateData.stockId + "&productStatus=" + updateData.status;
		return APIService.doPut(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static deleteProduct(stockId) {
		let url = APIUrlConstants.getApiUrl("deleteProduct");
		url = Util.beautifyUrl(url, [stockId]);
		return APIService.doDelete(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static unHoldProduct(stockId) {
		let url = APIUrlConstants.getApiUrl("unHoldProduct") + "?stockId=" + stockId;
		return APIService.doPut(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static adminDownloadProduct(body) {
		let url = APIUrlConstants.getApiUrl("adminDownloadProduct");
		return APIService.doPostDownload(url, body).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static setArtworkFeatured(artworkId) {
		let url = APIUrlConstants.getApiUrl("featureArtworks") + "?artworkId=" + artworkId;
		return APIService.doPut(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
	static uploadArtwork(body) {
		let url = APIUrlConstants.getApiUrl("bulkUpload");
		console.log("🚀 ~ file: ManageArtworks.service.js ~ line 58 ~ ManageArtworkService ~ uploadArtwork ~ url", url);
		return APIService.doUploadMultipart(url, body).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static submitArtworktoBlockchain(stockId) {
		let url = APIUrlConstants.getApiUrl("submitArtworktoBlockchain") + "?StockId=" + stockId;
		return APIService.doPut(url).then((resp) => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
}
