import * as types from "./ActionsTypes";
import { EditProductService } from "../../containers/EditProduct/EditProduct.service";
import { popToast } from "../../components/Toast/Toast";

// ============================= Get All Categories ============================= //
export const getAllCategories = (parentId) => {
	return (dispatch, getState) => {
		dispatch(request());
		EditProductService.getCategory(parentId).then(
			(response) => {
				if (response && response.result === 200) {
					dispatch(success(response.data));
				} else {
					dispatch(failure(response));
				}
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.GET_CATEGORY_REQUEST };
	}
	function success(payload) {
		return { type: types.GET_CATEGORY_SUCCESS, payload };
	}
	function failure(error) {
		return { type: types.GET_CATEGORY_FAILURE, error };
	}
};

// ============================= Get Product ============================= //
export function getProductDetailsAction(id, stockIdValue) {
	return (dispatch) => {
		dispatch(request());
		if (id) {
			EditProductService.getProductBySlug(id, stockIdValue).then(
				async (response) => {
					if (response && response.result === 200) {
						if (response.data) {
							const resp = response.data;
							// dispatch(getParentsByCategoryId(resp.categoryId));
							// dispatch(getCategoryPropertiesAndMap(resp));
							dispatch(success(resp));
						} else popToast.show("Data not able to fetch.");
					} else {
						popToast.show("Some error occured while fetching artwork. " + response.message || "");
						dispatch(failure(response));
					}
				},
				(error) => {
					dispatch(failure(error));
					popToast.show("Some error occured while fetching artwork. ");
				}
			);
		}
	};

	function request() {
		return { type: types.GET_PRODUCT_REQUEST };
	}
	function success(payload) {
		console.log("🚀 ~ file: EditProductAction.js ~ line 65 ~ success ~ payload", payload);
		return { type: types.GET_PRODUCT_SUCCESS, payload };
	}
	function failure(error) {
		return { type: types.GET_PRODUCT_FAILURE, error };
	}
}

// ============================= Get Category Properties ============================= //
export function getCategoryPropertiesAndMap(productData) {
	return (dispatch, getState) => {
		dispatch(setDetails(productData));
		EditProductService.getCategoryProperty(productData.categoryId).then(
			(response) => {
				if (response && response.result === 200) {
					if (response.data) {
						// Initialising value fields in product property array
						let newPropArray = response.data.map((item, index) => {
							const { justFilter = false, propertySuggestions = [] } = item;
							if (!justFilter) {
								if (propertySuggestions.length > 0) {
									return {
										isSelectDropdown: true,
										propertyId: item._id,
										propertyName: item.propertyName,
										dataType: item.dataType,
										propertyUnit: item.unit,
										value: item.dataType === 4 ? [] : item.dataType === 3 ? false : "",
										isMandatory: item.manadatory,
										chooseFromSuggestion: item.chooseFromSuggestion,
										propertySuggestions: item.propertySuggestions,
									};
								} else {
									return {
										isSelectDropdown: false,
										propertyId: item._id,
										propertyName: item.propertyName,
										dataType: item.dataType,
										propertyUnit: item.unit,
										value: item.dataType === 4 ? [] : item.dataType === 3 ? false : "",
										isMandatory: item.manadatory,
									};
								}
							}
						});

						// Get from API : Map value of every property from API to custom array
						if (productData && productData.productProperties) {
							const updatedPropArray = newPropArray.filter((val, index) => {
								const objectFound = productData.productProperties.find(function (o) {
									return o.propertyId === val.propertyId;
								});
								if (objectFound) {
									val.value = objectFound.value;
								}
								return val;
							});
							dispatch(success(updatedPropArray));
						}

						// Map plain array to reducer
						else dispatch(success(newPropArray));
					}
				} else {
					dispatch(failure(response));
					popToast.show("Some error occured. " + response.message || "");
				}
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function setDetails(payload) {
		return { type: types.SET_PRODUCT_DETAILS, payload };
	}
	function request() {
		return { type: types.GET_CATEGORY_PROPERTIES_AND_MAP_REQUEST };
	}
	function success(payload) {
		return { type: types.GET_CATEGORY_PROPERTIES_AND_MAP_SUCCESS, payload };
	}
	function failure(error) {
		return { type: types.GET_CATEGORY_PROPERTIES_AND_MAP_FAILURE, error };
	}
}

// ============================= Get Parents List Array ============================= //
export function getParentsByCategoryId(id) {
	return (dispatch) => {
		dispatch(request());
		EditProductService.getParentsByCategoryId(id).then(
			(response) => {
				if (response && response.result === 200) {
					if (response.data) {
						let respReverse = response.data.reverse();
						let newArray = respReverse.map((item) => {
							return item._id;
						});
						dispatch(success(newArray));
					}
				} else {
					dispatch(failure());
					popToast.show("Some error occured. " + response.message || "");
				}
			},
			(error) => {
				// TODO
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: types.GET_PARENTS_BY_CATEGORY_REQUEST };
	}
	function success(payload) {
		return { type: types.GET_PARENTS_BY_CATEGORY_SUCCESS, payload };
	}
	function failure(error) {
		return { type: types.GET_PARENTS_BY_CATEGORY_FAILURE, error };
	}
}

// ============================= Update Product ============================= //
export function onUpdateProduct(request) {
	return async (dispatch, getState) => {
		dispatch(setDetails(request));
		dispatch(requestAction());

		// let reducerValues = getState().updateProductManagement;
		let reducerValues = request;

		// TODO : Issue with this method is, the original object in reducer that is mapped to component also changes
		// This should be prevented
		// Keep an array to save in reducer and an array to pass over to api
		// Unless stringify error gets multiplied
		await reducerValues.productProperties.map((item, index) => {
			if (item.dataType === 4) {
				// TODO: JSON.stringify(compact(item.value))
				item.value = typeof item.value !== "string" ? JSON.stringify(item.value) : item.value;
				return item;
			}
			if (item.dataType === 3) {
				item.value = typeof item.value !== "string" ? JSON.stringify(item.value) : item.value;
				return item;
			}
			if (item.dataType === 1 || item.dataType === 2) {
				item.value = typeof item.value !== "string" ? item.value.toString() : item.value;
				return item;
			} else return item;
		});

		let requestObj = {
			...reducerValues,
			dimensions: {
				height: reducerValues.height,
				width: reducerValues.width,
				depth: reducerValues.depth || 0,
			},
			packageDimensions: {
				height: reducerValues.packageHeight,
				width: reducerValues.packageWidth,
				depth: reducerValues.packageDepth || 0,
			},
		};

		delete requestObj.displayPicture;

		console.log("((((((((((((REQUEST BODY OF UPDATE PRODUCT))))))))))))", requestObj);

		EditProductService.updateProduct(reducerValues.productId, requestObj).then(
			(response) => {
				if (response && response.result === 200) {
					dispatch(success());
					popToast.show("Successfully updated artwork details.");
				} else {
					dispatch(failure());
					popToast.show("Something went wrong while updating artwork details." + response.message || "");
				}
			},
			(error) => {
				dispatch(failure(error));
				popToast.show("Something went wrong while updating artwork details.");
			}
		);
	};

	function setDetails(payload) {
		return { type: types.SET_PRODUCT_DETAILS, payload };
	}
	function requestAction(payload) {
		return { type: types.UPDATE_PRODUCT_REQUEST, payload };
	}
	function success(payload) {
		return { type: types.UPDATE_PRODUCT_SUCCESS, payload };
	}
	function failure(error) {
		return { type: types.UPDATE_PRODUCT_FAILURE, error };
	}
}

// ============================= Update images and videos ============================= //
export function onUpdateImagesAndVideos(request, pageNum = 1) {
	return (dispatch, getState) => {
		dispatch(setDetails(request));
		dispatch(requestAction());
		let filesListObj = new FormData();
		filesListObj.append("ProductId", request.productId);

		// If display picture chosed
		if (request.displayPicture) filesListObj.append("DiplayImage", request.displayPicture);

		// NEW IMAGES
		for (let index = 0; index < request.imagesArray.length; index++) {
			filesListObj.append("ProductImage", request.imagesArray[index].media);
		}
		// NEW VIDEOS
		// for (let index = 0; index < request.roomImageArray.length; index++) {
		//     filesListObj.append("RoomViewImage", request.roomImageArray[index].media);
		// }

		// If there is deletedFiles
		if (request.deletedFiles.length > 0)
			for (let index = 0; index < request.deletedFiles.length; index++) {
				filesListObj.append("DeletedFiles", request.deletedFiles[index]);
			}

		EditProductService.updateImagesAndVideos(filesListObj).then(
			(response) => {
				if (response && response.result === 200) {
					popToast.show("Successfully updated images!");
					dispatch(success());
					window.location.href = `/#/manage-artworks/${pageNum !== undefined ? pageNum : 1}`;
				} else {
					popToast.show("Something went wrong while updating images and videos! " + response.message || "");
				}
			},
			(error) => {
				console.log(error || "Something went wrong while updating images and videos!");
				dispatch(failure(error));
			}
		);
	};

	function setDetails(payload) {
		return { type: types.SET_IMAGE_DETAILS, payload };
	}
	function requestAction(payload) {
		return { type: types.UPDATE_PRODUCT_REQUEST, payload };
	}
	function success(payload) {
		return { type: types.UPDATE_PRODUCT_SUCCESS, payload };
	}
	function failure(error) {
		return { type: types.UPDATE_PRODUCT_FAILURE, error };
	}
}

// ============================= Download Display Picture ============================= //
export function onDownloadDisplayPicture(id, name = "rtistIQ_Product") {
	return (dispatch) => {
		dispatch(request());
		EditProductService.downloadDisplayPicture(id).then(
			(response) => {
				if (response) {
					const url = window.URL.createObjectURL(new Blob([response]));
					const link = document.createElement("a");
					link.href = url;
					link.download = `${name}.jpg`;
					// link.setAttribute("download", "file.pdf");
					document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
					link.click();
					link.remove(); //afterwards we remove the element again
					dispatch(success(response.data));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Image download error : " + response.Message || "Something went wrong!");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while downloading image!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.DOWNLOAD_DISPLAY_PICTURE_REQUEST };
	}
	function success(payload) {
		return { type: types.DOWNLOAD_DISPLAY_PICTURE_SUCCESS, payload };
	}
	function failure(error) {
		return { type: types.DOWNLOAD_DISPLAY_PICTURE_FAILURE, error };
	}
}

// ============================= Get Original Artworks ============================= //
export const getOriginalArtworksAction = () => {
	return (dispatch) => {
		dispatch(request());
		EditProductService.getOriginalArtworks().then(
			(response) => {
				if (response && response.result === 200) {
					dispatch(success(response.data));
				} else {
					dispatch(failure(response));
				}
			},
			(error) => {
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: types.GET_ORIGINAL_ARTWORKS_REQUEST };
	}
	function success(payload) {
		return { type: types.GET_ORIGINAL_ARTWORKS_SUCCESS, payload };
	}
	function failure(error) {
		return { type: types.GET_ORIGINAL_ARTWORKS_FAILURE, error };
	}
};

// ============================= Update Meta Data  ============================= //
export const updateMetaDataArtworkEditStatusAction = (status, id) => {
	return (dispatch) => {
		dispatch(request());
		EditProductService.metaDataArtworkStatusEdit(status, id).then(
			(response) => {
				if (response && response.result === 200) {
					dispatch(success(response.data));
				} else {
					dispatch(failure(response));
					popToast.show(response.message !== null ? response.message : "Something went wrong ");
				}
			},
			(error) => {
				dispatch(failure(error));
				popToast.show("Something went wrong");
			}
		);
	};

	function request() {
		return { type: types.META_DATA_ARTWORK_UPDATE };
	}
	function success(payload) {
		return { type: types.META_DATA_ARTWORK_UPDATE_SUCCESS, payload };
	}
	function failure(error) {
		return { type: types.META_DATA_ARTWORK_UPDATE_FAILURE, error };
	}
};

export const clearEditProductState = () => {
	return (dispatch) => {
		dispatch(clearStates());
	};

	function clearStates() {
		return { type: types.CLEAR_UPDATE_PRODUCT_REDUCER_STATES };
	}
};
