import React, { Component } from 'react'
import { Multiselect } from 'multiselect-react-dropdown';
import "./MultiSelectDropdown.scss";

export default class MultiSelectDropdown extends Component {
    render() {
        const style = {
            multiselectContainer: {
                width: "300px",
                marginLeft: "1rem",
            },
            searchBox: {
                border: "1px solid #e5e5e5",
                minHeight: "45px",
                borderRadius: "0px",
            },
            inputField: {
                border: "none"
            },
            chips: {
                background: "#58514B"
            },
        };
        const { data, showCheckbox = false, selectedCoutry, onSetCountryArray, onRemoveCountryArray, displayValue, placeholder = "Select", isObject = true } = this.props;
        return (
            <Multiselect
                options={data}
                showCheckbox={showCheckbox}
                selectedValues={selectedCoutry} // Preselected value to persist in dropdown
                onSelect={onSetCountryArray} // Function will trigger on select event
                onRemove={onRemoveCountryArray} // Function will trigger on remove event
                displayValue={displayValue} // Property name to display in the dropdown options
                style={style}
                placeholder={placeholder}
                isObject={isObject}
            />
        )
    }
}
