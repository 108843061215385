import {
	GET_USERSLIST_REQUEST,
	GET_USERSLIST_SUCCESS,
	GET_USERSLIST_FAILURE,
	ORDER_HISTORY_REQUEST,
	ORDER_HISTORY_SUCCESS,
	ORDER_HISTORY_FAILURE,
	BLOCK_USER_REQUEST,
	BLOCK_USER_SUCCESS,
	BLOCK_USER_FAILURE,
	GET_SHIPPING_ADDRESS_REQUEST,
	GET_SHIPPING_ADDRESS_SUCCESS,
	GET_SHIPPING_ADDRESS_FAILURE,
	UNBLOCK_USER_REQUEST,
	UNBLOCK_USER_SUCCESS,
	UNBLOCK_USER_FAILURE,
	USER_PROFILE_DETAILS,
	GET_USERS_DETAILS_REQUEST,
	GET_USERS_DETAILS_SUCCESS,
	GET_USERS_DETAILS_FAILURE,
	GET_ROLE_CHANGE_LIST_REQUEST,
	GET_ROLE_CHANGE_LIST_SUCCESS,
	GET_ROLE_CHANGE_LIST_FAILURE,
	UPDATE_ROLE_CHANGE_REQUEST,
	UPDATE_ROLE_CHANGE_SUCCESS,
	UPDATE_ROLE_CHANGE_FAILURE,
	GET_USER_KYC_DOC_REQUEST,
	GET_USER_KYC_DOC_SUCCESS,
	GET_USER_KYC_DOC_FAILURE,
	KYC_APPROVE_REQUEST,
	KYC_APPROVE_SUCCESS,
	KYC_APPROVE_FAILURE,
	GET_KYC_REQUEST,
	GET_KYC_SUCCESS,
	GET_KYC_FAILURE,
	TOGGLE_KYC_DIALOG,
} from "./ActionsTypes";
import { ManageUsersService } from "../../containers/ManageUsers/Manageusers.service";
import { popToast } from "../../components/Toast/Toast";

export function getUsersList(userdata) {
	return (dispatch) => {
		dispatch(request());
		ManageUsersService.getUsersList(userdata).then(
			(response) => {
				if (response && response.message === "Success") {
					dispatch(success(response.data));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Get users list Error : " + response.Message || "Something went wrong while loading users list!");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while loading users list!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_USERSLIST_REQUEST };
	}
	function success(data) {
		return { type: GET_USERSLIST_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_USERSLIST_FAILURE, error };
	}
}
export function getOrderHistory(orderdata) {
	return (dispatch) => {
		dispatch(request());
		ManageUsersService.getOrderHistory(orderdata).then(
			(response) => {
				if (response && response.message === "Success") {
					dispatch(success(response));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Get order history Error : " + response.Message || "Something went wrong while loading order history!");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while loading order history!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: ORDER_HISTORY_REQUEST };
	}
	function success(data) {
		return { type: ORDER_HISTORY_SUCCESS, data };
	}
	function failure(error) {
		return { type: ORDER_HISTORY_FAILURE, error };
	}
}
export function blockUser(userid, input) {
	return (dispatch) => {
		dispatch(request());
		ManageUsersService.blockUser(userid, input).then(
			(response) => {
				if (response && response.result === 200) {
					dispatch(success(response));
					popToast.show("User blocked successfully");
					dispatch(getUsersList(input));
				} else {
					dispatch(failure(response));
					popToast.show("Blocking user Error : " + response.message || "Something went wrong while blocking user!");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while blocking user !");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: BLOCK_USER_REQUEST };
	}
	function success(data) {
		return { type: BLOCK_USER_SUCCESS, data };
	}
	function failure(error) {
		return { type: BLOCK_USER_FAILURE, error };
	}
}
export function unblockUser(userid, input) {
	return (dispatch) => {
		dispatch(request());
		ManageUsersService.unblockUser(userid, input).then(
			(response) => {
				if (response && response.result === 200) {
					dispatch(success(response));
					popToast.show("User unblocked successfully");
					dispatch(getUsersList(input));
				} else {
					dispatch(failure(response));
					popToast.show("Unblocking user Error : " + response.message || "Something went wrong while unblocking user!");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while unblocking user !");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: UNBLOCK_USER_REQUEST };
	}
	function success(data) {
		return { type: UNBLOCK_USER_SUCCESS, data };
	}
	function failure(error) {
		return { type: UNBLOCK_USER_FAILURE, error };
	}
}
export function getUserDetails(userid) {
	return (dispatch) => {
		dispatch(request());
		ManageUsersService.getUserDetails(userid).then(
			(response) => {
				if (response && response.message === "Success") {
					dispatch(success(response));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Get users details Error : " + response.Message || "Something went wrong while loading users details!");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while loading users details!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_USERS_DETAILS_REQUEST };
	}
	function success(data) {
		return { type: GET_USERS_DETAILS_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_USERS_DETAILS_FAILURE, error };
	}
}

// <=========================Action function for getting user Shipping Address info=====>
export const getShipAddressAction = (userid) => {
	return (dispatch) => {
		dispatch(request());
		ManageUsersService.getShipAddress(userid).then(
			(response) => {
				if (response.result === 200) {
					dispatch(success(response));
				} else {
					dispatch(failure(response.message));
					popToast.show("Get shipping info Error : " + response.message || "Something went wrong while getting shipping info!");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong getting shipping info!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_SHIPPING_ADDRESS_REQUEST };
	}
	function success(data) {
		return { type: GET_SHIPPING_ADDRESS_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_SHIPPING_ADDRESS_FAILURE, error };
	}
};

//====================Role Change Requests List==============//
export function getRoleChangeRequestsAction(data) {
	return (dispatch) => {
		dispatch(request());
		ManageUsersService.getRoleChangeRequests(data).then(
			(response) => {
				if (response && response.message === "Success") {
					dispatch(success(response));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show(
						"Get role change list Error : " + response.Message || "Something went wrong while loading role change request lists!"
					);
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while loading role change request lists!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_ROLE_CHANGE_LIST_REQUEST };
	}
	function success(data) {
		return { type: GET_ROLE_CHANGE_LIST_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_ROLE_CHANGE_LIST_FAILURE, error };
	}
}

//==================== Update Role Change ==============//
export function updateRoleChangeAction(userId, status) {
	return (dispatch) => {
		dispatch(request());
		ManageUsersService.updateRoleChangeStatus(userId, status).then(
			(response) => {
				if (response && response.result === 200 && response.message === "Success") {
					dispatch(success(response));
					popToast.show("Updated sucessfully");
					dispatch(getRoleChangeRequestsAction());
				} else if (response) {
					dispatch(failure(response));
					popToast.show(response.message || "Something went wrong while updating role change request!");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while updating role change request!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: UPDATE_ROLE_CHANGE_REQUEST };
	}
	function success(data) {
		return { type: UPDATE_ROLE_CHANGE_SUCCESS, data };
	}
	function failure(error) {
		return { type: UPDATE_ROLE_CHANGE_FAILURE, error };
	}
}
export function updateRoleFromManageArtistChangeAction(userId, status, userRole) {
	return (dispatch) => {
		dispatch(request());
		ManageUsersService.updateRoleChangeStatusFromMangeArtist(userId, status, userRole).then(
			(response) => {
				if (response && response.result === 200 && response.message === "Success") {
					dispatch(success(response));
					popToast.show("Updated sucessfully");
					dispatch(getRoleChangeRequestsAction());
				} else if (response) {
					dispatch(failure(response));
					popToast.show(response.message || "Something went wrong while updating role change request!");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while updating role change request!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: UPDATE_ROLE_CHANGE_REQUEST };
	}
	function success(data) {
		return { type: UPDATE_ROLE_CHANGE_SUCCESS, data };
	}
	function failure(error) {
		return { type: UPDATE_ROLE_CHANGE_FAILURE, error };
	}
}
export function getUserKycUser(userid) {
	return (dispatch) => {
		dispatch(request());
		ManageUsersService.getUserKYC(userid).then(
			(response) => {
				if (response && response.result === 200 && response.message === "Success") {
					dispatch(success(response));
				} else if (response) {
					dispatch(failure(response));
					popToast.show(response.message);
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while fetching KYC !");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_USER_KYC_DOC_REQUEST };
	}
	function success(data) {
		return { type: GET_USER_KYC_DOC_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_USER_KYC_DOC_FAILURE, error };
	}
}

export function approveKYC(userid, isType) {
	return (dispatch) => {
		dispatch(request());
		ManageUsersService.approveKYC(userid, isType).then(
			(response) => {
				if (response && response.result === 200 && response.message === "Success") {
					popToast.show("KYC status updated successfully");
					dispatch(success(response));
				} else if (response) {
					dispatch(failure(response));
					popToast.show(response.message || "Something went wrong");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while blocking user !");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: KYC_APPROVE_REQUEST };
	}
	function success(data) {
		return { type: KYC_APPROVE_SUCCESS, data };
	}
	function failure(error) {
		return { type: KYC_APPROVE_FAILURE, error };
	}
}

export function adminDownloadKYCAction(userId, documentType, fileName) {
	return (dispatch) => {
		dispatch(request());
		ManageUsersService.adminDownloadKYC(userId, documentType).then(
			(response) => {
				if (response) {
					const url = window.URL.createObjectURL(new Blob([response]));
					const link = document.createElement("a");

					link.href = url;
					link.download = fileName;
					// link.setAttribute("download", "file.pdf");
					document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
					link.click();
					link.remove(); //afterwards we remove the element again
					dispatch(success(response.data));
				} else if (response && response.message === "Failure") {
					dispatch(failure(response));
					popToast.show("Get KYC Error : " + response.Message || "Something went wrong while downloading KYC!");
				}
			},
			(error) => {
				if (error && error.status_message) {
					popToast.show(error.status_message);
				} else {
					popToast.show("Something went wrong while downloading KYC!");
				}
				console.log("error dispatched for API error!", error);
				dispatch(failure(error));
			}
		);
	};

	function request() {
		return { type: GET_KYC_REQUEST };
	}
	function success(data) {
		return { type: GET_KYC_SUCCESS, data };
	}
	function failure(error) {
		return { type: GET_KYC_FAILURE, error };
	}
}

export const setUserProfile = (data) => ({
	type: USER_PROFILE_DETAILS,
	data,
});
// ============================= Toggle Add Shipping Charges Dialog ============================= //
export const toggleKYCDialog = (data) => ({
	type: TOGGLE_KYC_DIALOG,
	data,
});
