import React, { Component } from "react";
import { Steps, Select, Popover } from "antd";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
//Files
import Root from "../../Root/Root";
import Topbar from "../../../components/Topbar/Topbar";
import { getOrderDetailsAction, updateOrderStatusAction, invoiceDownloadAction, orderRefundAction, wayBillDownloadAction, getPickupDetailsAction } from "../../../redux/actions/OrdersAction";
//CSS File
import "./OrderStatus.scss";
import { popToast } from "../../../components/Toast/Toast";
const { Step } = Steps;
const { Option } = Select;

class OrderStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: null,
			note: "",
			amount: ""
		};
	}
	componentDidMount() {
		this.props.getOrderDetailsAction(this.props.match.params.id);
		this.props.getPickupDetailsAction(this.props.match.params.id);
	}
	render() {
		const { orderDetailsLoader, orderDetailsData, pickUpDataLoader, pickUpData } = this.props.ordersReducer;
		console.log('pickUpData', pickUpData)
		const listStatus = orderDetailsData ? orderDetailsData.orderCurrentStatus : 0;
		// const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
		const options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" };
		const orderhistory = orderDetailsData ? orderDetailsData.orderHistory : [];
		const cancelUpdateData =
			orderDetailsData && (orderDetailsData.orderCurrentStatus === 10 || orderDetailsData.orderCurrentStatus === 11 || orderDetailsData.orderCurrentStatus === 12)
				? orderhistory.filter(item => item.status === orderDetailsData.orderCurrentStatus)
				: [];
		const labelList = [
			{ label: "Payment Pending", value: 0 },
			{ label: "Pending Confirmation", value: 1 },
			{ label: "Order Confirmed", value: 2 },
			{ label: "Packed", value: 3 },
			{ label: "Collected For Shipping", value: 4 },
			{ label: "Shipped", value: 5 },
			{ label: "Delivered", value: 6 },
			{ label: "Return Requested", value: 7 },
			{ label: "Shipped Return", value: 8 },
			{ label: "Returned", value: 9 },
			{ label: "Cancelled", value: 10 },
			{ label: "Payment Failed", value: 11 },
			{ label: "Pending With Artist", value: 12 },
			{ label: "Shipment On Hold", value: 13 }
		];
		const customDot = (dot, { status, index, title, description }) => {
			if (index <= listStatus) {
				return (
					<Popover
						content={
							<span style={{ display: "flex", flexDirection: "column" }}>
								<p style={{ fontSize: "12px", color: "#000000", marginBottom: "5px" }}>{title}</p>
								<p style={{ fontSize: "12px", color: "#000000", marginBottom: "5px" }}>{description}</p>
								<p style={{ fontSize: "12px", color: "#000000", marginBottom: "5px" }}>
									{orderhistory && orderhistory[index] ? orderhistory[index].updateText : null}
								</p>
							</span>
						}
					>
						{dot}
					</Popover>
				);
			} else {
				return <span>{dot}</span>;
			}
		};
		return (
			<Root>
				<Topbar title={"Order Status"} showBreadCrumbs showHead />
				{orderDetailsData ? (
					<div className="order-status-wrapper">
						<div className="head-wrapper">
							<div className="detail-wrapper">Order</div>
							<div className="form-grid">
								<div className="detail-wrapper">Order Status</div>
								<div className="message-wrapper">Messages of status</div>
							</div>
						</div>
						<div className="content-wrapper">
							<div className="colum-grid">
								<div className="list-wrapper">
									<div className="sub-wrapper">
										<ul>
											<li>Ord. Date:</li>
											<li>Order Id:</li>
											<li>Total Amount:</li>
											{orderDetailsData && orderDetailsData.shipmentProvider &&
												<li>Shipment Provider:</li>
											}
											{orderDetailsData && orderDetailsData.grossWeight > 0 &&
												<li>Gross Weight:</li>
											}
											{orderDetailsData && orderDetailsData.shipmentPickupTimeStamp &&
												<li>Shipment Pickup Time:</li>
											}
											{orderDetailsData && orderDetailsData.expectedDeliveryDate &&
												<li>Expected Delivery Date:</li>
											}
											{orderDetailsData && orderDetailsData.returnPickupTimeStamp &&
												<li>Return Pickup Time:</li>
											}

										</ul>
										<ul className="detail-values">
											<li>{orderDetailsData && orderDetailsData.orderDate ? new Date(orderDetailsData.orderDate).toLocaleString("en-IN", options) : "Nil"}</li>
											<li>{orderDetailsData && orderDetailsData.orderId ? orderDetailsData.orderId : "Nil"}</li>
											<li>{orderDetailsData && orderDetailsData.currency}&nbsp;{orderDetailsData ? orderDetailsData.amount.toLocaleString("en-IN") : 0}</li>
											<li>{orderDetailsData && orderDetailsData.shipmentProvider}</li>
											<li>{orderDetailsData && orderDetailsData.grossWeight > 0 && `${orderDetailsData.grossWeight} kg`}</li>
											<li>{orderDetailsData && orderDetailsData.shipmentPickupTimeStamp && new Date(orderDetailsData.shipmentPickupTimeStamp).toLocaleString("en-IN", options)}</li>
											<li>{orderDetailsData && orderDetailsData.expectedDeliveryDate && new Date(orderDetailsData.expectedDeliveryDate).toLocaleString("en-IN", options)}</li>
											<li>{orderDetailsData && orderDetailsData.returnPickupTimeStamp && new Date(orderDetailsData.returnPickupTimeStamp).toLocaleString("en-IN", options)}</li>
										</ul>
									</div>
									<div className="sub-wrapper">
										<ul>
											<li className="greylist">Product</li>
										</ul>
										<ul className="detail-values">
											<li>
												<img
													src={
														orderDetailsData
															? orderDetailsData.productImage
															: require("../../../assets/images/common/No_Image.png")
													}
													alt="ProductImage"
												/>
											</li>
											<li>
												<h2>{orderDetailsData ? orderDetailsData.productName : "Nil"}</h2>
											</li>
											<li>
												<p className="description">{orderDetailsData ? orderDetailsData.oneLineDescription : "Nil"}</p>
											</li>
										</ul>
									</div>
									<div className="sub-wrapper">
										<ul>
											<li className="greylist">Buyer</li>
											<li className="greylist">Shipping Address</li>
										</ul>
										<ul className="detail-values">
											<li>
												<p className="artist-name">
													{orderDetailsData && orderDetailsData.shippingAddress
														? orderDetailsData.shippingAddress.name
														: "Nil"}
												</p>
											</li>
											<li>
												<div className="addressform">
													<p className="nameText">
														{orderDetailsData && orderDetailsData.shippingAddress
															? orderDetailsData.shippingAddress.name
															: "Nil"}
													</p>
													<p className="addressData">
														{orderDetailsData && orderDetailsData.shippingAddress
															? orderDetailsData.shippingAddress.addressLine1
															: "Nil"}
														&#44;
													</p>
													<p className="addressData">
														{orderDetailsData && orderDetailsData.shippingAddress
															? orderDetailsData.shippingAddress.addressLine2
															: "Nil"}
														&#44;
													</p>
													<p className="addressData">
														{orderDetailsData && orderDetailsData.shippingAddress
															? orderDetailsData.shippingAddress.city
															: "Nil"}
														&#44;
														{orderDetailsData && orderDetailsData.shippingAddress
															? orderDetailsData.shippingAddress.state
															: "Nil"}
														&#46;
													</p>
													<p className="addressData">
														{"Pin"}&nbsp;
														{orderDetailsData && orderDetailsData.shippingAddress
															? orderDetailsData.shippingAddress.postalCode
															: "Nil"}
													</p>
													<p className="addressData">
														{orderDetailsData && orderDetailsData.shippingAddress
															? orderDetailsData.shippingAddress.country
															: "Nil"}
													</p>
												</div>
											</li>
										</ul>
									</div>
									<div className="sub-wrapper">
										<ul>
											<li className="greylist">Seller Address</li>
										</ul>
										<ul className="detail-values">
											<li>
												<div className="addressform">
													<p className="nameText">
														{orderDetailsData && orderDetailsData.pickupAddress
															? orderDetailsData.pickupAddress.name
															: "Nil"}
													</p>
													<p className="addressData">
														{orderDetailsData && orderDetailsData.pickupAddress
															? orderDetailsData.pickupAddress.addressLine1
															: "Nil"}
														&#44;
													</p>
													<p className="addressData">
														{orderDetailsData && orderDetailsData.pickupAddress
															? orderDetailsData.pickupAddress.addressLine2
															: "Nil"}
														&#44;
													</p>
													<p className="addressData">
														{orderDetailsData && orderDetailsData.pickupAddress
															? orderDetailsData.pickupAddress.city
															: "Nil"}
														&#44;
														{orderDetailsData && orderDetailsData.pickupAddress
															? orderDetailsData.pickupAddress.state
															: "Nil"}
														&#46;
													</p>
													<p className="addressData">
														{"Pin"}&nbsp;
														{orderDetailsData && orderDetailsData.pickupAddress
															? orderDetailsData.pickupAddress.postalCode
															: "Nil"}
													</p>
													<p className="addressData">
														{orderDetailsData && orderDetailsData.pickupAddress
															? orderDetailsData.pickupAddress.country
															: "Nil"}
													</p>
												</div>
											</li>
										</ul>
									</div>
									<div className="end-wrapper">
										{orderDetailsData && orderDetailsData.orderCurrentStatus !== 0 && orderDetailsData.orderCurrentStatus !== 10 && orderDetailsData.orderCurrentStatus !== 11 && (
											<div className="first-imagebutton" onClick={this.onInvoice.bind(this)}>
												<img
													className="first-iconimage"
													src={require("../../../assets/images/common/Invoice.svg")}
													height={"18px"}
													width={"18px"}
													alt="invoice"
												/>
												<p className="title">Invoice</p>
											</div>
										)}
										{orderDetailsData && ((orderDetailsData.orderCurrentStatus >= 3 && orderDetailsData.orderCurrentStatus <= 9) || orderDetailsData.orderCurrentStatus === 13) && (
											<div className="second-imagebutton" onClick={this.onWayBill.bind(this)}>
												<img
													className="second-iconimage"
													src={require("../../../assets/images/common/Delivery.svg")}
													height={"25px"}
													width={"25px"}
													alt="delivery"
												/>
												<p className="title">Way Bill</p>
											</div>
										)}
										{/* <div className="second-imagebutton">
											<img
												className="second-iconimage"
												src={require("../../../assets/images/common/Delivery.svg")}
												height={"25px"}
												width={"25px"}
												alt="delivery"
											/>
											<p className="title">Delivery slip</p>
										</div> */}
									</div>
								</div>
							</div>
							<div className="colum-grid-new">
								<div className="track-wrapper">
									<div className="tracking">
										{orderDetailsData && orderDetailsData.orderCurrentStatus <= 9 ? (
											<Steps
												direction="vertical"
												progressDot={customDot}
												current={orderDetailsData ? orderDetailsData.orderCurrentStatus : 0}
											>
												<Step
													title="Payment Pending"
													description={
														orderhistory && orderhistory[0]
															? new Date(orderhistory[0].updateDate).toLocaleString("en-IN", options)
															: ""
													}
												/>
												<Step
													title="Pending Confirmation "
													description={
														orderhistory && orderhistory[1]
															? new Date(orderhistory[1].updateDate).toLocaleString("en-IN", options)
															: ""
													}
												/>
												<Step
													title="Order Confirmed"
													description={
														orderhistory && orderhistory[2]
															? new Date(orderhistory[2].updateDate).toLocaleString("en-IN", options)
															: ""
													}
												/>
												<Step
													title="Packed"
													description={
														orderhistory && orderhistory[3]
															? new Date(orderhistory[3].updateDate).toLocaleString("en-IN", options)
															: ""
													}
												/>
												<Step
													title="Collected For Shipping"
													description={
														orderhistory && orderhistory[4]
															? new Date(orderhistory[4].updateDate).toLocaleString("en-IN", options)
															: ""
													}
												/>
												<Step
													title="Shipped"
													description={
														orderhistory && orderhistory[5]
															? new Date(orderhistory[5].updateDate).toLocaleString("en-IN", options)
															: ""
													}
												/>
												<Step
													title="Delivered"
													description={
														orderhistory && orderhistory[6]
															? new Date(orderhistory[6].updateDate).toLocaleString("en-IN", options)
															: ""
													}
												/>
												{orderDetailsData &&
													(orderDetailsData.orderCurrentStatus === 7 || orderDetailsData.orderCurrentStatus === 8 || orderDetailsData.orderCurrentStatus === 9) && (
														<Step
															title="Return Requested"
															description={
																orderhistory && orderhistory[7]
																	? new Date(orderhistory[7].updateDate).toLocaleString(
																		"en-IN",
																		options
																	)
																	: ""
															}
														/>
													)}
												{orderDetailsData &&
													(orderDetailsData.orderCurrentStatus === 8 || orderDetailsData.orderCurrentStatus === 9) && (
														<Step
															title="Shipped Return"
															description={
																orderhistory && orderhistory[8]
																	? new Date(orderhistory[8].updateDate).toLocaleString(
																		"en-IN",
																		options
																	)
																	: ""
															}
														/>
													)}
												{orderDetailsData && orderDetailsData.orderCurrentStatus === 9 && (
													<Step
														title="Returned"
														description={
															orderhistory && orderhistory[9]
																? new Date(orderhistory[9].updateDate).toLocaleString(
																	"en-IN",
																	options
																)
																: ""
														}
													/>
												)}
											</Steps>
										) : (
											<Steps
												direction="vertical"
												progressDot={customDot}
												current={orderDetailsData ? orderDetailsData.orderCurrentStatus : 0}
											>
												{orderDetailsData &&
													orderDetailsData.orderHistory &&
													orderDetailsData.orderHistory.length !== 0 &&
													orderDetailsData.orderHistory.map((item, index) => {
														if (item.status === 0) {
															return (
																<Step
																	key={index}
																	title="Payment Pending"
																	description={
																		item.updateDate
																			? new Date(item.updateDate).toLocaleDateString("en-US", options)
																			: ""
																	}
																/>
															);
														} else if (item.status === 1) {
															return (
																<Step
																	key={index}
																	title="Pending Confirmation"
																	description={
																		item.updateDate
																			? new Date(item.updateDate).toLocaleDateString("en-US", options)
																			: ""
																	}
																/>
															);
														} else if (item.status === 2) {
															return (
																<Step
																	key={index}
																	title="Order Confirmed"
																	description={
																		item.updateDate
																			? new Date(item.updateDate).toLocaleDateString("en-US", options)
																			: ""
																	}
																/>
															);
														} else if (item.status === 3) {
															return (
																<Step
																	key={index}
																	title="Packed"
																	description={
																		item.updateDate
																			? new Date(item.updateDate).toLocaleDateString("en-US", options)
																			: ""
																	}
																/>
															);
														} else if (item.status === 4) {
															return (
																<Step
																	key={index}
																	title="Collected For Shipping"
																	description={
																		item.updateDate
																			? new Date(item.updateDate).toLocaleDateString("en-US", options)
																			: ""
																	}
																/>
															);
														} else if (item.status === 5) {
															return (
																<Step
																	key={index}
																	title="Shipped"
																	description={
																		item.updateDate ? new Date(item.updateDate).toLocaleDateString("en-US", options) : ""
																	}
																/>
															);
														} else if (item.status === 6) {
															return (
																<Step
																	key={index}
																	title="Delivered"
																	description={
																		item.updateDate ? new Date(item.updateDate).toLocaleDateString("en-US", options) : ""
																	}
																/>
															);
														} else if (item.status === 7) {
															return (
																<Step
																	key={index}
																	title="Return Requested"
																	description={
																		item.updateDate ? new Date(item.updateDate).toLocaleDateString("en-US", options) : ""
																	}
																/>
															);
														} else if (item.status === 8) {
															return (
																<Step
																	key={index}
																	title="Shipped Return"
																	description={
																		item.updateDate ? new Date(item.updateDate).toLocaleDateString("en-US", options) : ""
																	}
																/>
															);
														} else if (item.status === 9) {
															return (
																<Step
																	key={index}
																	title="Returned"
																	description={
																		item.updateDate ? new Date(item.updateDate).toLocaleDateString("en-US", options) : ""
																	}
																/>
															);
														} else if (item.status === 10) {
															return (
																<Step
																	key={index}
																	title="Cancelled"
																	description={
																		item.updateDate
																			? new Date(item.updateDate).toLocaleDateString("en-US", options)
																			: ""
																	}
																/>
															);
														} else if (item.status === 11) {
															return (
																<Step
																	key={index}
																	title="Payment Failed"
																	description={
																		item.updateDate
																			? new Date(item.updateDate).toLocaleDateString("en-US", options)
																			: ""
																	}
																/>
															);
														} else if (item.status === 12) {
															return (
																<Step
																	key={index}
																	title="Pending With Artist"
																	description={
																		item.updateDate
																			? new Date(item.updateDate).toLocaleDateString("en-US", options)
																			: ""
																	}
																/>
															);
														} else if (item.status === 13) {
															return (
																<Step
																	key={index}
																	title="Shipment On Hold"
																	description={
																		item.updateDate ? new Date(item.updateDate).toLocaleDateString("en-US", options) : ""
																	}
																/>
															);
														}
													})}
											</Steps>
										)}
									</div>
									<div className="track-details">
										{labelList.map((item, index) => {
											if (item.value === listStatus)
												return (
													<p key={index} className="status-head">
														{item.label}
													</p>
												);
										})}
										{listStatus === 10 || listStatus === 11 || listStatus === 12 ? (
											<React.Fragment>
												<p className="status-date">
													{cancelUpdateData && cancelUpdateData[0]
														? new Date(cancelUpdateData[0].updateDate).toLocaleDateString("en-IN", options)
														: "Nil"}
												</p>
												<p className="status-data">
													{cancelUpdateData && cancelUpdateData[0] && cancelUpdateData[0].updateText
														? cancelUpdateData[0].updateText
														: "Nil"}
												</p>
											</React.Fragment>
										) : (
											<React.Fragment>
												<p className="status-date">
													{orderhistory && orderhistory[listStatus]
														? new Date(orderhistory[listStatus].updateDate).toLocaleDateString(
															"en-IN",
															options
														)
														: "Nil"}
												</p>
												<p className="status-data">
													{orderhistory && orderhistory[listStatus]
														? orderhistory[listStatus].updateText
														: "Nil"}
												</p>
											</React.Fragment>
										)}
									</div>
								</div>
								{orderDetailsData && orderDetailsData.isShipmentCreated &&
									<div className="pickup-wrapper">
										<h3>Pick Up Details</h3>
										<div className="sub-wrapper">
											<ul>
												{pickUpData && pickUpData.grossWeight &&
													<li>Gross Weight(in Kg):</li>
												}
												{pickUpData && pickUpData.dimensions && pickUpData.dimensions.height &&
													<li>Height:</li>
												}
												{pickUpData && pickUpData.dimensions && pickUpData.dimensions.width &&
													<li>Width:</li>
												}
												{pickUpData && pickUpData.dimensions && pickUpData.dimensions.depth &&
													<li>Depth:</li>
												}
												{pickUpData && pickUpData.pickupLocation &&
													<li>Pick Up Location:</li>
												}
												{pickUpData && pickUpData.shipmentPickupTime &&
													<li>Pick Up Date:</li>
												}
												{pickUpData && pickUpData.pickupLocationCloseTime &&
													<li>Pick Up Close Time:</li>
												}
												{pickUpData && pickUpData.awbNumber &&
													<li>AWB No.:</li>
												}
												{pickUpData && pickUpData.pickupReferenceNumber &&
													<li>Pick Up Reference No.:</li>
												}
											</ul>
											<ul className="detail-values">
												{pickUpData && pickUpData.grossWeight && <li>{pickUpData.grossWeight}</li>}
												{pickUpData && pickUpData.dimensions && pickUpData.dimensions.height && <li>{pickUpData.dimensions.height}</li>}
												{pickUpData && pickUpData.dimensions && pickUpData.dimensions.width && <li>{pickUpData.dimensions.width}</li>}
												{pickUpData && pickUpData.dimensions && pickUpData.dimensions.depth && <li>{pickUpData.dimensions.depth}</li>}
												{pickUpData && pickUpData.pickupLocation && <li>{pickUpData.pickupLocation}</li>}
												{pickUpData && pickUpData.shipmentPickupTime && <li>{new Date(pickUpData.shipmentPickupTime).toLocaleString("en-IN", options)}</li>}
												{pickUpData && pickUpData.pickupLocationCloseTime && <li>{pickUpData.pickupLocationCloseTime}</li>}
												{pickUpData && pickUpData.awbNumber && <li>{pickUpData.awbNumber}</li>}
												{pickUpData && pickUpData.pickupReferenceNumber && <li>{pickUpData.pickupReferenceNumber}</li>}
											</ul>
										</div>
									</div>
								}
								{orderDetailsData && orderDetailsData.orderCurrentStatus >= 3 &&
									orderDetailsData.orderCurrentStatus < 9 &&
									orderDetailsData.orderCurrentStatus !== 6 &&
									(
										<div className="status-wrapper">
											<div className="form-status">
												<p className="selection-title">Change Status</p>
												<Select
													mode="default"
													style={{ width: 200 }}
													placeholder="Select Status"
													value={this.state.status}
													onChange={this.handleChange("status")}
												>
													{orderDetailsData
														? labelList.filter(item => item.value === orderDetailsData.orderCurrentStatus + 1).map(
															(item, index) => (
																<Option key={index} value={item.value}>
																	{item.label}
																</Option>
															)
														)
														: null}
												</Select>
											</div>
											<div className="note-status">
												<p className="selection-title">Add Note</p>
												<input
													className="noteText"
													value={this.state.note}
													type="text"
													onChange={this.handleChange("note")}
												/>
											</div>
											<button className="track-btn" onClick={this.updateStatus.bind(this)}>
												UPDATE
											</button>
										</div>
									)}
								{orderDetailsData &&
									(orderDetailsData.orderCurrentStatus === 9 || orderDetailsData.orderCurrentStatus === 10) &&
									!orderDetailsData.isAmountRefunded && (
										<div className="status-wrapper">
											<div className="note-status">
												<p className="selection-title">Refund Amount</p>
												<input
													className="noteText"
													value={this.state.amount}
													type="number"
													onChange={this.handleChange("amount")}
												/>
											</div>
											<button className="track-btn" onClick={this.orderRefund.bind(this)}>
												REFUND
											</button>
										</div>
									)}
							</div>
						</div>
					</div>
				) : orderDetailsLoader ? (
					<div className="order-status-wrapper">
						<CircularProgress className="circular-loader" size={30} />
					</div>
				) : (
					<div className="order-status-wrapper">
						<div className="empty-table">No Orders Found.</div>
					</div>
				)}
			</Root>
		);
	}

	handleChange = name => event => {
		if (name === "status") {
			this.setState({ status: event });
		} else if (name === "note") {
			this.setState({ note: event.target.value });
		} else if (name === "amount") {
			this.setState({ amount: event.target.value });
		}
	};

	//------------ Order Payment Refund --------------//
	orderRefund(e) {
		e.preventDefault();
		if (!this.state.amount) popToast.show("Please Enter Amount");
		else if (this.state.amount > this.props.ordersReducer.orderDetailsData.amount) {
			popToast.show("Maxium refund amount is price of the artwork");
		}
		else {
			this.props.orderRefundAction(this.state.amount, this.props.match.params.id);
			this.handleReset();
		}
	}

	//------------ Order Status Update --------------//
	updateStatus(e) {
		e.preventDefault();
		const { orderCurrentStatus } = this.props.ordersReducer.orderDetailsData;
		if (!this.state.status) popToast.show("Please Select Status");
		else if (!this.state.note) popToast.show("Please Add Note");
		else {
			if (this.state.status < 10) {
				if (this.state.status === orderCurrentStatus + 1) {
					let request = {
						status: +this.state.status,
						note: this.state.note,
						orderDetailsId: this.props.match.params.id
					};
					this.props.updateOrderStatusAction(request);
					this.handleReset();
				} else {
					popToast.show("Update to this status is not possible");
				}
			} else {
				if (orderCurrentStatus <= 4) {
					let request = {
						status: +this.state.status,
						note: this.state.note,
						orderDetailsId: this.props.match.params.id
					};
					this.props.updateOrderStatusAction(request);
					this.handleReset();
				} else {
					popToast.show("Update to this status is not possible");
				}
			}
		}
	}

	//------------ Order Invoice Download --------------//
	onInvoice() {
		const { orderDetailsData } = this.props.ordersReducer;
		this.props.invoiceDownloadAction(orderDetailsData._id);
	}

	//---------------- Order Way Bill Download ------------//
	onWayBill() {
		const { orderDetailsData } = this.props.ordersReducer;
		this.props.wayBillDownloadAction(orderDetailsData._id);
	}

	handleReset() {
		this.setState({
			...this.state,
			status: null,
			note: "",
			amount: ""
		});
	}
}
const mapStateToProps = state => {
	return {
		ordersReducer: state.ordersReducer
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getOrderDetailsAction: orderDetailsId => {
			dispatch(getOrderDetailsAction(orderDetailsId));
		},
		updateOrderStatusAction: updateData => {
			dispatch(updateOrderStatusAction(updateData));
		},
		invoiceDownloadAction: orderId => {
			dispatch(invoiceDownloadAction(orderId));
		},
		orderRefundAction: (amount, orderDetailsId) => {
			dispatch(orderRefundAction(amount, orderDetailsId));
		},
		wayBillDownloadAction: orderDetailsId => {
			dispatch(wayBillDownloadAction(orderDetailsId));
		},
		getPickupDetailsAction: orderId => {
			dispatch(getPickupDetailsAction(orderId));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderStatus);
