import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent, CircularProgress } from "@material-ui/core";
// Components
import { popToast } from "../../../components/Toast/Toast";
import { Util } from "../../../util/util";
// Redux
import { connect } from "react-redux";
import { createHomeBannerAction, updateHomeBannerAction } from "../../../redux/actions/ManageHomeAction.js";
// SCSS
import "./AddSliderDialog.scss";

class AddSliderDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			webMedia: "",
			webMediaThumb: "",
			mobMedia: "",
			mobMediaThumb: "",
			caption: "",
			description: "",
			readMore: "",
			explore: "",
			buttonText: "",
			imageCredit: "",

		};
	}
	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.open && this.props.open) {
			const updateData = this.props.updateData;
			this.setState({
				webMedia: (updateData && updateData.webBannerImage) || "",
				webMediaThumb: (updateData && updateData.webBannerImage) || "",
				mobMedia: (updateData && updateData.mobileBannerImage) || "",
				mobMediaThumb: (updateData && updateData.mobileBannerImage) || "",
				caption: (updateData && updateData.caption) || "",
				description: (updateData && updateData.description) || "",
				readMore: (updateData && updateData.readMore) || "",
				explore: (updateData && updateData.explore) || "",
				buttonText: (updateData && updateData.buttonText) || "",
				imageCredit: (updateData && updateData.source) || "",


			});
		}
	}
	render() {
		const { createBannerLoader, updateBannerLoader } = this.props.manageHomeReducer;
		return (
			<Dialog
				open={this.props.open}
				onClose={this.props.handleClose}
				BackdropProps={{ classes: { root: "dialog-backdrop" } }}
				className="add-slider-dialog"
			>
				<img className="close-btn" onClick={this.props.handleClose} src={require("../../../assets/images/common/Close_h.svg")} alt="" />
				<DialogTitle className="dialog-title">Add Slider</DialogTitle>
				<DialogContent className="add-slider-content">
					<div className="add-slider-body">
						{/* ---------------------- Slider Details ---------------------- */}
						<div className="add-slider-body-top">
							<div className="add-slider-image-box">
								<div className="form-control-image-box mr-custom">
									{this.state.mobMediaThumb ? (
										<label className="upload-box bg-white mobile-upload-box" onClick={e => this.attach_mob_media.click()}>
											<img className="media-thumb" src={this.state.mobMediaThumb} alt="Attach media" />
										</label>
									) : (
										<div className="upload-box mobile-upload-box" onClick={e => this.attach_mob_media.click()}>
											<img className="image-upload" src={require("../../../assets/images/common/Icon.svg")} alt="Upload" />
											<label>
												Upload Image <br /> Mobile App
											</label>
										</div>
									)}
									<input
										type="file"
										style={{ display: "none" }}
										accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
										onChange={e => this.onImageSelect(e, "mobMedia", "mobMediaThumb")}
										disabled={createBannerLoader || updateBannerLoader}
										ref={ref => (this.attach_mob_media = ref)}
									/>
								</div>

								<div className="form-control-image-box">
									{this.state.webMediaThumb ? (
										<label className="upload-box bg-white" onClick={e => this.attach_web_media.click()}>
											<img className="media-thumb" src={this.state.webMediaThumb} alt="Attach media" />
										</label>
									) : (
										<div className="upload-box" onClick={e => this.attach_web_media.click()}>
											<img className="image-upload" src={require("../../../assets/images/common/Icon.svg")} alt="Upload" />
											<label>
												Upload Image <br /> Website
											</label>
										</div>
									)}
									<input
										type="file"
										style={{ display: "none" }}
										accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
										onChange={e => this.onImageSelect(e, "webMedia", "webMediaThumb")}
										disabled={createBannerLoader || updateBannerLoader}
										ref={ref => (this.attach_web_media = ref)}
									/>
								</div>
							</div>
							<div className="form-control">
								<label className="form-label">Banner caption (If any)</label>
								<input
									type="text"
									className="form-input"
									placeholder="Banner caption (If any)"
									value={this.state.caption}
									onChange={this.handleChange("caption")}
								/>
							</div>
							<div className="form-control">
								<label className="form-label">Banner description (If any)</label>
								<input
									type="text"
									className="form-input"
									placeholder="Banner description (If any)"
									value={this.state.description}
									onChange={this.handleChange("description")}
								/>
							</div>
							<div className="form-control">
								<label className="form-label">CTA Button Text</label>
								<input
									type="text"
									className="form-input"
									placeholder="CTA button text"
									value={this.state.buttonText}
									onChange={this.handleChange("buttonText")}
								/>
							</div>
							<div className="form-control">
								<label className="form-label">CTA button link</label>
								<input
									type="text"
									className="form-input"
									placeholder="Explore link (If any)"
									value={this.state.explore}
									onChange={this.handleChange("explore")}
								/>
							</div>
							<div className="form-control">
								<label className="form-label">Image Credit</label>
								<input
									type="text"
									className="form-input"
									placeholder="Enter the image credit text"
									value={this.state.imageCredit}
									onChange={this.handleChange("imageCredit")}
								/>
							</div>
						</div>
						<div className="add-slider-action">
							<button className="btn-cancel" onClick={this.onClickCancel.bind(this)}>
								Cancel
							</button>
							<button className="btn-orange" onClick={this.onClickCreate.bind(this)} disabled={createBannerLoader || updateBannerLoader}>
								{this.props.updateData ? "Update" : "Save"}
								{(createBannerLoader || updateBannerLoader) && <CircularProgress size={22} className="circular-loader modal-button-loader" />}
							</button>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		);
	}

	// On change
	handleChange = name => event => this.setState({ [name]: event.target.value });

	// On select image
	onImageSelect(e, media, mediaThumb) {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		let image = "";
		let img = new Image();
		img.src = window.URL.createObjectURL(e.target.files[0]);
		img.onload = () => {
			if ((img.width >= 1024 || img.height >= 768) && (img.width <= 3840 || img.height <= 2160)) {
				if (file) {
					reader.onloadend = () => {
						this.setState({
							[media]: file,
							[mediaThumb]: reader.result
						});
						image = reader.result;
					};
					reader.readAsDataURL(file);
				} else {
					this.setState({
						[media]: "",
						[mediaThumb]: ""
					});
				}
			} else if (img.width < 1024 || img.height < 768) {
				popToast.show("Minimum image Width is 1024 and height is 768");
				this.setState({
					[media]: "",
					[mediaThumb]: ""
				});
			} else if (img.width > 3840 || img.height > 2160) {
				popToast.show("Maximum image Width is 3840 and height is 2160");
				this.setState({
					[media]: "",
					[mediaThumb]: ""
				});
			}
		};
		// if (file) {
		// 	reader.onloadend = () => {
		// 		this.setState({
		// 			[media]: file,
		// 			[mediaThumb]: reader.result
		// 		});
		// 		image = reader.result;
		// 	};
		// 	reader.readAsDataURL(file);
		// } else {
		// 	this.setState({
		// 		[media]: "",
		// 		[mediaThumb]: ""
		// 	});
		// }
	}

	// Form validation
	isFormValid() {
		if (!this.state.mobMedia) {
			popToast.show("Choose an image for banner");
			return false;
		}
		if (this.state.mobMedia.type && !this.state.mobMedia.type.startsWith("image/")) {
			popToast.show("Please choose a valid image file");
			return false;
		}
		if (!this.state.webMedia) {
			popToast.show("Choose an image for banner");
			return false;
		}
		if (this.state.webMedia.type && !this.state.webMedia.type.startsWith("image/")) {
			popToast.show("Please choose a valid image file");
			return false;
		}
		return true;
	}

	// Create Slider
	onClickCreate(e) {
		e.preventDefault();

		if (this.isFormValid()) {
			let request = {
				WebImage: this.state.webMedia,
				MobileImage: this.state.mobMedia,
				Description: this.state.description,
				Caption: this.state.caption,
				ReadMore: this.state.readMore,
				Explore: this.state.explore,
				ButtonText: this.state.buttonText,
				Source: this.state.imageCredit,
			};
			let formBodyRequest = Util.objectToFormData(request);
			this.props.updateData ? this.props.updateHomeBannerAction(this.props.updateData._id, formBodyRequest) : this.props.createHomeBannerAction(formBodyRequest);
		}
	}

	onClickCancel() {
		this.props.handleClose();
	}
}

const mapStateToProps = state => {
	return {
		manageHomeReducer: state.manageHomeReducer
	};
};

const mapDispatchToProps = dispatch => {
	return {
		createHomeBannerAction: bannerData => {
			dispatch(createHomeBannerAction(bannerData));
		},
		updateHomeBannerAction: (bannerId, bannerData) => {
			dispatch(updateHomeBannerAction(bannerId, bannerData));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddSliderDialog);
