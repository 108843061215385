import React, { useState } from "react";
import { Popover, Badge, Icon, Checkbox } from "antd";
import { includes, intersection } from "lodash";
import "./FilterDropdown.scss";

const FilterDropdown = ({
	filterFor,
	idToFetch = "_id",
	nameToDisplay,
	selectedList = [],
	checkboxList = [],
	returnBackQueryArray,
	returnBackCheckEvent,
	multiSelection = true,
	isCustomCheckboxFn = false
}) => {
	const [visible, setPopoverVisibility] = useState(false);

	const multiSelectionCheckboxes = (event, itemId) => {
		let list = multiSelection ? selectedList : [];
		if (event.target.checked) {
			list = [...list, itemId];
		} else {
			list = list.filter(item => item !== itemId);
		}
		selectedList = list;
		returnBackQueryArray(list);
	};

	const popoverContent = (
		<div className="filter-dropdown-body">
			{checkboxList &&
				checkboxList.map((item, index) => (
					<div className="filter-dropdown-form-control" key={index}>
						<Checkbox
							className="filter-dropdown-form-checkbox"
							checked={includes(selectedList, item[idToFetch])}
							onChange={e => (isCustomCheckboxFn ? returnBackCheckEvent(e, item) : multiSelectionCheckboxes(e, item[idToFetch]))}
						>
							{item[nameToDisplay] || "Anonymous"}
						</Checkbox>
					</div>
				))}
		</div>
	);

	return (
		<Popover
			content={popoverContent}
			trigger="click"
			placement="bottom"
			visible={visible}
			overlayClassName="filter-dropdown-overlay"
			onVisibleChange={value => setPopoverVisibility(value)}
		>
			<button className="psuedo-dropdown">
				{filterFor || "Filter"}
				<Badge
					className="psuedo-dropdown-badge"
					count={
						isCustomCheckboxFn
							? // Intersection is for only filters dropdwon
							// since selectedList of filters might contain the values of other filter dropdowns too
							intersection(
								checkboxList.map(item => item.filterId),
								selectedList
							).length
							: selectedList && selectedList.length
					}
					overflowCount={10}
				/>
				<Icon className="psuedo-dropdown-icon" type="down" />
			</button>
		</Popover>
	);
};

export default FilterDropdown;
