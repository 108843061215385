import { APIUrlConstants } from "../../util/apiUrl.constant";
import { APIService } from "../../util/api.service";

export class OrderService {
	static getOrderList(orderdata) {
		if (orderdata.filterValue && orderdata.shipmentProvider) {
			let url =
				APIUrlConstants.getApiUrl("getOrderList") +
				"?PageNum=" +
				orderdata.pageNum +
				"&PageSize=" +
				orderdata.pageSize +
				"&Status=" +
				orderdata.filterValue +
				"&ShipmentProvider=" + orderdata.shipmentProvider;
			return APIService.doGet(url).then(resp => {
				if (resp) {
					return resp;
				}
				throw resp;
			});
		}
		else if (orderdata.shipmentProvider) {
			let url =
				APIUrlConstants.getApiUrl("getOrderList") +
				"?PageNum=" +
				orderdata.pageNum +
				"&PageSize=" +
				orderdata.pageSize +
				"&ShipmentProvider=" + orderdata.shipmentProvider;
			return APIService.doGet(url).then(resp => {
				if (resp) {
					return resp;
				}
				throw resp;
			});
		}
		else if (orderdata.filterValue) {
			let url =
				APIUrlConstants.getApiUrl("getOrderList") +
				"?PageNum=" +
				orderdata.pageNum +
				"&PageSize=" +
				orderdata.pageSize +
				"&Status=" +
				orderdata.filterValue;
			return APIService.doGet(url).then(resp => {
				if (resp) {
					return resp;
				}
				throw resp;
			});
		} else {
			let url = APIUrlConstants.getApiUrl("getOrderList") + "?PageNum=" + orderdata.pageNum + "&PageSize=" + orderdata.pageSize;
			return APIService.doGet(url).then(resp => {
				if (resp) {
					return resp;
				}
				throw resp;
			});
		}
	}
	static getOrderDetails(orderDetailsId) {
		let url = APIUrlConstants.getApiUrl("getOrderDetails") + "?OrderDetailsId=" + orderDetailsId;
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
	static updateOrderStatus(updateData) {
		let url =
			APIUrlConstants.getApiUrl("updateOrderStatus") +
			"?orderStatus=" +
			updateData.status +
			"&UpdateText=" +
			updateData.note +
			"&orderDetailsId=" +
			updateData.orderDetailsId;
		return APIService.doPost(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
	static invoiceDownload(orderId) {
		let url = APIUrlConstants.getApiUrl("getOrderInvoice") + "?OrderDetailsId=" + orderId;
		return APIService.doDownload(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
	static amountRefund(amount, orderDetailsId) {
		let url = APIUrlConstants.getApiUrl("amountRefund") + "?amount=" + amount + "&OrderDetailId=" + orderDetailsId;
		return APIService.doPost(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}

	static wayBillDownload(OrderDetailsId) {
		let url = APIUrlConstants.getApiUrl("getWaybillNumber") + "?OrderDetailsId=" + OrderDetailsId;
		return APIService.doDownload(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
	static getPickupDetails(OrderDetailId) {
		let url = APIUrlConstants.getApiUrl("pickupDetails") + "?OrderDetailId=" + OrderDetailId;
		return APIService.doGet(url).then(resp => {
			if (resp) {
				return resp;
			}
			throw resp;
		});
	}
}
