import * as types from "./ActionsTypes";
import { popToast } from "../../components/Toast/Toast";
import { BlogService } from "../../containers/More/Blog/Blog.service";

export function getBlogsAction(pageNum, pageSize) {
    return dispatch => {
        dispatch(request());
        BlogService.getBlogList(pageNum, pageSize).then(
            response => {
                if (response.result === 200) {
                    dispatch(success(response.data));
                } else {
                    dispatch(failure(response.message));
                    popToast.show("Error : " + response.Message || "Something went wrong");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong !");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: types.GET_BLOGS_REQUEST };
    }
    function success(data) {
        return { type: types.GET_BLOGS_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.GET_BLOGS_FAILURE, error };
    }
}

export function updateBlogsAction(blogId, body) {
    return dispatch => {
        dispatch(request());
        BlogService.updateBlog(blogId, body).then(
            response => {
                if (response.result === 200) {
                    dispatch(success(response));
                    dispatch(getBlogsAction(1, 10));
                } else {
                    dispatch(failure(response.message));
                    popToast.show("Error : " + response.Message || "Something went wrong");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong !");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: types.UPDATE_BLOGS_REQUEST };
    }
    function success(data) {
        return { type: types.UPDATE_BLOGS_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.UPDATE_BLOGS_FAILURE, error };
    }
}

export function deleteBlogsAction(blogId) {
    return dispatch => {
        dispatch(request());
        BlogService.deleteBlog(blogId).then(
            response => {
                if (response.result === 200) {
                    dispatch(success(response));
                    dispatch(getBlogsAction(1, 10));
                } else {
                    dispatch(failure(response.message));
                    popToast.show("Error : " + response.Message || "Something went wrong");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong !");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: types.DELETE_BLOGS_REQUEST };
    }
    function success(data) {
        return { type: types.DELETE_BLOGS_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.DELETE_BLOGS_FAILURE, error };
    }
}

export function createBlogsAction(BlogData) {
    return dispatch => {
        dispatch(request());
        BlogService.addBlog(BlogData).then(
            response => {
                if (response.result === 200) {
                    dispatch(success(response));
                    dispatch(getBlogsAction(1, 10));
                } else {
                    dispatch(failure(response.message));
                    popToast.show("Error : " + response.Message || "Something went wrong");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong !");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: types.ADD_BLOGS_REQUEST };
    }
    function success(data) {
        return { type: types.ADD_BLOGS_SUCCESS, data };
    }
    function failure(error) {
        return { type: types.ADD_BLOGS_FAILURE, error };
    }
}
