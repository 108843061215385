import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { includes, find, last, isEmpty, filter, compact, isArray, size } from "lodash";
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import {
	getProductDetailsAction,
	getAllCategories,
	onUpdateImagesAndVideos,
	onUpdateProduct,
	onDownloadDisplayPicture,
	getOriginalArtworksAction,
	updateMetaDataArtworkEditStatusAction,
} from "../../redux/actions/EditProductAction";
import { getAllLookUpProperty } from "../../redux/actions/LookupAction";
import { Cascader, Select, Checkbox, Tooltip, Icon, Empty, Radio, Button } from "antd";
import { popToast } from "../../components/Toast/Toast";
import "./EditProduct.scss";
import PropertySelect from "./PropertySelect/PropertySelect";

const { Option } = Select;
const countryList = [
	{ name: "INR", value: "INR" },
	{ name: "DHS", value: "DHS" },
	{ name: "AED", value: "AED" },
	{ name: "GBP", value: "GBP" },
	{ name: "USD", value: "USD" },
];

const packageList = [{ name: "Canvas Roll", value: 0 }, { name: "Boxed", value: 1 }, { name: "Crate", value: 2 }];
class EditProductMetaData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			productId: "",
			slugId: "",
			isProductValid: null,
			productResponse: null,
			isMyProduct: null,
			isEditable: null,

			// Basic
			categoryArray: [],
			categoryId: "",
			productName: "",
			artistId: "",
			artistName: "",
			tagsArray: [],
			tags: "",
			oneLineDescription: "",
			description: "",
			// Primary
			height: "",
			width: "",
			depth: "",

			medium1: [],
			isLimitedEdition: false,
			materialsUsed: "",
			yearofCreation: "",
			weight: "",
			originalArtworkId: "",
			materialsUsed: "",
			selectedMaterialsUsed: "",
			packageHeight: "",
			packageWidth: "",
			packageDepth: "",

			// Pricing and stock
			price: "",
			sellingPrice: "",
			shippingCharge: "",
			isHidePrice: false,
			stockManagement: true,
			stock: 1,
			currency: null,

			// Images and Videos
			imagesArray: [],
			// roomImageArray: [],
			existingImagesArray: [],
			// existingRoomImageArray: [],
			deletedFiles: [],
			displayPicture: "",

			existingDisplayPicture: "",
			isDisplayImageModified: false,

			// Features
			productProperties: [],
			packaging: "",

			expandList: {
				basic: true,
				artist: true,
				price: true,
				feature: true,
				image: true,
			},
			isInitialTooltipVisible: null,
			totalSize: 0,
			displaySize: 0,
			editionPublisher: "",
			seriesName: "",
			selectedStyle: [],
			selectedSubject: [],
			panel: false,
			framed: false,
			packaging: "",
			isAbstract: false,
			subject: [],
			style: {},
			conditionReport: "",
			signature: "",
			status: 0,
		};
	}

	componentDidMount() {
		const slugIdValue = this.props.match && this.props.match.params && this.props.match.params.slugId;
		const stockIdValue = (this.props.match && this.props.match.params && this.props.match.params.stockId) || "";

		// this.props.getAllCategories();
		this.props.getOriginalArtworksAction();

		// if (!userStates) getUserInfo();
		if (slugIdValue) {
			// if (isEmpty(categoryListData)) getCategoryList();
			this.props.getProductDetails(slugIdValue, stockIdValue);
		}

		let body = {
			PropertyNames: ["STYLE", "SUBJECT", "MATERIALS", "MEDIUM"],
		};
		this.props.getAllLookUpProperty(body);
		this.setState({ ...this.props.editReducer });
	}

	componentDidUpdate(prevProps) {
		const { allPropertyList } = this.props.lookupReducer;
		if (this.props.editReducer !== prevProps.editReducer) {
			console.log("🚀 ~ file: EditProduct.js ~ line 143 ~ EditProduct ~ componentDidUpdate ~ this.props.editReducer", this.props.editReducer);
			const selectedMaterialsUsed = [];
			const selectedStyle = [];
			const selectedSubject = [];
			if (this.props.editReducer.materialsUsed && this.props.editReducer.materialsUsed.length > 0) {
				this.props.editReducer.materialsUsed.map((obj) => {
					selectedMaterialsUsed.push(obj._id);
				});
			}
			if (this.props.editReducer.subject && this.props.editReducer.subject.length > 0) {
				this.props.editReducer.subject.map((obj) => {
					selectedSubject.push(obj._id);
				});
			}
			if (this.props.editReducer.style) {
				if (this.props.editReducer.style && this.props.editReducer.style._id !== undefined) {
					selectedStyle.push(this.props.editReducer.style._id);
				}
			}
			this.setState({
				...this.props.editReducer,
				medium1: this.props.editReducer && this.props.editReducer.medium && [this.props.editReducer.medium._id],
				selectedMaterialsUsed,
				selectedSubject,
				selectedStyle,
				// existingDisplayPicture: this.props.editReducer && this.props.editReducer.displayPicture
			});
		}

		if (this.props.editReducer.isProductValid !== prevProps.editReducer.isProductValid) {
			// Tooltip
			setTimeout(() => {
				this.setState({
					isInitialTooltipVisible: true,
				});
			}, 1000);
			setTimeout(() => {
				this.setState({
					isInitialTooltipVisible: false,
				});
			}, 6000);
		}
	}

	renderSuggestionChildren = (data) => {
		return (
			data &&
			data.map((sugg, index) => (
				<Option value={sugg._id} key={sugg._id}>
					{sugg.name}
				</Option>
			))
		);
	};
	onChangeRadio = (keyType) => {
		console.log("🚀 ~ file: EditProduct.js ~ line 190 ~ EditProduct ~ keyType", keyType);
		if (keyType === "panel") {
			this.setState({
				panel: !this.state.panel,
			});
		}
		if (keyType === "abstract") {
			this.setState({
				isAbstract: !this.state.isAbstract,
			});
		}
	};
	render() {
		const { allPropertyList } = this.props.lookupReducer;
		const children = [];
		const { updatingProductLoader, downloadingImage, isProductValid, categoryLoader, categoryList, originalArtworks } = this.props.editReducer;
		const { status } = this.state;
		console.log("🚀 ~ file: ManageArtists.js ~ line 52 ~ ManageArtists ~ render ~ status", status);
		return (
			<Root>
				<Topbar title={"Edit Artwork"} />
				<div className="edit-product-wrapper">
					{isProductValid ? (
						isProductValid === "VALID" ? (
							<div className="edit-product-wrapper-box">
								{/* =================================== BASIC ================================ */}
								<p className="edit-product-subtitle">
									<Tooltip visible={this.state.isInitialTooltipVisible} title="Click here to expand and collapse sections">
										<img
											src={require("../../assets/images/common/Arrow_down.svg")}
											alt="Arrow"
											className={this.state.expandList.basic ? "" : "rotate-icon"}
											onClick={this.doExpand.bind(this, "basic")}
										/>
									</Tooltip>
									General Info
								</p>

								{this.state.expandList.basic && (
									<div>
										{/* Product Name */}
										<div className="form-control mb-0">
											<label className="form-label">
												Title
												<span className="mandatory"> *</span>
											</label>
											<input
												autoFocus
												type="text"
												className="form-input"
												value={this.state.productName}
												onChange={this.onHandleInput("productName")}
											/>
										</div>
										{/* Year of creation */}
										<div className="form-control mb-0">
											<label className="form-label">
												Year of creation
												<span className="mandatory"> *</span>
											</label>
											<input type="text" disabled className="form-input" value={this.state.yearofCreation} />
										</div>

										{/* Category */}
										<div className="form-control">
											<label className="form-label">
												Medium (Art Category)
												<span className="mandatory"> *</span>
											</label>
											<Cascader
												className="category-cascader"
												popupClassName="category-cascader-menu"
												options={allPropertyList && allPropertyList.artworkMedium}
												value={this.state.medium1}
												onChange={this.onChangeCascaderValue.bind(this)}
												changeOnSelect={true}
												notFoundContent="No medium found"
												fieldNames={{ label: "name", value: "_id" }}
												// defaultValue={[this.state.medium && this.state.medium._id]}
												placeholder={categoryLoader ? "Fetching medium..." : "Choose medium"}
											/>
										</div>

										{/* Medium (Materials & Techniques) * */}
										<div className="form-control mb-0">
											<label className="form-label">
												Medium (Materials & Techniques)
												<span className="mandatory"> *</span>
											</label>
											<Select
												mode="materialsUsed"
												showSearch
												style={{ width: "100%" }}
												placeholder="Type and click enter"
												value={this.state.selectedMaterialsUsed}
												onChange={(e) => this.onHandlePropertyValueChangeInput("materialsUsed", e, this)}
												className="tags-select"
												mode="tags"
												dropdownClassName="dropdp"
												optionFilterProp="children"
												filterOption={(input, option) =>
													option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
													option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}
											>
												{this.renderSuggestionChildren(allPropertyList && allPropertyList.materials)}
											</Select>
										</div>
										<br />
										{/* Is limited edition */}
										<div className="form-control form-checkbox">
											<Checkbox
												checked={this.state.isLimitedEdition}
												onChange={(event) => this.handleCheckbox(event, "isLimitedEdition")}
											>
												Is artwork a limited edition?(Unique/Multiple)
											</Checkbox>
										</div>

										{this.state.isLimitedEdition && !isEmpty(originalArtworks) && (
											<div className="form-control">
												<label className="form-label">Choose Original Artwork</label>
												<Select value={this.state.originalArtworkId} onChange={this.onChange("originalArtworkId")}>
													{!isEmpty(originalArtworks) &&
														originalArtworks.map((item, index) => (
															<Option key={item._id}>
																<img className="artwork-image" src={item.displayPicture} alt="artworkImage" />
																{item.productName}
															</Option>
														))}
												</Select>
											</div>
										)}

										{/* Stock */}
										<div className="form-control">
											<label className="form-label">
												Stock
												<span className="mandatory"> *</span>
											</label>
											<input type="number" min="0" className="form-input w-100" value={this.state.stock || ""} disabled />
										</div>

										{/* Publisher*/}
										<div className="form-control mb-0">
											<label className="form-label">Publisher</label>
											<input
												type="text"
												className="form-input"
												value={this.state.editionPublisher}
												onChange={this.onHandleInput("editionPublisher")}
											/>
										</div>

										{/* Artist */}
										<div className="form-control">
											<label className="form-label">
												Artist
												<span className="mandatory"> *</span>
											</label>
											<input type="text" className="form-input" disabled value={this.state.artistName || "Anonymous"} />
										</div>

										<p className="edit-product-subtitle">Dimensions (in inches)</p>
										{/* Height */}
										<div className="form-control mb-0">
											<label className="form-label">
												Height
												<span className="mandatory"> *</span>
											</label>
											<input
												type="text"
												className="form-input"
												value={this.state.height}
												onChange={this.onHandleInput("height")}
											/>
										</div>

										{/* Width */}
										<div className="form-control mb-0">
											<label className="form-label">
												Width
												<span className="mandatory"> *</span>
											</label>
											<input
												type="text"
												className="form-input"
												value={this.state.width}
												onChange={this.onHandleInput("width")}
											/>
										</div>

										{/* Depth */}
										<div className="form-control mb-0">
											<label className="form-label">Depth</label>
											<input
												type="text"
												className="form-input"
												value={this.state.depth}
												onChange={this.onHandleInput("depth")}
											/>
										</div>

										{/* <p className="edit-product-subtitle">Primary Features</p> */}

										{/* Original artwork */}

										{/* Medium */}
										{/* <div className="form-control mb-0">
											<label className="form-label">
												Medium
												<span className="mandatory"> *</span>
											</label>
											<input type="text" disabled className="form-input" value={this.state.medium} />
										</div> */}

										{/* Materials used */}
										{/* <div className="form-control mb-0">
											<label className="form-label">
												Materials used
												<span className="mandatory"> *</span>
											</label>
											<input
												type="text"
												className="form-input"
												value={this.state.materialsUsed}
												onChange={this.onHandleInput("materialsUsed")}
											/>
										</div> */}
									</div>
								)}
								{/* =================================== PRICING AND STOCK ================================ */}
								<p className="edit-product-subtitle">
									<img
										src={require("../../assets/images/common/Arrow_down.svg")}
										alt="Arrow"
										className={this.state.expandList.price ? "" : "rotate-icon"}
										onClick={this.doExpand.bind(this, "artist")}
									/>
									Artistic Details
								</p>
								{this.state.expandList.artist && (
									<div>
										{/* Figurative/Abstract */}
										<div className="form-control mb-0">
											<label className="form-label">
												Panel
												<span className="mandatory"> *</span>
											</label>
											<Radio.Group
												onChange={() => this.onChangeRadio("abstract")}
												value={this.state.isAbstract && this.state.isAbstract ? "Abstract" : "Figurative"}
											>
												<Radio value={"Abstract"}>Abstract</Radio>
												<Radio value={"Figurative"}>Figurative</Radio>
											</Radio.Group>
										</div>
										<br />
										{/* seriesName */}
										<div className="form-control mb-0">
											<label className="form-label">Artwork part of the Series</label>
											<input
												type="text"
												className="form-input"
												value={this.state.seriesName}
												onChange={this.onHandleInput("seriesName")}
											/>
										</div>
										{/* Style */}
										<div className="form-control mb-0">
											<label className="form-label">
												Style
												<span className="mandatory"> *</span>
											</label>
											<Select
												mode="style"
												showSearch
												style={{ width: "100%" }}
												placeholder="Please select or type here"
												value={this.state.selectedStyle}
												onChange={(e) => this.onHandlePropertyValueChangeInput("style", e, this)}
												className="tags-select"
												mode="tags"
												dropdownClassName="dropdp"
												filterOption={(input, option) =>
													option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
													option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}
											>
												{this.renderSuggestionChildren(allPropertyList && allPropertyList.artworkStyle)}
											</Select>
										</div>
										<br />
										{/* Style */}
										<div className="form-control mb-0">
											<label className="form-label">
												Subject
												<span className="mandatory"> *</span>
											</label>
											<Select
												mode="subject"
												showSearch
												style={{ width: "100%" }}
												placeholder="Please select or type here"
												value={this.state.selectedSubject}
												onChange={(e) => this.onHandlePropertyValueChangeInput("subject", e, this)}
												className="tags-select"
												mode="tags"
												dropdownClassName="dropdp"
												optionFilterProp="children"
												filterOption={(input, option) =>
													option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
													option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}
											>
												{this.renderSuggestionChildren(allPropertyList && allPropertyList.artworkSubject)}
											</Select>
										</div>
										<br />
										{/* Tags */}
										<div className="form-control">
											<label className="form-label">
												Keywords
												<span className="mandatory"> *</span>
											</label>
											<Select
												mode="tags"
												style={{ width: "100%" }}
												placeholder="Type and click enter"
												value={this.state.tagsArray}
												onChange={this.onHandleInput("tagsArray")}
												className="tags-select"
												dropdownClassName="dropdp"
											>
												{children}
											</Select>
										</div>

										{/* Condition report */}
										<div className="form-control mb-0">
											<label className="form-label">Condition report</label>
											<input
												type="text"
												className="form-input"
												value={this.state.conditionReport}
												onChange={this.onHandleInput("conditionReport")}
											/>
										</div>

										{/* Signature, date, additional labels*/}
										<div className="form-control mb-0">
											<label className="form-label">Signature, date, additional labels</label>
											<input
												type="text"
												className="form-input"
												value={this.state.signature}
												onChange={this.onHandleInput("signature")}
											/>
										</div>

										{/* One Line Description */}
										<div className="form-control mb-0">
											<label className="form-label">One Line Description</label>
											<input
												type="text"
												className="form-input"
												value={this.state.oneLineDescription}
												onChange={this.onHandleInput("oneLineDescription")}
											/>
										</div>

										{/* Description */}
										<div className="form-control">
											<label className="form-label">Description</label>
											<textarea
												className="form-input"
												value={this.state.description}
												onChange={this.onHandleInput("description")}
											/>
										</div>
									</div>
								)}
								{/* =================================== PRICING AND STOCK ================================ */}
								<p className="edit-product-subtitle">
									<img
										src={require("../../assets/images/common/Arrow_down.svg")}
										alt="Arrow"
										className={this.state.expandList.price ? "" : "rotate-icon"}
										onClick={this.doExpand.bind(this, "price")}
									/>
									Pricing
								</p>
								{this.state.expandList.price && (
									<>
										{/* Currency */}
										<div className="form-control">
											<label className="form-label">
												Currency
												<span className="mandatory"> *</span>
											</label>
											<Select showSearch value={this.state.currency} disabled>
												{countryList.map((item, index) => (
													<Option key={item.value} value={item.value}>
														{item.name}
													</Option>
												))}
											</Select>
										</div>

										{/* Product Price */}
										<div className="form-control">
											<label className="form-label">
												Price MRP
												<span className="mandatory"> *</span>
											</label>
											<input
												type="number"
												min="0"
												className="form-input w-100"
												value={this.state.price}
												onChange={this.onHandleInput("price")}
											/>
										</div>

										{/* Selling Price */}
										<div className="form-control">
											<label className="form-label">
												Your Price
												<span className="mandatory"> *</span>
											</label>
											<input
												type="number"
												min="0"
												className="form-input w-100"
												value={this.state.sellingPrice}
												onChange={this.onHandleInput("sellingPrice")}
											/>
										</div>
										{/* Shipping Charge */}
										<div className="form-control">
											<label className="form-label">
												Shipping Charge
												{/* <span className="mandatory"> *</span> */}
											</label>
											<input
												type="number"
												min="0"
												className="form-input w-100"
												value={this.state.shippingCharge}
												onChange={this.onHandleInput("shippingCharge")}
											/>
										</div>

										{/* Hide price */}
										<div className="form-control form-checkbox">
											<Checkbox
												checked={this.state.isHidePrice}
												onChange={(event) => this.handleCheckbox(event, "isHidePrice")}
											>
												Hide price?
											</Checkbox>
											{/* <Checkbox
                                                checked={this.state.isHidePrice}
                                                onChange={event => this.setState({ isHidePrice: event.target.checked })}
                                            >
                                                Hide price?
                                            </Checkbox> */}
										</div>
									</>
								)}

								{/* =================================== FEATURES ================================ */}
								<p className="edit-product-subtitle">
									<img
										src={require("../../assets/images/common/Arrow_down.svg")}
										alt="Arrow"
										className={this.state.expandList.feature ? "" : "rotate-icon"}
										onClick={this.doExpand.bind(this, "feature")}
									/>
									Logistics
								</p>

								{this.state.expandList.feature && (
									<div>
										<p className="edit-product-subtitle">Package Dimensions (in inches)</p>
										<div className="form-control">
											<label className="form-label">
												Packaging
												<span className="mandatory"> *</span>
											</label>
											<select className="form-input" selected={this.state.packaging}>
												<option value="">--Select--</option>
												{packageList.map((data, index) => (
													<option selected={data.value === this.state.packaging ? true : false} value={data.value}>
														{data.name}
													</option>
												))}
											</select>
										</div>
										{/* Height */}
										<div className="form-control mb-0">
											<label className="form-label">
												Height
												<span className="mandatory"> *</span>
											</label>
											<input
												type="text"
												className="form-input"
												value={this.state.packageHeight}
												onChange={this.onHandleInput("packageHeight")}
											/>
										</div>

										{/* Width */}
										<div className="form-control mb-0">
											<label className="form-label">
												Width
												<span className="mandatory"> *</span>
											</label>
											<input
												type="text"
												className="form-input"
												value={this.state.packageWidth}
												onChange={this.onHandleInput("packageWidth")}
											/>
										</div>

										{/* Depth */}
										<div className="form-control mb-0">
											<label className="form-label">Depth</label>
											<span className="mandatory"> *</span>
											<input
												type="text"
												className="form-input"
												value={this.state.packageDepth}
												onChange={this.onHandleInput("packageDepth")}
											/>
										</div>

										{/* Weight */}
										<div className="form-control mb-0">
											<label className="form-label">
												Weight&nbsp;(in Kg)
												<span className="mandatory"> *</span>
											</label>
											<input
												type="number"
												className="form-input"
												value={this.state.weight}
												onChange={this.onHandleInput("weight")}
											/>
										</div>

										{/* Is Framed */}
										<div className="form-control form-checkbox">
											<Checkbox checked={this.state.framed} onChange={(event) => this.handleCheckbox(event, "framed")}>
												This artwork is framed
											</Checkbox>
										</div>

										{/* Panel */}
										<div className="form-control mb-0">
											<label className="form-label">
												Panel
												<span className="mandatory"> *</span>
											</label>
											<Radio.Group
												onChange={() => this.onChangeRadio("panel")}
												value={this.state.panel && this.state.panel ? "SinglePanel" : "MultiPanel"}
											>
												<Radio value={"SinglePanel"}>Single Panel</Radio>
												<Radio value={"MultiPanel"}>Multi Panel</Radio>
											</Radio.Group>
										</div>

										{/* Features */}
										{isEmpty(this.state.productProperties) ? (
											<></>
										) : (
											this.state.productProperties.map((item, index) =>
												item.isSelectDropdown ? (
													<PropertySelect
														key={index}
														item={item}
														onHandlePropertyInput={this.onHandlePropertyInput.bind(this)}
													/>
												) : (
													<React.Fragment key={index}>
														{/* ---------------------------- String ---------------------------- */}
														{item.dataType === 0 && (
															<div className="form-control">
																<label className="form-label">
																	{item.propertyName || "<Property name>"}
																	<span className="mandatory">{item.isMandatory && " *"}</span>
																</label>
																<div className="input-unit-wrapper">
																	<input
																		type="text"
																		className="form-input"
																		value={item.value}
																		onChange={this.onHandlePropertyInput.bind(this, item)}
																	/>
																	<span title="Unit" className="unit">
																		{item.propertyUnit.toUpperCase() || "-"}
																	</span>
																</div>
															</div>
														)}

														{/* ---------------------------- Number/Double ---------------------------- */}
														{(item.dataType === 1 || item.dataType === 2) && (
															<div className="form-control">
																<label className="form-label">
																	{item.propertyName || "<Property name>"}
																	<span className="mandatory">{item.isMandatory && " *"}</span>
																</label>
																<div className="input-unit-wrapper">
																	<input
																		type="number"
																		min="0"
																		className="form-input"
																		value={item.value}
																		onChange={this.onHandlePropertyInput.bind(this, item)}
																	/>
																	<span title="Unit" className="unit">
																		{item.propertyUnit.toUpperCase() || "-"}
																	</span>
																</div>
															</div>
														)}

														{/* ---------------------------- Boolean ---------------------------- */}
														{item.dataType === 3 && (
															<div className="form-control form-checkbox">
																<Checkbox
																	checked={this.getCheckBoxValue(item.value)}
																	onChange={this.onHandlePropertyInput.bind(this, item)}
																>
																	<span className="mandatory">{item.isMandatory && "*  "}</span>
																	{item.propertyName || "<Property name>"}
																</Checkbox>
															</div>
														)}

														{/* ---------------------------- StringList(array) ---------------------------- */}
														{item.dataType === 4 && (
															<div className="form-control-array">
																<label className="form-label">
																	{item.propertyName || "<Property name>"}
																	<span className="mandatory">{item.isMandatory && " *"}</span>
																</label>
																{this.renderArrayProperties(item)}
																<div className="button-wrapper">
																	Add {item.propertyName || "values"}
																	<Icon
																		type="plus-square"
																		theme="filled"
																		style={{ marginLeft: "4px" }}
																		onClick={this.onAddMoreHighlights.bind(this, item)}
																	/>
																</div>
															</div>
														)}

														{/* ---------------------------- HTML Content ---------------------------- */}
														{item.dataType === 5 && (
															<div className="form-control">
																<label className="form-label">
																	{item.propertyName || "<Property name>"}
																	<span className="mandatory">{item.isMandatory && " *"}</span>
																</label>
																<textarea
																	className="form-input"
																	value={item.value}
																	onChange={this.onHandlePropertyInput.bind(this, item)}
																/>
															</div>
														)}
													</React.Fragment>
												)
											)
										)}
									</div>
								)}

								<div className="edit-product-button-container">
									<Button onClick={this.onClickUpdateDetails.bind(this)} loading={updatingProductLoader}>
										Update artwork Details
									</Button>
								</div>

								{/* =================================== IMAGES ================================ */}

								<p className="edit-product-subtitle">
									<img
										src={require("../../assets/images/common/Arrow_down.svg")}
										alt="Arrow"
										className={this.state.expandList.image ? "" : "rotate-icon"}
										onClick={this.doExpand.bind(this, "image")}
									/>
									Image
								</p>

								{this.state.expandList.image && (
									<div
										className={
											status === 1 || status === 4 || status === 5 || status === 6 || status === 13 ? "" : "disabledbutton"
										}
									>
										{/* --------------- Display Picture --------------- */}
										<p className="product-iv-title">Display Picture</p>
										<div className="product-iv-contents">
											<div className="product-iv-item">
												{this.state.existingDisplayPicture ? (
													<img src={this.state.existingDisplayPicture} alt="Artwork" />
												) : (
													<img
														src={
															this.state.displayPicture
																? this.state.displayPicture
																: require("../../assets/images/common/Add_Images.svg")
														}
														alt="Artwork"
													/>
												)}
												<div className="display-hover-screen">
													{downloadingImage ? (
														<Icon type="loading" className="download-icon" />
													) : (
														<div>
															{(this.state.existingDisplayPicture || this.state.displayPicture) && (
																<Icon
																	className="download-icon"
																	type="download"
																	onClick={this.onClickImageDownload.bind(this)}
																/>
															)}
															<Icon className="edit-icon" type="edit" onClick={(e) => this.attach_display.click()} />
														</div>
													)}
												</div>
												<input
													type="file"
													style={{ display: "none" }}
													accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
													onChange={(e) => this.onImageSelect(e, "disp")}
													ref={(ref) => (this.attach_display = ref)}
												/>
											</div>
										</div>

										{/* --------------- Images --------------- */}
										<p className="product-iv-title">Image</p>

										<div className="product-iv-contents">
											{!isEmpty(this.state.existingImagesArray) &&
												this.state.existingImagesArray.map((data, index) => (
													<div key={index} className="product-iv-item">
														<img src={data} alt="" />
														<Tooltip placement="top" title={"Remove Image"}>
															<Icon
																className="close-icon"
																type="close-circle"
																theme="twoTone"
																twoToneColor="#908e8e"
																onClick={this.onRemoveExistingMedia.bind(this, "existingImagesArray", data, index)}
															/>
														</Tooltip>
													</div>
												))}
											{!isEmpty(this.state.imagesArray) &&
												this.state.imagesArray.map((data, index) => (
													<div key={index} className="product-iv-item">
														<img src={data.mediaResult} alt="" />
														<Tooltip placement="top" title={"Remove Image"}>
															<Icon
																className="close-icon"
																type="close-circle"
																theme="twoTone"
																twoToneColor="#908e8e"
																onClick={this.onRemoveMedia.bind(this, "imagesArray", data, index)}
															/>
														</Tooltip>
													</div>
												))}
											{this.checkIfMoreImagesCanBeAdded(this.state.existingImagesArray, this.state.imagesArray, 5) && (
												<div className="product-iv-item" onClick={(e) => this.attach_image.click()}>
													<img src={require("../../assets/images/common/Add_Images.svg")} alt="" />
													<input
														type="file"
														style={{ display: "none" }}
														accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
														onChange={(e) => this.onImageSelect(e, "imagesArray")}
														ref={(ref) => (this.attach_image = ref)}
													/>
												</div>
											)}
										</div>

										{/* --------------- Room View Image --------------- */}
										{/*<p className="product-iv-title">Room view image</p>

										<div className="product-iv-contents">
											{!isEmpty(this.state.existingRoomImageArray) &&
												this.state.existingRoomImageArray.map((data, index) => (
													<div key={index} className="product-iv-item">
														<img src={data} alt="" />
														<Tooltip placement="top" title={"Remove Image"}>
															<Icon
																className="close-icon"
																type="close-circle"
																theme="twoTone"
																twoToneColor="#908e8e"
																onClick={this.onRemoveExistingMedia.bind(this, "existingRoomImageArray", data, index)}
															/>
														</Tooltip>
													</div>
												))}
											 {!isEmpty(this.state.roomImageArray) &&
												this.state.roomImageArray.map((data, index) => (
													<div key={index} className="product-iv-item">
														<img src={data.mediaResult} alt="" />
														<Tooltip placement="top" title={"Remove Image"}>
															<Icon
																className="close-icon"
																type="close-circle"
																theme="twoTone"
																twoToneColor="#908e8e"
																onClick={this.onRemoveMedia.bind(this, "roomImageArray", data, index)}
															/>
														</Tooltip>
													</div>
												))}

											 {this.checkIfMoreImagesCanBeAdded(this.state.existingRoomImageArray, this.state.roomImageArray, 1) && (
												<div className="product-iv-item" onClick={e => this.room_image.click()}>
													<img src={require("../../assets/images/common/Add_Images.svg")} alt="" />
													<input
														type="file"
														style={{ display: "none" }}
														accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
														onChange={e => this.onImageSelect(e, "roomImageArray")}
														ref={ref => (this.room_image = ref)}
													/>
												</div>
											)}
										</div>*/}
									</div>
								)}
								<div className="edit-product-button-container">
									<Button onClick={this.onClickUpdateImages.bind(this)} loading={updatingProductLoader}>
										Update artwork Images
									</Button>
								</div>
							</div>
						) : (
							<Empty description="Invalid Artwork!" />
						)
					) : (
						<Empty description="Fetching artwork..." />
					)}
				</div>
			</Root>
		);
	}

	// Expand/collapse
	doExpand = (name) => {
		this.setState({
			expandList: {
				...this.state.expandList,
				[name]: !this.state.expandList[name],
			},
		});
	};

	// On change inputs/selects
	onHandleInput = (name) => (event) => {
		if (name === "tagsArray") {
			this.setState({
				[name]: event,
			});
		} else {
			this.setState({
				[name]: event.target.value,
			});
		}
	};

	onHandlePropertyValueChangeInput = (keyType, event) => {
		console.log("🚀 ~ file: EditProduct.js ~ line 1070 ~ EditProduct ~ event", event);
		console.log("🚀 ~ file: EditProduct.js ~ line 1070 ~ EditProduct ~ keyType", keyType);

		if (keyType === "materialsUsed") {
			this.setState({
				selectedMaterialsUsed: event,
			});
		}
		if (keyType === "style") {
			this.setState({
				selectedStyle: last(event),
			});
		}
		if (keyType === "subject") {
			this.setState({
				selectedSubject: event,
			});
		}
	};

	onChange = (name) => (value) => {
		this.setState({
			[name]: value,
		});
	};

	// On Checkbox changes
	handleCheckbox(event, name) {
		this.setState({
			[name]: event.target.checked,
		});
	}

	// On select a category
	onChangeCascaderValue(categoryArray) {
		let selectedID = last(categoryArray);
		this.setState(
			{
				categoryArray,
				categoryId: selectedID,
				medium1: categoryArray,
			},
			() => {
				if (selectedID) {
				}
			}
		);
	}

	// STRING, NUMBER, DOUBLE, BOOL, HTML
	// On handle change event for inputs inside property array
	onHandlePropertyInput(itemDetails, event) {
		let changingItem = this.findThePropertyFromArray(itemDetails);
		changingItem["value"] = itemDetails.isSelectDropdown ? event : itemDetails.dataType === 3 ? event.target.checked : event.target.value;

		// State changed using setState - to update the component
		this.setState({ productProperties: this.state.productProperties });
	}

	// Check the value is string or boolean
	getCheckBoxValue(value) {
		if (typeof value === "string") {
			const isTrueSet = value === "true";
			return isTrueSet;
		}
		return value;
	}

	// render STRINGLIST
	renderArrayProperties = (item) => {
		if (isArray(item.value)) {
			return item.value.map((itemInput, index) => (
				<div className="form-control" key={index}>
					<input type="text" value={itemInput} className="form-input" onChange={this.onHandleArrayPropertyInput.bind(this, item, index)} />
				</div>
			));
		} else {
			let parsedArray = JSON.parse(item.value);
			return parsedArray.map((itemInput, index) => (
				<div className="form-control" key={index}>
					<input type="text" value={itemInput} className="form-input" onChange={this.onHandleArrayPropertyInput.bind(this, item, index)} />
				</div>
			));
		}
	};

	// on clik add highlights
	onAddMoreHighlights(itemDetails) {
		let changingItem = this.findThePropertyFromArray(itemDetails);

		changingItem.value = isArray(changingItem.value) ? changingItem.value : JSON.parse(changingItem.value);

		// Pushing empty value to arr
		changingItem.value.push("");

		// State changed using setState - to update the component
		this.setState({ productProperties: this.state.productProperties });
	}

	// On handle string list input fields
	onHandleArrayPropertyInput(itemDetails, index, event) {
		let changingItem = this.findThePropertyFromArray(itemDetails);

		changingItem.value = isArray(changingItem.value) ? changingItem.value : JSON.parse(changingItem.value);

		// Assigning new value to that index
		changingItem.value[index] = event.target.value;

		// State changed using setState - to update the component
		this.setState({ productProperties: this.state.productProperties });
	}

	// To get the property from array
	findThePropertyFromArray = (itemDetails) => {
		let changingPropertyObject = this.state.productProperties.find(function (item) {
			return item.propertyId === itemDetails.propertyId;
		});
		return changingPropertyObject;
	};

	// TODO: Find a better solution for depth mandatory checks in category sculptures
	isSculpture = (categoryArr) => {
		const getSculptureId = find(this.props.editReducer.categoryList, ["categoryName", "Sculptures"]) || null;
		const sculptureId = getSculptureId ? getSculptureId._id : null;
		return includes(categoryArr, sculptureId);
	};

	// Checking form validations
	isFormValid() {
		const {
			productName,
			oneLineDescription,
			description,
			categoryId,
			tagsArray,
			height,
			width,
			depth,
			medium,
			materialsUsed,
			yearofCreation,
			weight,
			packageHeight,
			packageWidth,
			packageDepth,
			price,
			sellingPrice,
			shippingCharge,
			stock,
			currency,
			productProperties,
			selectedStyle,
			selectedSubject,
			medium1,
		} = this.state;

		if (!productName.trim()) {
			popToast.show("Please enter an artwork name!");
			return false;
		}
		// if (!oneLineDescription.trim()) {
		// 	popToast.show("Please enter the one line description!");
		// 	return false;
		// }
		// if (!description.trim()) {
		// 	popToast.show("Please enter the description!");
		// 	return false;
		// }
		if (!height || +height <= 0) {
			popToast.show("Please enter height!");
			return false;
		}
		if (!width || +width <= 0) {
			popToast.show("Please enter width!");
			return false;
		}
		if (this.isSculpture(this.state.categoryArray)) {
			if (!depth || +depth < 0) {
				popToast.show("Please enter depth!");
				return false;
			}
		}
		if (medium1 <= 0 || compact(medium1.map((s) => s.trim())).length < 1) {
			popToast.show("Please choose medium!");
			return false;
		}

		if (!materialsUsed) {
			popToast.show("Please enter the material's used!");
			return false;
		}
		if (isEmpty(selectedStyle)) {
			popToast.show("Please choose style!");
			return false;
		}
		if (selectedSubject.length <= 0 || compact(selectedSubject.map((s) => s.trim())).length < 1) {
			popToast.show("Please choose subject!");
			return false;
		}

		if (yearofCreation === "") {
			popToast.show("Please enter the year of creation!");
			return false;
		}
		if (!categoryId || !categoryId.trim()) {
			popToast.show("Please choose a category!");
			return false;
		}
		if (tagsArray.length <= 0 || compact(tagsArray.map((s) => s.trim())).length < 1) {
			popToast.show("Please choose tags!");
			return false;
		}
		if (!packageHeight || +packageHeight <= 0) {
			popToast.show("Please enter package height!");
			return false;
		}
		if (!packageWidth || +packageWidth <= 0) {
			popToast.show("Please enter package width!");
			return false;
		}
		if (!packageDepth || +packageDepth <= 0) {
			popToast.show("Please enter package depth!");
			return false;
		}
		// Pricing
		if (!price || +price <= 0) {
			popToast.show("Please enter a valid price!");
			return false;
		}
		if (!sellingPrice || +sellingPrice > +price || +sellingPrice < 0) {
			popToast.show("Please enter a valid selling price!");
			return false;
		}
		if (!shippingCharge || +shippingCharge <= -1) {
			popToast.show("Please enter the shipping charge!");
			return false;
		}
		if (!weight || +weight <= 0) {
			popToast.show("Please enter the weight!");
			return false;
		}
		if (!stock || +stock <= 0) {
			popToast.show("Please enter the stock!");
			return false;
		}
		if (!currency.trim()) {
			popToast.show("Please enter the currency!");
			return false;
		}
		// Features
		if (productProperties.length > 0) {
			let found = productProperties.find((element) => {
				// Boolean [3] values cannot be mandatory
				if (element.dataType !== 3 && element.dataType !== 4 && element.isMandatory && !element.value.toString().trim()) {
					return element;
				}

				// StringList[4] should have minimum one length after removing null values
				else if (element.dataType === 4 && element.isMandatory && compact(element.value).length < 1) {
					return element;
				}
			});
			if (found) {
				popToast.show("Please make sure all mandatory fields are entered!");
				return false;
			}
		}
		return true;
	}
	findTheItemFromArray = (data, value) => {
		let item = data.find(function (item) {
			return item._id === value;
		});
		return item;
	};
	onClickUpdateDetails() {
		const { allPropertyList } = this.props.lookupReducer;
		let style = {};
		let subjects = [];
		const { selectedStyle, selectedSubject, selectedMaterialsUsed, medium1 } = this.state;

		//Style Mapping
		let selectedStyleData = [];
		if (isArray(selectedStyle)) {
			selectedStyle &&
				selectedStyle.map((obj) => {
					selectedStyleData = this.findTheItemFromArray(allPropertyList.artworkStyle, obj);
				});
		} else if (selectedStyle && selectedStyle !== undefined) {
			selectedStyleData = this.findTheItemFromArray(allPropertyList.artworkStyle, selectedStyle);
		}

		if (selectedStyleData !== undefined) {
			let styleObj = {
				_id: selectedStyleData._id,
				name: selectedStyleData.name,
			};
			style = styleObj;
		} else {
			let styleObj = {
				_id: "",
				name: selectedStyle,
			};
			style = styleObj;
		}

		//Subject Mapping

		selectedSubject &&
			selectedSubject.map((obj) => {
				let selectedData = this.findTheItemFromArray(allPropertyList.artworkSubject, obj);
				if (selectedData !== undefined) {
					let subjectObj = {
						_id: selectedData._id,
						name: selectedData.name,
					};
					subjects.push(subjectObj);
				} else {
					let subjectObj = {
						_id: "",
						name: obj,
					};
					subjects.push(subjectObj);
				}
			});
		let mediumValue = last(medium1);
		let selectedMedium = this.findTheItemFromArray(allPropertyList.artworkMedium, mediumValue);
		let mediumObj = {};
		if (selectedMedium !== undefined) {
			mediumObj = {
				_id: selectedMedium._id,
				name: selectedMedium.name,
			};
		}

		//Material Binding
		let materialsUsed = [];
		selectedMaterialsUsed &&
			selectedMaterialsUsed.map((obj) => {
				let selectedMaterials = this.findTheItemFromArray(allPropertyList.materials, obj);
				if (selectedMaterials !== undefined) {
					let materialObj = {
						_id: selectedMaterials._id,
						name: selectedMaterials.name,
					};
					materialsUsed.push(materialObj);
				} else {
					let materialObj = {
						_id: "",
						name: obj,
					};
					materialsUsed.push(materialObj);
				}
			});

		if (this.isFormValid()) {
			let request = {
				...this.state,
				tags: this.state.tagsArray.join(","),
				style,
				subject: subjects,
				medium: mediumObj,
				materialsUsed: materialsUsed,
			};
			this.props.onUpdateProduct(request);
			const pageNum = this.props.match && this.props.match.params && this.props.match.params.pageNum;
			const stockIdValue = (this.props.match && this.props.match.params && this.props.match.params.stockId) || "";

			this.props.updateMetaDataArtworkEditStatusAction(2, stockIdValue);
			this.props.history.push(`/manage-artworks/${pageNum !== undefined ? pageNum : 1}`);
		}
	}

	onClickUpdateMetaDataStatus = () => {
		const stockIdValue = (this.props.match && this.props.match.params && this.props.match.params.stockId) || "";
		this.props.updateMetaDataArtworkEditStatusAction(1, stockIdValue);
	};

	// {/* =================================== IMAGES ================================ */}
	// Generate Unique Key for filters
	generateKey = (pre = "MEDIA") => {
		return `${pre}_${new Date().getTime()}`;
	};

	// Checking if uploading file is valid
	isFileValid = (file, type) => {
		// TODO : Validation?
		if (type === "disp") {
			let total = this.state.totalSize;
			let demoSize = this.state.displaySize;
			let display = total - demoSize;
			let size = display + file.size;
			if (size > 220200960) {
				popToast.show("Total media size should be below 210MB. Try choosing again", "warning");
				return false;
			} else {
				this.setState({
					displaySize: file.size,
					totalSize: size,
				});
			}
		}
		if (type === "imagesArray") {
			let total = this.state.totalSize;
			let size = total + file.size;
			if (size > 220200960) {
				popToast.show("Total media size should be below 210MB. Try choosing again", "warning");
				return false;
			} else {
				this.setState({
					totalSize: size,
				});
			}
		}
		if (file.size > 31457280) {
			popToast.show("Media size should be below 30MB. Try choosing again!", "warning");
			return false;
		}
		if (!file.type.startsWith("image/")) {
			popToast.show("Try uploading a valid image file!", "warning");
			return false;
		}
		return true;
	};

	// On select image
	onImageSelect = (e, type) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		e.target.value = null;
		let image = "";
		let img = new Image();
		img.src = window.URL.createObjectURL(file);
		img.onload = () => {
			if (img.width >= 1080 && img.height >= 1080) {
				if (file && this.isFileValid(file, type)) {
					image = reader.result;
					reader.onloadend = () => {
						// Creating a media object
						let mediaObj = {
							index: this.generateKey(),
							media: file,
							mediaResult: reader.result,
						};

						// Pushing to array
						if (type === "imagesArray") {
							this.setState({
								imagesArray: [...this.state.imagesArray, mediaObj],
							});
							// setImageLoader(false);
						} else if (type === "disp") {
							this.setState({
								existingDisplayPicture: reader.result,
								displayPicture: file,
								isDisplayImageModified: true,
							});
						}
						// else if (type === "roomImageArray") {
						// 	this.setState({
						// 		roomImageArray: [mediaObj]
						// 	});
						// 	// setImageLoader(false);
						// }
					};

					reader.readAsDataURL(file);
				}
			} else {
				popToast.show("Image uploaded of lower resolution. Please upload image with minimum of 1080 (H) X 1980 (W)", "warning");
			}
		};
	};

	// To check if more images can be added to each section
	checkIfMoreImagesCanBeAdded = (arr1, arr2, max) => {
		const total = size(arr1) + size(arr2);
		if (total < max) {
			return true;
		}
		return false;
	};

	//Removing images
	onRemoveMedia = (stateName, data) => {
		let total = this.state.totalSize;
		let size = total - data.media.size;
		this.setState({
			totalSize: size,
			[stateName]: this.state[stateName].filter((media) => media.index !== data.index),
		});
	};

	// Remove existing media
	onRemoveExistingMedia = (stateName, data) => {
		this.setState({
			[stateName]: filter(this.state[stateName], function (ele) {
				return ele !== data;
			}),
			deletedFiles: [...this.state.deletedFiles, data],
		});
	};

	// Download Image
	onClickImageDownload = () => {
		this.props.onDownloadDisplayPicture(this.state.productId, this.state.slugId);
	};

	onClickUpdateImages() {
		const pageNum = this.props.match && this.props.match.params && this.props.match.params.pageNum;
		this.props.onUpdateImagesAndVideos(this.state, pageNum);


		if (this.state.isDisplayImageModified === true) {
			const stockIdValue = (this.props.match && this.props.match.params && this.props.match.params.stockId) || "";

			this.props.updateMetaDataArtworkEditStatusAction(2, stockIdValue);
		}
		//this.props.history.push(`/manage-artworks/${pageNum !== undefined ? pageNum : 1}`);
	}
}

const mapStateToProps = (state) => {
	return {
		editReducer: state.editProductReducer,
		addBrandsReducer: state.addBrandsReducer,
		lookupReducer: state.lookupReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProductDetails: bindActionCreators(getProductDetailsAction, dispatch),
		getAllCategories: bindActionCreators(getAllCategories, dispatch),
		getOriginalArtworksAction: bindActionCreators(getOriginalArtworksAction, dispatch),
		onUpdateProduct: bindActionCreators(onUpdateProduct, dispatch),
		onUpdateImagesAndVideos: bindActionCreators(onUpdateImagesAndVideos, dispatch),
		onDownloadDisplayPicture: bindActionCreators(onDownloadDisplayPicture, dispatch),
		getAllLookUpProperty: bindActionCreators(getAllLookUpProperty, dispatch),
		updateMetaDataArtworkEditStatusAction: bindActionCreators(updateMetaDataArtworkEditStatusAction, dispatch),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditProductMetaData);
