import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Pagination, Table, Select, Tooltip } from "antd";
//Files
import { getOrdersListAction } from "../../redux/actions/OrdersAction";
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { Util } from "../../util/util";
import { isNull } from 'lodash';

// SCSS
import "./Orders.scss";

const { Option } = Select;

class Orders extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterValue: "All",
			shipmentProvider: "All",
			pageNumValue: 1
		};
	}

	componentDidMount() {
		// List Sellers by fetching details from URL - When Page Loads
		this.setSearchParams();
	}

	componentDidUpdate(prevProps) {
		// List Sellers by fetching details from URL - When URL change occur
		const locationChanged = this.props.location !== prevProps.location;
		if (locationChanged) {
			this.setSearchParams();
		}
	}
	render() {
		const { ordersListLoader, ordersList, ordersListCount } = this.props.ordersReducer;
		const options = { year: "numeric", month: "long", day: "numeric" };
		const FilterList = [
			{ label: "All", value: "All" },
			{ label: "Payment Pending", value: 0 },
			{ label: "Pending Confirmation", value: 1 },
			{ label: "Order Confirmed", value: 2 },
			{ label: "Packed", value: 3 },
			{ label: "Collected For Shipping", value: 4 },
			{ label: "Shipped", value: 5 },
			{ label: "Delivered", value: 6 },
			{ label: "Return Requested", value: 7 },
			{ label: "Shipped Return", value: 8 },
			{ label: "Returned", value: 9 },
			{ label: "Cancelled", value: 10 },
			{ label: "Payment Failed", value: 11 },
			{ label: "Pending With Artist", value: 12 },
			{ label: "Shipment On Hold", value: 13 }
		];
		const providerList = [
			{ label: "All", value: "All" },
			{ label: "RTISTIQ", value: "RTISTIQ" },
			{ label: "DHL", value: "DHL" },
			{ label: "FEDEX", value: "FEDEX" },
			{ label: "ARAMAX", value: "ARAMAX" },
			{ label: "NINJAVAN", value: "NINJAVAN" }
		];
		const columns = [
			{
				title: "Products",
				dataIndex: "productName",
				key: "productName",
				render: (text, record) => (
					<span className="colWrapper" onClick={this.toggleOrderStatus.bind(this, "orders/order-status", record)}>
						{new Date(record.orderDate).toLocaleDateString() === new Date().toLocaleDateString() && (
							<img className="labelImage" src={require("../../assets/images/common/New_Label.svg")} alt="label" />
						)}
						<img className="colImage" src={record.productImage} alt="productImage" />
						<div className="colDiv">
							<Tooltip placement="topLeft" title={text ? text : "Nil"}>
								<p className="colText">{text ? text : "Nil"}</p>
							</Tooltip>
							<p className="colGreyText">{record.oneLineDescription}</p>
						</div>
					</span>
				)
			},
			{
				title: "Price",
				dataIndex: "amount",
				key: "amount",
				render: (text, record) => <p className="textBlack">{record.currency}&nbsp;{text.toLocaleString("en-IN")}</p>
			},
			{
				title: "Buyer",
				dataIndex: "buyerName",
				key: "buyerName",
				render: text => (
					<div className="artistDiv">
						<img src={require("../../assets/images/common/User.svg")} alt="user" />
						{text}
					</div>
				)
			},
			{
				title: "Order ID",
				dataIndex: "orderId",
				key: "orderId"
			},
			{
				title: "Order Date",
				dataIndex: "orderDate",
				key: "orderDate",
				render: text => <p className="textBlack">{new Date(text).toLocaleDateString("en-IN", options)}</p>
			},
			{
				title: "Status",
				key: "orderCurrentStatus",
				dataIndex: "orderCurrentStatus",
				render: text => {
					return (
						<div className="dropDown">
							{text === 0 && <p className="textOrange">Payment Pending</p>}
							{text === 1 && <p className="textOrange">Pending Confirmation</p>}
							{text === 2 && <p className="textBlue">Order Confirmed</p>}
							{text === 3 && <p className="textBlue">Packed</p>}
							{text === 4 && <p className="textDarkBlue">Collected For Shipping</p>}
							{text === 5 && <p className="textDarkBlue">Shipped</p>}
							{text === 6 && <p className="textGreen">Delivered</p>}
							{text === 7 && <p className="textBrown">Return Requested</p>}
							{text === 8 && <p className="textBrown">Shipped Return</p>}
							{text === 9 && <p className="textBrown">Returned</p>}
							{text === 10 && <p className="textRed">Cancelled</p>}
							{text === 11 && <p className="textRed">Payment Failed</p>}
							{text === 12 && <p className="textBrown">Pending With Artist</p>}
							{text === 13 && <p className="textBrown">Shipment On Hold</p>}
						</div>
					);
				}
			}
		];

		return (
			<Root>
				<Topbar title={"Orders"} />
				<div className="orders-wrapper">
					<div className="filter-wrapper">
						<img className="filter-img" src={require("../../assets/images/common/Filter.svg")} alt="filter" />
						<Select style={{ width: 200 }} value={this.state.filterValue} placeholder="Filtered by Status" onChange={this.onChangeFilter(this)}>
							{FilterList &&
								FilterList.map((item, index) => (
									<Option key={item.value} >
										{item.label}
									</Option>
								))}
						</Select>
						<Select style={{ width: 220 }} value={this.state.shipmentProvider} onChange={this.onChangeProviderFilter(this)}>
							{providerList &&
								providerList.map((item, index) => (
									<Option key={item.value} >
										{item.label}
									</Option>
								))}
						</Select>
					</div>
					<div className="sub-wrapper">
						{ordersList && ordersList.length !== 0 ? (
							<Table rowKey={record => record._id} columns={columns} dataSource={ordersList} pagination={false} />
						) : ordersListLoader ? (
							<CircularProgress className="circular-loader" size={30} />
						) : (
									<div className="empty-table">No Orders Found.</div>
								)}
						{!ordersListLoader && (
							<Pagination current={parseInt(this.state.pageNumValue)} total={ordersListCount} pageSize={10} onChange={this.onSelectPageNumber(this)} />
						)}
					</div>
				</div>
			</Root>
		);
	}

	// ------------------------ When Page loads/ URL changes ------------------------- //
	// Call API by fetching details from URL
	setSearchParams() {
		const { location } = this.props;

		// When filter/Search params exist
		if (location.search) {
			let parsedQuery = Util.queryStringHandling(location.search, "PARSE");
			this.setState(
				{
					filterValue: parsedQuery.Filter,
					shipmentProvider: parsedQuery.Provider,
					pageNumValue: this.props.match.params.pageNum
				},
				() => {
					this.doSearchFilterSellers(parsedQuery);
				}
			);
		}

		// When there is no filter/Search params
		else {
			this.setState({ pageNumValue: this.props.match.params.pageNum }, () => {
				this.doSearchFilterSellers();
			});
		}
	}

	// --------------------- When filter/search/pagination events trigger --------------------- //

	//Pagination
	onSelectPageNumber = name => event => {
		this.setState({ pageNumValue: event }, () => {
			this.doChangeUrlWithQuery();
		});
	};

	// Filter
	onChangeFilter = name => event => {
		this.setState(
			{
				filterValue: event,
				pageNumValue: 1
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	};

	//Provider Filter
	onChangeProviderFilter = name => event => {
		this.setState(
			{
				shipmentProvider: event,
				pageNumValue: 1
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	}
	// Change URL based on filter/search/Page values
	doChangeUrlWithQuery() {
		let searchCriteria = {
			Filter: this.state.filterValue,
			Provider: this.state.shipmentProvider
		};
		const queryString = Util.queryStringHandling(searchCriteria, "STR");
		this.props.history.push("/orders/" + this.state.pageNumValue + "?" + queryString);
	}

	// ----------------------- API - Fetch Sellers ----------------------------- //
	doSearchFilterSellers() {
		// POST request body
		if (this.state.filterValue !== "All" && this.state.shipmentProvider !== "All") {
			let request = { pageNum: this.state.pageNumValue, pageSize: 10, filterValue: this.state.filterValue, shipmentProvider: this.state.shipmentProvider };
			this.props.getOrdersListAction(request);
		}
		else if (this.state.filterValue !== "All") {
			console.log('object :>> ', this.state.filterValue);
			let request = { pageNum: this.state.pageNumValue, pageSize: 10, filterValue: this.state.filterValue };
			this.props.getOrdersListAction(request);
		}
		else if (this.state.shipmentProvider !== "All") {
			let request = { pageNum: this.state.pageNumValue, pageSize: 10, shipmentProvider: this.state.shipmentProvider };
			this.props.getOrdersListAction(request);
		}
		else {
			let request = { pageNum: this.state.pageNumValue, pageSize: 10 };
			this.props.getOrdersListAction(request);
		}
	}

	// -------------------------- Seller Profile -------------------------- //
	toggleOrderStatus(path, item) {
		window.location.href = "/#/" + path + "/" + item._id;
		//this.props.setSellerProfile(item);
	}
}

const mapStateToProps = state => {
	return {
		ordersReducer: state.ordersReducer
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getOrdersListAction: orderData => {
			dispatch(getOrdersListAction(orderData));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
