import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, CircularProgress } from '@material-ui/core';
import './ConfirmationMessageDialog.scss';

export default class ConfirmationMessageDialog extends Component {
  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.handleClose}
        BackdropProps={{ classes: { root: 'dialog-backdrop' } }} className="confirmation-dialog">
        <img className="close-btn" onClick={this.props.handleClose} src={require('../../assets/images/common/Close_h.svg')} alt="" />
        <DialogTitle className="dialog-title">{this.props.confirmTitle || 'Confirmation'}</DialogTitle>
        <DialogContent className="confirmation-content">
          <div className="confirmation-body">
            <p>{this.props.confirmMessage || 'Are you sure?'}</p>
          </div>
          <div className="confirm-action">
            <button className="btn-orange" disabled={this.props.loader} onClick={this.onClickConfirm.bind(this)}>
              {this.props.confirmButton || 'Confirm'}
              {this.props.loader && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  onClickConfirm() {
    this.props.onConfirm();
  }
}
