import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { Util } from '../../util/util';
import { popToast, Toast } from '../../components/Toast/Toast';
// Redux
import { connect } from 'react-redux';
import { login } from '../../redux/actions/CommonAction';
import './Login.scss';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            redirectToReferrer: false
        };
        Util.clearLocal();
    }

    componentWillReceiveProps(newProps) {
        this.setState({ redirectToReferrer: newProps.loginReducer.redirectToReferrer });
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: "/dashboard" } };

        if (this.state.redirectToReferrer === true) {
            return <Redirect to={from} />
        }

        return (
            <div className="login-wrapper">
                <Toast />
                <div className="login-box">
                    <div className="logo-box">
                        <img src={require('../../assets/images/common/Logo.svg')} alt="Icon" className="logo-icon" />
                        {/* <img src={require('../../assets/images/common/logo-text.svg')} alt="TWAIB" className="logo-text" /> */}
                    </div>
                    {/* <p className="sub-text">SUPER ADMIN</p> */}

                    <form className="login-form">

                        <div className="login-form-header">
                            Sign In
                        </div>

                        <div className="form-control">
                            <div className="floating-input-wrapper">
                                <input
                                    type="text"
                                    autoFocus
                                    placeholder=" "
                                    value={this.state.username}
                                    onChange={this.onInputChangeHandler('username')} />
                                <span>Email</span>
                            </div>
                        </div>

                        <div className="form-control">
                            <div className="floating-input-wrapper">
                                <input
                                    type="password"
                                    className="font-password"
                                    placeholder=" "
                                    value={this.state.password}
                                    onChange={this.onInputChangeHandler('password')} />
                                <span>Password</span>
                            </div>
                        </div>

                        <div className="forget-link">
                            <a className="animated-border" onClick={this.goToForgetPassword.bind(this)}>
                                <span>Forgot Password?</span>
                            </a>
                        </div>

                        <div className="form-control">
                            <button
                                className="btn-orange"
                                onClick={this.onSignInClick.bind(this)}
                                disabled={this.props.loginReducer.loginRequest}>
                                Login
                                {this.props.loginReducer.loginRequest && <CircularProgress size={24} className="login-progress" />}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    // On change input
    onInputChangeHandler = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };

    // Email validation
    isValidEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email)
            return re.test(String(email).toLowerCase());
        return false;
    }

    // Sign In : Sign in form validation
    isSignInValid() {
        if (!this.state.username.trim()) {
            popToast.show('Enter email');
            return false;
        }
        if (!this.state.password) {
            popToast.show('Enter password');
            return false;
        }
        // TODO : Uncomment validation
        if (!this.isValidEmail(this.state.username.trim())) {
            popToast.show('Invalid email');
            return false;
        }
        return true;
    }

    onSignInClick(e) {
        e.preventDefault();
        if (this.isSignInValid()) {
            let credentials = {
                UserName: this.state.username.trim(),
                Password: this.state.password
            }
            // let credentials = {
            //     UserName: "asd@123.com",
            //     Password: "123456"
            // }
            this.props.login(credentials);
        }
    }

    // Forget Password route
    goToForgetPassword() {
        window.location.href = "/#/forgot-password";
    }
}

const mapStateToProps = state => {
    return {
        loginReducer: state.loginReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (credentials) => {
            dispatch(login(credentials));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
