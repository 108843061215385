import {
    GET_ARTISTSLIST_REQUEST,
    GET_ARTISTSLIST_SUCCESS,
    GET_ARTISTSLIST_FAILURE,
    GET_ARTIST_PROFILE_REQUEST,
    GET_ARTIST_PROFILE_SUCCESS,
    GET_ARTIST_PROFILE_FAILURE,
    SET_ARTIST_FEATURED_REQUEST,
    SET_ARTIST_FEATURED_SUCCESS,
    SET_ARTIST_FEATURED_FAILURE,
    GET_ARTIST_DOWNLOAD_REQUEST,
    GET_ARTIST_DOWNLOAD_SUCCESS,
    GET_ARTIST_DOWNLOAD_FAILURE,
    UPDATE_ARTIST_INFO_REQUEST,
    UPDATE_ARTIST_INFO_SUCCESS,
    UPDATE_ARTIST_INFO_FAILURE,
    TOGGLE_ARTIST_INFO_DIALOG,
    BLOCK_ARTIST,
    BLOCK_ARTIST_SUCCESS,
    BLOCK_ARTIST_FAILURE
} from "./ActionsTypes";
import { ManageArtistsService } from "../../containers/ManageArtists/ManageArtists.service";
import { popToast } from "../../components/Toast/Toast";
import { UserProfileService } from "../../containers/ManageUsers/UserProfile/UserProfile.service";

export function getArtistsList(artistdata, isSort) {
    return dispatch => {
        dispatch(request());
        ManageArtistsService.getArtistsList(artistdata, isSort).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Get artists list Error : " + response.Message || "Something went wrong while loading artists list!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while loading artists list!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: GET_ARTISTSLIST_REQUEST };
    }
    function success(data) {
        return { type: GET_ARTISTSLIST_SUCCESS, data };
    }
    function failure(error) {
        return { type: GET_ARTISTSLIST_FAILURE, error };
    }
}

export function getArtistByIdAction(userId) {
    return dispatch => {
        dispatch(request());
        UserProfileService.getArtistProfile(userId).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Get artists list Error : " + response.Message || "Something went wrong while loading artists list!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while loading artists list!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: GET_ARTIST_PROFILE_REQUEST };
    }
    function success(data) {
        return { type: GET_ARTIST_PROFILE_SUCCESS, data };
    }
    function failure(error) {
        return { type: GET_ARTIST_PROFILE_FAILURE, error };
    }
}
export function setArtistFeaturedAction(featureData, artistdata, userData) {
    return dispatch => {
        dispatch(request());
        ManageArtistsService.setArtistFeatured(featureData).then(
            response => {
                if (response && response.message === "Success") {
                    dispatch(success(response.data));
                    if (artistdata) {
                        dispatch(getArtistsList(artistdata));
                    } else {
                        dispatch(getArtistByIdAction(userData));
                    }
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show(
                        "Set Artist Featured/Unfeatured Error : " + response.Message || "Something went wrong while set artist featured/unfeatured!"
                    );
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while set artist featured/unfeatured!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: SET_ARTIST_FEATURED_REQUEST };
    }
    function success(data) {
        return { type: SET_ARTIST_FEATURED_SUCCESS, data };
    }
    function failure(error) {
        return { type: SET_ARTIST_FEATURED_FAILURE, error };
    }
}

//======================Download Artists===========================//
export function adminDownloadArtistAction() {
    return dispatch => {
        dispatch(request());
        ManageArtistsService.adminDownloadArtists().then(
            response => {
                if (response) {
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = `RtistIQ Artists_${new Date().toLocaleString()}.xlsx`;
                    // link.setAttribute("download", "file.pdf");
                    document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
                    link.click();
                    link.remove(); //afterwards we remove the element again
                    dispatch(success(response.data));
                } else if (response && response.message === "Failure") {
                    dispatch(failure(response));
                    popToast.show("Get order invoice Error : " + response.Message || "Something went wrong while downloading order invoice!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while downloading order invoice!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: GET_ARTIST_DOWNLOAD_REQUEST };
    }
    function success(data) {
        return { type: GET_ARTIST_DOWNLOAD_SUCCESS, data };
    }
    function failure(error) {
        return { type: GET_ARTIST_DOWNLOAD_FAILURE, error };
    }
}

export function updateArtistInfoAction(artistId, data) {
    return dispatch => {
        dispatch(request());
        ManageArtistsService.updateArtistInfo(artistId, data).then(
            response => {
                if (response && response.result === 200 && response.message === "Success") {
                    dispatch(success(response.data));
                } else if (response) {
                    dispatch(failure(response));
                    popToast.show(response.message || "Something went wrong while updating artist info!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while updating artist info!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: UPDATE_ARTIST_INFO_REQUEST };
    }
    function success(data) {
        return { type: UPDATE_ARTIST_INFO_SUCCESS, data };
    }
    function failure(error) {
        return { type: UPDATE_ARTIST_INFO_FAILURE, error };
    }
}

export function blockArtistAction(userId) {
    return dispatch => {
        dispatch(request());
        ManageArtistsService.blockArtistService(userId).then(
            response => {
                if (response && response.message === "Success") {
                    popToast.show("Artist blocked successfully ");
                    dispatch(success(response.data));
                } else if (response && response.message === "Failure") {
                    popToast.show("Artist blocked failed ");
                    dispatch(failure(response));
                    popToast.show(response.Message || "Something went wrong while loading artists list!");
                }
            },
            error => {
                if (error && error.status_message) {
                    popToast.show(error.status_message);
                } else {
                    popToast.show("Something went wrong while loading artists list!");
                }
                console.log("error dispatched for API error!", error);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: BLOCK_ARTIST };
    }
    function success(data) {
        return { type: BLOCK_ARTIST_SUCCESS, data };
    }
    function failure(error) {
        return { type: BLOCK_ARTIST_FAILURE, error };
    }
}

// ============================= Toggle Artist Info Charges Dialog ============================= //
export const toggleArtistInfoDialog = data => ({
    type: TOGGLE_ARTIST_INFO_DIALOG,
    data
});
