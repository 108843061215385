import React, { Component } from 'react';
import { Checkbox } from 'antd';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, CircularProgress, Slide } from '@material-ui/core';
// Components
import { popToast } from '../../../components/Toast/Toast';
// Redux
import { connect } from 'react-redux';
import { updateCategoryData } from '../../../redux/actions/CategoryAction';
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class UpdateCategoryDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categoryName: '',
            slugId: '',
            media: '',
            mediaThumb: '',
            editorState: EditorState.createEmpty(),
            isFeatured: false,
            filterValue: ''
        }
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("props", this.props.updatingCategoryData)
        if (!prevProps.open && this.props.open) {
            this.setState({
                categoryName: this.props.updatingCategoryData.categoryName || '',
                slugId: this.props.updatingCategoryData.slugId || '',
                media: this.props.updatingCategoryData.categoryImage || '',
                mediaThumb: this.props.updatingCategoryData.categoryImage || '',
                editorState: (this.props.updatingCategoryData && this.props.updatingCategoryData.description && EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(this.props.updatingCategoryData.description)))) || EditorState.createEmpty(),
                isFeatured: this.props.updatingCategoryData.featuredCategory || false,
                selectedFilter: this.props.updatingCategoryData.filterPresetID || '',
            });
            console.log("🚀 ~ file: UpdateCategoryDialog.js ~ line 46 ~ UpdateCategoryDialog ~ componentDidUpdate ~ this.props.updatingCategoryData", this.props.updatingCategoryData)
        }
    }
    onChangeFilterCountry(e) {
        console.log("🚀 ~ file: UpdateCategoryDialog.js ~ line 50 ~ UpdateCategoryDialog ~ onChangeFilterCountry ~ e", e)
        this.setState(
            {
                selectedFilter: e.target.value,


            });
    };

    render() {
        const { modalLoader } = this.props.categoryReducer;
        const { editorState } = this.state;
        const { allFilterRules, isLoading } = this.props.filterRuleReducer;

        return (
            <Dialog
                fullScreen
                open={this.props.open}
                onClose={this.props.handleClose}
                TransitionComponent={Transition}
                BackdropProps={{ classes: { root: 'dialog-backdrop' } }}
                className="add-category-dialog"
            >
                <img className="close-btn" onClick={this.props.handleClose} src={require('../../../assets/images/common/Close_h.svg')} alt="" />
                <DialogTitle className="dialog-title">
                    Edit/View Category
                </DialogTitle>
                <DialogContent className="add-category-content">
                    <div className="add-category-body">
                        {(this.state.mediaThumb) ?
                            <label className="upload-box bg-white" onClick={(e) => this.attach_media.click()}>
                                <img className="media-thumb" src={this.state.mediaThumb} alt="Attach Media" />
                            </label>
                            :
                            <div className="upload-box" onClick={(e) => this.attach_media.click()}>
                                <img className="image-upload" src={require('../../../assets/images/common/Icon.svg')} alt="Upload" />
                                Upload icon
                            </div>
                        }
                        <input
                            type="file"
                            style={{ display: "none" }}
                            accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
                            onChange={(e) => this.onImageSelect(e)}
                            disabled={modalLoader}
                            ref={(ref) => this.attach_media = ref}
                        />
                        <div className="form-control">
                            <label className="form-label">Category Name</label>
                            <input
                                type="text"
                                autoFocus
                                className="form-input"
                                value={this.state.categoryName}
                                disabled={modalLoader}
                                onChange={this.onHandleInput('categoryName')} />
                        </div>
                        <div className="form-control">
                            <label className="form-label">Slug Id</label>
                            <input
                                type="text"
                                className="form-input"
                                value={this.state.slugId}
                                disabled={modalLoader}
                                onChange={this.onHandleInput('slugId')} />
                        </div>
                        <div className="form-control">
                            <label className="form-label">Filter Rule</label>

                            <select className="form-input" onChange={this.onChangeFilterCountry.bind(this)} value={this.state.selectedFilter}>
                                <option>Choose Filter Rule</option>
                                {allFilterRules &&
                                    allFilterRules.map((item, index) => (
                                        <option key={index} value={item._id} >
                                            {item.name}
                                        </option>
                                    ))}
                            </select>

                        </div>
                        <div className="form-control">
                            <label className="form-label">Description</label>
                            {/* <textarea
                                className="form-input"
                                value={this.state.description}
                                // disabled={modalLoader}
                                onChange={this.onHandleInput("description")}
                            /> */}
                            <Editor
                                toolbar={
                                    {
                                        options: ['blockType', 'inline', 'list'],
                                        inline: {
                                            visible: true,
                                            inDropdown: false,
                                            options: ['bold', 'italic', 'underline'],
                                        },
                                    }
                                }
                                editorState={editorState}
                                onEditorStateChange={this.onEditorStateChange()}
                                editorStyle={{
                                    height: "300px",
                                    border: "1px solid #E5E5E5",
                                    padding: "10px",
                                    fontFamily: "Times New Roman",
                                    color: "black",
                                    fontWeight: "100",
                                    width: "100%"
                                }}
                                placeholder="Add some contents here"
                            />
                        </div>
                    </div>


                    {/* {!this.props.isUpdatingMainCategory &&
                        <div className="add-category-featured-box">
                            Featured Category ?
                            <Checkbox checked={this.state.isFeatured} value={this.state.isFeatured} onChange={this.onHandleInput('isFeatured')} />
                        </div>
                    } */}

                    <div className="add-category-action">
                        <button className="btn-cancel" onClick={this.onClickCancel.bind(this)}>Cancel</button>
                        <button
                            className="btn-orange"
                            onClick={this.onClickUpdate.bind(this)}
                            disabled={modalLoader}>
                            Update
                            {modalLoader && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    convertToSlug = (Text) => {
        return Text
            .toLowerCase()
            .replace(/[^\w ]+/g, '')
            .replace(/ +/g, '-')
            ;
    };

    // Handle inputs
    onHandleInput = name => event => {
        if (name === "isFeatured")
            this.setState({
                [name]: event.target.checked
            });
        else {
            if (name === "categoryName") {
                this.setState({
                    slugId: this.convertToSlug(event.target.value)
                });
            }
            this.setState({
                [name]: event.target.value
            });
        }
    };

    // On select image
    onImageSelect(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let image = '';
        if (file) {
            reader.onloadend = () => {
                this.setState({
                    media: file,
                    mediaThumb: reader.result
                });
                image = reader.result
            }
            reader.readAsDataURL(file);
        } else {
            this.setState({
                media: '',
                mediaThumb: ''
            });
        }
    }

    isFormValid() {
        if (!this.state.categoryName.trim()) {
            popToast.show('Please enter a category name');
            return false;
        }
        if (!this.state.slugId.trim()) {
            popToast.show("Please enter the slug Id");
            return false;
        }
        if (!this.state.selectedFilter.trim()) {
            popToast.show("Please choose a filter rule");
            return false;
        }
        if (!this.state.media) {
            popToast.show('Please choose a category image');
            return false;
        }
        if (this.state.media.type && !this.state.media.type.startsWith('image/')) {
            popToast.show('Please choose a valid image file');
            return false;
        }
        return true;
    }

    onEditorStateChange = () => (editorState) => {
        this.setState({
            editorState,
        });
    };

    onClickUpdate() {
        const { isUpdatingMainCategory, updatingCategoryData } = this.props;
        const currentParentId = this.props.categoryReducer.currentParentId;

        if (this.isFormValid()) {
            let formBody = new FormData();
            formBody.append('NewCategoryName', this.state.categoryName);
            formBody.append('SlugId', this.state.slugId);
            formBody.append('CategoryId', updatingCategoryData._id);
            // formBody.append('description', this.state.description);
            formBody.append("Description", draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));
            formBody.append('filterPresetID', this.state.selectedFilter);
            formBody.append('CategoryImageFile', this.state.media);

            // For subCategory, 'FeaturedCategory' value is also added to body
            if (!isUpdatingMainCategory) formBody.append('FeaturedCategory', this.state.isFeatured);

            // currentParentId to refresh list
            this.props.updateCategoryData(currentParentId, formBody);
        }
    }

    onClickCancel() {
        this.props.handleClose();
    }

}

const mapStateToProps = state => {
    return {
        categoryReducer: state.categoryReducer,
        filterRuleReducer: state.filterRuleReducer

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCategoryData: (currentParentId, request) => {
            dispatch(updateCategoryData(currentParentId, request))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCategoryDialog);
