import React, { Component } from "react";
import { Checkbox } from "antd";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent, CircularProgress, Slide } from "@material-ui/core";
import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Components
import { popToast } from "../../../components/Toast/Toast";
// Redux
import { connect } from "react-redux";
import { updatePropertyValues } from "../../../redux/actions/LookupAction";
// SCSS
import "../AddDialog/AddLookupDialog.scss";
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
class UpdateLookupDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			id: "",
			slugId: "",
		};
	}
	componentDidMount() {
		console.log("🚀 ~ file: UpdateLookupDialog.js ~ line 29 ~ UpdateLookupDialog ~ componentDidMount ~ this.props.item", this.props.item);
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.open && this.props.open) {
			if (this.props.selectedTab === "collection_category") {
				this.setState({
					name: this.props.item.name,
					id: this.props.item._id,
					slugId: this.props.item.slugId,
				});
			} else {
				this.setState({
					name: this.props.item.name,
					id: this.props.item._id,
				});
			}
		}
	}

	render() {
		const { isLoading } = this.props.lookupReducer;
		const { selectedTab } = this.props;

		return (
			<Dialog
				fullScreen
				open={this.props.open}
				onClose={this.props.handleClose}
				TransitionComponent={Transition}
				BackdropProps={{ classes: { root: "dialog-backdrop" } }}
				className="add-category-dialog"
			>
				<img className="close-btn" onClick={this.props.handleClose} src={require("../../../assets/images/common/Close_h.svg")} alt="" />
				<DialogTitle className="dialog-title">{`Update  ${
					selectedTab === "subject"
						? "Subject"
						: selectedTab === "style"
						? "Style"
						: selectedTab === "materials"
						? "Material"
						: selectedTab === "collection_category"
						? "Collection Category"
						: "Medium"
				}`}</DialogTitle>
				<DialogContent className="add-category-content">
					<div className="add-category-body">
						<div className="form-control">
							<label className="form-label">Name</label>
							<input
								type="text"
								autoFocus
								className="form-input"
								value={this.state.name}
								disabled={isLoading}
								onChange={this.onHandleInput("name")}
							/>
						</div>
					</div>

					{/* {!this.props.isMainCategory &&
                        <div className="add-category-featured-box">
                            Featured Category ?
                            <Checkbox checked={this.state.isFeatured} value={this.state.isFeatured} onChange={this.onHandleInput('isFeatured')} />
                        </div>
                    } */}

					<div className="add-category-action">
						<button className="btn-cancel" onClick={this.onClickCancel.bind(this)}>
							Cancel
						</button>
						<button className="btn-orange" onClick={this.onClickUpdate.bind(this)} disabled={isLoading}>
							Update
							{isLoading && <CircularProgress size={24} className="circular-loader modal-button-loader" />}
						</button>
					</div>
				</DialogContent>
			</Dialog>
		);
	}

	convertToSlug = (Text) => {
		return Text.toLowerCase()
			.replace(/[^\w ]+/g, "")
			.replace(/ +/g, "-");
	};
	// Handle inputs
	onHandleInput = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	isFormValid() {
		if (!this.state.name.trim()) {
			popToast.show("Please enter name");
			return false;
		}

		return true;
	}

	onClickUpdate() {
		if (this.isFormValid()) {
			let data = {
				_id: this.state.id,
				name: this.state.name,
			};
			this.props.updatePropertyValues(this.props.selectedTab, data);
			this.props.handleClose();
		}
	}

	onClickCancel() {
		this.props.handleClose();
	}
}

const mapStateToProps = (state) => {
	return {
		categoryReducer: state.categoryReducer,
		lookupReducer: state.lookupReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updatePropertyValues: (propertyType, body) => {
			dispatch(updatePropertyValues(propertyType, body));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UpdateLookupDialog);
