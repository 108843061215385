import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
import { Pagination, Table, Icon, Tooltip, Popover } from "antd";

// Redux
import { connect } from "react-redux";
import { getAllLookUpProperty, deletePropertyValues } from "../../redux/actions/LookupAction";
import ConfirmationMessageDialog from "../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";
// Components
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";

// SCSS
import "./Lookup.scss";
import loader from "sass-loader";
import AddLookupDialog from "./AddDialog/AddLookupDialog";
import UpdateLookupDialog from "./UpdateDialog/UpdateLookupDialog";
const lookupMainList = [
	{ name: "Medium", value: "medium" },
	{ name: "Materials", value: "materials" },
	{ name: "Style", value: "style" },
	{ name: "Subject", value: "subject" },
	{ name: "Collection Category", value: "collection_category" },
];
class Lookup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: "medium",
			isLoader: false,
			isAddDialogOpen: false,
			isEditDialogOpen: false,
			isDeleteDialogOpen: false,
			selectedItem: {},
		};
	}

	componentDidMount() {
		let body = {
			PropertyNames: ["STYLE", "SUBJECT", "MATERIALS", "MEDIUM", "COLLECTIONCATEGORY"],
		};
		this.props.getAllLookUpProperty(body);
	}

	onClickEdit(value, item, type) {
		this.setState(
			{
				selectedItem: item,
			},
			() => {
				this.toggleEditPropertyDialog();
			}
		);
	}
	onClickDelete(value, item, type) {
		this.setState(
			{
				selectedItem: item,
			},
			() => {
				this.toggleDeletePropertyDialog();
			}
		);
	}
	toggleAddPropertyDialog() {
		this.setState({
			isAddDialogOpen: !this.state.isAddDialogOpen,
		});
	}
	toggleEditPropertyDialog() {
		this.setState({
			isEditDialogOpen: !this.state.isEditDialogOpen,
		});
	}
	toggleDeletePropertyDialog() {
		this.setState({
			isDeleteDialogOpen: !this.state.isDeleteDialogOpen,
		});
	}
	// To set reducer states
	setCurrentStates(state, id) {
		let body = {};

		if (id === "subject") {
			body = {
				PropertyNames: ["SUBJECT"],
			};
		} else if (id === "style") {
			body = {
				PropertyNames: ["STYLE"],
			};
		} else if (id === "materials") {
			body = {
				PropertyNames: ["MATERIALS"],
			};
		} else if (id === "medium") {
			body = {
				PropertyNames: ["MEDIUM"],
			};
		} else if (id === "collection_category") {
			body = {
				PropertyNames: ["COLLECTIONCATEGORY"],
			};
		}
		this.props.getAllLookUpProperty(body);
		this.setState({
			selectedTab: id,
		});
	}

	// ====================== DELETE PROPERTY ====================== //
	// Delete property
	onDeleteProperty() {
		this.props.deletePropertyValues(this.state.selectedTab, this.state.selectedItem._id);
		this.toggleDeletePropertyDialog();
	}

	// Close all dialogs when route changes
	componentWillUnmount() {}

	renderSubList(data, type) {
		const content = (record) => (
			<div>
				<div className="action-span" onClick={this.onClickDelete.bind(this, true, record, type)}>
					<img src={require("../../assets/images/common/delete_active.svg")} alt="edit" />
					<p className="pop-item">Delete</p>
				</div>

				<div className="action-span" onClick={this.onClickEdit.bind(this, true, record, type)}>
					<img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" />
					<p className="pop-item">Edit</p>
				</div>
			</div>
		);
		const columns = [
			{
				title: "Value",
				className: "sorter-no-tooltip",
				dataIndex: "name",
				key: "name",
			},

			{
				title: "Created Date",
				className: "sorter-no-tooltip",
				dataIndex: "createdDate",
				key: "createdDate",
			},
			{
				title: "Update Date",
				className: "sorter-no-tooltip",
				dataIndex: "updatedDate",
				key: "updatedDate",
			},
			{
				title: "Action",
				key: "action",
				fixed: "right",
				render: (record) => (
					<Popover
						placement="bottom"
						content={content(record)}
						overlayClassName="manage-artworks-action-popover"
						// onVisibleChange={this.onVisibleChangeOfArtworkRecord.bind(this, record)}
					>
						<img className="action-image" src={require("../../assets/images/common/artworksActionIcon.svg")} alt="action" />
					</Popover>
				),
			},
		];
		return <Table rowKey={(record) => record._id} columns={columns} dataSource={data} pagination={true} showSorterTooltip sticky />;
	}

	render() {
		const { selectedTab, isLoader, isAddDialogOpen, selectedItem, isEditDialogOpen, isDeleteDialogOpen } = this.state;
		const { allPropertyList, isLoading } = this.props.lookupReducer;
		// MAIN PRODUCT ID : 5cb707fea6bf3f1e185b5820
		// {/* ---------------- Main Category List ---------------- */ }
		const mainCategory = lookupMainList.map((main, index) => {
			return (
				<li
					key={main.value}
					className={selectedTab === main.value ? "active" : ""}
					onClick={this.setCurrentStates.bind(this, "selectedCategoryId", main.value)}
				>
					<div className="list-item-content">
						<Tooltip placement="topLeft" title={main.name}>
							<p>{main.name}</p>
						</Tooltip>
					</div>
				</li>
			);
		});

		return (
			<Root>
				<Topbar
					title={"Lookup"}
					showBreadCrumbs
					breadCrumbList={[
						{
							categoryName: "Lookup",
							index: 0,
						},
					]}
				/>

				<div className="lookup-wrapper">
					{/* ---------------- Main Category List ---------------- */}
					<div className="lookup-main-list">
						{lookupMainList.length > 0 ? (
							<ul className="category-list-wrapper">{mainCategory}</ul>
						) : isLoader ? (
							<div className="category-list-wrapper">
								<CircularProgress className="circular-loader" size={20} />
								Fetching categories...
							</div>
						) : (
							<div className="category-list-wrapper">No lookup found.</div>
						)}
					</div>
					<div className="lookup-sub-list">
						{isLoading ? (
							<div className="category-list-wrapper">
								<CircularProgress className="circular-loader" size={20} />
								Fetching Lookup ...
							</div>
						) : selectedTab === "subject" ? (
							this.renderSubList(allPropertyList && allPropertyList.artworkSubject && allPropertyList.artworkSubject, "subject")
						) : selectedTab === "style" ? (
							this.renderSubList(allPropertyList && allPropertyList.artworkStyle && allPropertyList.artworkStyle, "style")
						) : selectedTab === "materials" ? (
							this.renderSubList(allPropertyList && allPropertyList.materials && allPropertyList.materials, "materials")
						) : selectedTab === "collection_category" ? (
							this.renderSubList(
								allPropertyList && allPropertyList.collectionCategory && allPropertyList.collectionCategory,
								"collection_category"
							)
						) : (
							this.renderSubList(allPropertyList && allPropertyList.artworkMedium && allPropertyList.artworkMedium, "medium")
						)}
						{!isLoading && (
							<div className="add-button-box add-btn-fixed">
								<Tooltip placement="top" title={"Add Property"}>
									<span className="add-btn" onClick={this.toggleAddPropertyDialog.bind(this, selectedTab)}>
										<p>+</p>{" "}
										{`Add  ${
											selectedTab === "subject"
												? "Subject"
												: selectedTab === "style"
												? "Style"
												: selectedTab === "materials"
												? "Material"
												: selectedTab === "collection_category"
												? "Collection Category"
												: "Medium"
										}`}
									</span>
								</Tooltip>
							</div>
						)}
					</div>

					{/* Create Lookup */}
					<AddLookupDialog open={isAddDialogOpen} selectedTab={selectedTab} handleClose={this.toggleAddPropertyDialog.bind(this)} />
					{/* Update Lookup */}
					<UpdateLookupDialog
						selectedTab={selectedTab}
						open={isEditDialogOpen}
						item={selectedItem}
						handleClose={this.toggleEditPropertyDialog.bind(this)}
					/>

					{/* Delete Property */}
					<ConfirmationMessageDialog
						open={isDeleteDialogOpen}
						handleClose={this.toggleDeletePropertyDialog.bind(this)}
						confirmMessage={"Are you sure you want to delete this item?"}
						confirmButton={"Delete"}
						loader={isLoading}
						onConfirm={this.onDeleteProperty.bind(this)}
					/>

					{/* Delete Property */}
				</div>
			</Root>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		lookupReducer: state.lookupReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAllLookUpProperty: (body) => {
			dispatch(getAllLookUpProperty(body));
		},
		deletePropertyValues: (propertyType, id) => {
			dispatch(deletePropertyValues(propertyType, id));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Lookup);
