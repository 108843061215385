import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Pagination, Table, Popover, Icon, Tooltip, Select } from "antd";
//Files
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { Util } from "../../util/util";
import { isEmpty, isNull } from "lodash";

import {
	getUsersList,
	blockUser,
	unblockUser,
	setUserProfile,
	getRoleChangeRequestsAction,
	updateRoleChangeAction,
	getUserKycUser,
	toggleKYCDialog,
	approveKYC,
} from "../../redux/actions/ManageusersAction";
import KycDocumentDialog from "./KycDocumentDialog/KycDocumentDialog";
import ConfirmationMessageDialog from "../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";
// SCSS
import "./ManageUsers.scss";
const { Option } = Select;

const kycStatusList = [
	{ label: "KYC All Status", value: null },
	{ label: "Pending", value: 0 },
	{ label: "Completed", value: 1 },
	{ label: "Approved", value: 2 },
	{ label: "Not Applicable", value: 3 },
];

const artistStatusList = [
	{ label: "Artist All Status", value: null },
	{ label: "Pending", value: 0 },
	{ label: "Approved", value: 1 },
	{ label: "Rejected", value: 2 },
	{ label: "Blocked", value: 3 },
];
class ManageUsers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			blockconfirmationDialog: false,
			unblockconfirmationDialog: false,
			searchValue: "",
			pageNumValue: 1,
			pageNumForUserRequest: 1,
			userId: "",
			isButtonClicked: false,
			approveconfirmationDialog: false,
			rejectconfirmationDialog: false,
			status: null,
			userKycDoc: [],
			kycFilter: null,
			artistStatus: null,
			orderSort: 1,
			orderOption: 1,
		};
	}

	componentDidMount() {
		// List Sellers by fetching details from URL - When Page Loads
		this.setSearchParams();
	}

	componentDidUpdate(prevProps) {
		// List Sellers by fetching details from URL - When URL change occur
		const locationChanged = this.props.location !== prevProps.location;
		if (locationChanged) {
			this.setSearchParams();
		}
	}
	onChangeForSort(pagination, filters, sorter) {
		let sortOptionData = [
			{ label: "userName", value: 0 },
			{ label: "joiningDate", value: 1 },
			{ label: "userRole", value: 6 },
			{ label: "kycStatus", value: 4 },
		];
		if (!isEmpty(sorter)) {
			let findItem = sortOptionData.find((element) => element.label === sorter.columnKey);

			this.setState(
				{
					orderSort: sorter.order === "descend" ? 1 : 0,
					orderOption: findItem.value,
				},
				() => {
					let request = {
						PageNum: parseInt(this.state.pageNumForUserRequest),
						PageSize: 12,
						KYCStatus: this.state.kycFilter,
						Status: this.state.artistStatus,
						sortOrder: sorter.order === "descend" ? 1 : 0,
						userSortOptions: findItem.value,
					};
					this.props.getRoleChangeRequestsAction(request);
				}
			);
		} else {
			this.setState(
				{
					orderSort: null,
					orderOption: null,
				},
				() => {
					let request = {
						PageNum: parseInt(this.state.pageNumForUserRequest),
						PageSize: 12,
						KYCStatus: this.state.kycFilter,
						Status: this.state.artistStatus,
					};
					this.props.getRoleChangeRequestsAction(request);
				}
			);
		}
	}
	render() {
		const {
			usersListLoader,
			savedUserListCount,
			savedusersList,
			getRolechangeRequestLoader,
			getRolechangeRequestList,
			kycDocumentResult,
			kycDialog,
			getRoleChangeRequestCount,
		} = this.props.manageUsersReducer;

		const options = { year: "numeric", month: "long", day: "numeric" };

		const requestcolumns = [
			{
				title: "Name",
				dataIndex: "userName",
				key: "userName",
				sorter: true,
				sortDirections: ["ascend", "descend"],
				render: (text, record) => (
					<a
						href={
							record.userRole === "ARTIST"
								? `https://art.rtistiq.com/en/artist/${record.slugId}`
								: `https://art.rtistiq.com/en/galleries/${record.slugId}`
						}
						rel="noopener noreferrer"
						target="_blank"
					>
						<span className="colWrapper">
							{record.profileImageUrl ? (
								<img className="colImage" src={record.profileImageUrl} alt="img" />
							) : (
								<Icon className="icon-image" type="user" />
							)}
							<Tooltip title={text ? text : "Nil"}>
								<p className="colText">{text ? text : "Nil"}</p>
							</Tooltip>
						</span>
					</a>
				),
			},
			{
				title: "Mobile",
				dataIndex: "phoneNumber",
				key: "phoneNumber",
				render: (text, record) => (
					<p onClick={() => this.props.history.push("/manage-users/user-profile/" + record.userId)}>{text ? text : "Nil"}</p>
				),
			},
			{
				title: "Mail Id",
				dataIndex: "email",
				key: "email",
				render: (text, record) => (
					<Tooltip title={text ? text : "Nil"}>
						<p className="mailtext" onClick={() => this.props.history.push("/manage-users/user-profile/" + record.userId)}>
							{text ? text : "Nil"}
						</p>
					</Tooltip>
				),
			},
			{
				title: "Join Date",
				dataIndex: "joiningDate",
				key: "joiningDate",
				sorter: true,
				sortDirections: ["ascend", "descend"],
				render: (text, record) => (
					<p className="textBlack" onClick={() => this.props.history.push("/manage-users/user-profile/" + record.userId)}>
						{new Date(text).toLocaleDateString("en-IN", options)}
					</p>
				),
			},
			{
				title: "Request For",
				dataIndex: "userRole",
				key: "userRole",
				sorter: true,
				sortDirections: ["ascend", "descend"],
				render: (text, record) => {
					return (
						<React.Fragment>
							{text === "ARTIST" && (
								<p className="textnewBlack" onClick={() => this.props.history.push("/manage-users/user-profile/" + record.userId)}>
									Artist
								</p>
							)}
							{text === "GALLERY" && (
								<p className="textnewYellow" onClick={() => this.props.history.push("/manage-users/user-profile/" + record.userId)}>
									Gallery
								</p>
							)}
						</React.Fragment>
					);
				},
			},
			{
				title: "KYC",
				key: "kycStatus",
				dataIndex: "kycStatus",
				sorter: true,
				sortDirections: ["ascend", "descend"],
				render: (text) => {
					return (
						<div>
							{text === 0 && <p className="textOrange">Pending</p>}
							{text === 1 && <p className="textBrown">Completed</p>}
							{text === 2 && <p className="textGreen">Approved</p>}
							{text === 3 && <p className="textGreen">Not Applicable</p>}
						</div>
					);
				},
			},
			// {
			// 	title: "Status",
			// 	key: "status",
			// 	dataIndex: "status",
			// 	render: text => {
			// 		return (
			// 			<div>
			// 				{text === 0 && <p className="textOrange">Pending</p>}
			// 				{text === 1 && <p className="textGreen">Available</p>}
			// 				{text === 2 && <p className="textBrown">Rejected</p>}
			// 				{text === 3 && <p className="textRed">Blocked</p>}
			// 			</div>
			// 		);
			// 	}
			// },
			{
				title: "Action",
				key: "action",
				render: (record) =>
					record.status <= 2 && (
						<Popover
							placement="bottom"
							content={content(record)}
							overlayClassName="manage-artworks-action-popover"
							// onVisibleChange={this.onVisibleChangeOfArtworkRecord.bind(this, record)}
						>
							<img className="action-image" src={require("../../assets/images/common/artworksActionIcon.svg")} alt="action" />
						</Popover>
					),
			},
		];

		const content = (record) => (
			<div>
				{record.status === 0 && (
					<span className="action-span" onClick={this.toggleApproveConfirmationDialog.bind(this, record, 1)}>
						{/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
						<Icon type="check" />
						<p className="pop-item">Approve</p>
					</span>
				)}
				{record.status === 0 && (
					<span className="action-span" onClick={this.toggleRejectConfirmationDialog.bind(this, record, 2)}>
						{/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
						<Icon type="close" />
						<p className="pop-item">Reject</p>
					</span>
				)}
				{record.kycStatus !== 2 && record.kycStatus !== 3 && (
					<span className="action-span" onClick={this.onClickUserKyc.bind(this, true, record)}>
						{/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
						<Icon type="close" />
						<p className="pop-item">KYC clearance </p>
					</span>
				)}
				{record.kycStatus === 2 && (
					<span className="action-span" onClick={this.onClickUserKyc.bind(this, true, record)}>
						{/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
						<Icon type="close" />
						<p className="pop-item">Review KYC</p>
					</span>
				)}
			</div>
		);
		const columns = [
			{
				title: "Name",
				dataIndex: "name",
				key: "name",
				render: (text, record) => (
					<span className="colWrapper" onClick={() => this.props.history.push("/manage-users/user-profile/" + record.userId)}>
						{record.profileImageUrl ? (
							<img className="colImage" src={record.profileImageUrl} alt="productImage" />
						) : (
							<Icon className="icon-image" type="user" />
						)}
						<Tooltip title={text ? text : "Nil"}>
							<p className="colText">{text ? text : "Nil"}</p>
						</Tooltip>
					</span>
				),
			},
			{
				title: "User ID",
				dataIndex: "userId",
				key: "userId",
				render: (text, record) => <p onClick={() => this.props.history.push("/manage-users/user-profile/" + record.userId)}>{text}</p>,
			},
			{
				title: "Mobile",
				dataIndex: "phoneNumber",
				key: "phoneNumber",
				render: (text, record) => (
					<p onClick={() => this.props.history.push("/manage-users/user-profile/" + record.userId)}>{text ? text : "Nil"}</p>
				),
			},
			{
				title: "Mail Id",
				dataIndex: "email",
				key: "email",
				render: (text, record) => (
					<Tooltip title={text ? text : "Nil"}>
						<p className="mailtext" onClick={() => this.props.history.push("/manage-users/user-profile/" + record.userId)}>
							{text ? text : "Nil"}
						</p>
					</Tooltip>
				),
			},
			{
				title: "Join Date",
				dataIndex: "joiningDate",
				key: "joiningDate",
				render: (text, record) => (
					<p className="textBlack" onClick={() => this.props.history.push("/manage-users/user-profile/" + record.userId)}>
						{new Date(text).toLocaleDateString("en-IN", options)}
					</p>
				),
			},
			{
				title: "Country",
				dataIndex: "country",
				key: "country",
				render: (text, record) => (
					<p onClick={() => this.props.history.push("/manage-users/user-profile/" + record.userId)}>{text ? text : "Nil"}</p>
				),
			},
			{
				title: "Action",
				key: "action",
				render: (record) => (record.blocked ? unblockContent(record) : blockContent(record)),
				// <Popover
				// 	placement="bottom"
				// 	content={record.blocked ? unblockContent : blockContent}
				// 	overlayClassName="manage-artworks-action-popover"
				// 	onVisibleChange={this.onVisibleChange.bind(this, record)}
				// 	test={true}
				// >
				// 	<img className="action-image" src={require("../../assets/images/common/artworksActionIcon.svg")} alt="action" />
				// </Popover>
			},
		];
		const blockContent = (item) => (
			<div>
				<span className="action-span" onClick={this.toggleBlockConfirmationDialog.bind(this, item)}>
					<Tooltip placement="top" title="Block">
						<img src={require("../../assets/images/common/Block.svg")} alt="block" />
						{/* <p className="pop-item">Block</p> */}
					</Tooltip>
				</span>
			</div>
		);
		const unblockContent = (item) => (
			<div>
				<span className="action-span" onClick={this.toggleUnblockConfirmationDialog.bind(this, item)}>
					<Tooltip placement="top" title="Unblock">
						<img src={require("../../assets/images/common/Block_New.svg")} alt="delete" />
						{/* <p className="pop-item">Unblock</p> */}
					</Tooltip>
				</span>
			</div>
		);

		return (
			<Root>
				<Topbar title={"Manage Users"} showSearch searchValue={this.state.searchValue} onChangeSearch={this.onChangeSearch.bind(this)} />
				<div className="manageusers-wrapper">
					<div className="top-wrapper">
						<span className={this.state.isButtonClicked ? "" : "active"} onClick={this.onButtonClick.bind(this)}>
							Users
						</span>
						<span className={this.state.isButtonClicked ? "second-active" : "second-span"} onClick={this.onButtonClick.bind(this)}>
							User Request
						</span>
					</div>

					{this.state.isButtonClicked && (
						<div className="filter-wrapper">
							<Select className="filter-field" value={this.state.kycFilter} onChange={this.onChangeFilterKyc(this)}>
								{kycStatusList.map((item, index) => (
									<Option key={index} value={item.value}>
										{item.label}
									</Option>
								))}
							</Select>
							{/* <Select className="filter-field" value={this.state.artistStatus} onChange={this.onChangeFilterArtistStatus(this)}>
								{
									artistStatusList.map((item, index) => (
										<Option key={index} value={item.value}>
											{item.label}
										</Option>
									))}
							</Select> */}
						</div>
					)}
					{this.state.isButtonClicked ? (
						getRolechangeRequestList && getRolechangeRequestList.length !== 0 ? (
							<>
								<Table
									rowKey={(record) => record._id}
									columns={requestcolumns}
									dataSource={getRolechangeRequestList}
									pagination={false}
									onChange={this.onChangeForSort.bind(this)}
								/>
								<Pagination
									current={parseInt(this.state.pageNumForUserRequest)}
									total={getRoleChangeRequestCount}
									pageSize={12}
									onChange={this.onSelectPageNumberForUserRequest(this)}
								/>
							</>
						) : getRolechangeRequestLoader ? (
							<div className="manageusers-wrapper">
								<CircularProgress className="circular-loader" size={30} />
							</div>
						) : (
							<div className="manageusers-wrapper">
								<div className="empty-table">No Users Found.</div>
							</div>
						)
					) : savedusersList && savedusersList.length !== 0 ? (
						<>
							<Table rowKey={(record) => record._id} columns={columns} dataSource={savedusersList} pagination={false} />
							{savedUserListCount > 10 && (
								<Pagination
									current={parseInt(this.state.pageNumValue)}
									total={savedUserListCount}
									pageSize={12}
									onChange={this.onSelectPageNumber(this)}
								/>
							)}
						</>
					) : usersListLoader ? (
						<div className="manageusers-wrapper">
							<CircularProgress className="circular-loader" size={30} />
						</div>
					) : (
						<div className="manageusers-wrapper">
							<div className="empty-table">No Users Found.</div>
						</div>
					)}
					{/* <div className="filter-wrapper">
							<img className="filter-img" src={require("../../assets/images/common/Filter.svg")} alt="filter" />
							<Select style={{ width: 200 }} placeholder="Filtered by User/Artist/Gallery" onChange={this.fliterChange(this)}>
								{FilterList &&
									FilterList.map((item, index) => (
										<Option key={index} value={item.value}>
											{item.label}
										</Option>
									))}
							</Select>
							<Select style={{ width: 200 }} placeholder="Filtered by Action" onChange={this.fliterChange(this)}>
								{FilterList &&
									FilterList.map((item, index) => (
										<Option key={index} value={item.value}>
											{item.label}
										</Option>
									))}
							</Select>
						</div> */}
				</div>

				<ConfirmationMessageDialog
					open={this.state.blockconfirmationDialog}
					handleClose={this.toggleBlockConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to block this user?"}
					onConfirm={this.onBlockUser.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.unblockconfirmationDialog}
					handleClose={this.toggleUnblockConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to unblock this user?"}
					onConfirm={this.onUnblockUser.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.approveconfirmationDialog}
					handleClose={this.toggleApproveConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to approve this user?"}
					onConfirm={this.onApproveUser.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.rejectconfirmationDialog}
					handleClose={this.toggleRejectConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to reject this user?"}
					onConfirm={this.onRejectUser.bind(this)}
				/>
				<KycDocumentDialog
					open={kycDialog}
					approveKYC={this.approveKYC.bind(this)}
					handleClose={this.onToggleKycModel.bind(this, false)}
					data={kycDocumentResult && kycDocumentResult}
				/>
			</Root>
		);
	}

	// ------------------------ When Page loads/ URL changes ------------------------- //
	// Call API by fetching details from URL
	setSearchParams() {
		const { location } = this.props;

		// When filter/Search params exist
		if (location.search) {
			let parsedQuery = Util.queryStringHandling(location.search, "PARSE");
			if (parsedQuery.requests) {
				this.setState({ isButtonClicked: true });
				let body = {
					PageNum: this.state.pageNumForUserRequest,
					PageSize: 12,
				};
				this.props.getRoleChangeRequestsAction(body);
			} else
				this.setState(
					{
						searchValue: parsedQuery.Search,
						pageNumValue: this.props.match.params.pageNum,
					},
					() => {
						this.doSearchFilterUsers(parsedQuery);
					}
				);
		}

		// When there is no filter/Search params
		else {
			this.setState({ pageNumValue: this.props.match.params.pageNum }, () => {
				this.doSearchFilterUsers();
			});
		}
	}

	// --------------------- When filter/search/pagination events trigger --------------------- //

	//Pagination
	onSelectPageNumber = (name) => (event) => {
		this.setState({ pageNumValue: event }, () => {
			this.doChangeUrlWithQuery();
		});
	};

	onSelectPageNumberForUserRequest = (name) => (event) => {
		this.setState({ pageNumForUserRequest: event }, () => {
			let body = {};
			if (this.state.orderSort === null && this.state.orderOption === null) {
				body = {
					PageNum: event,
					PageSize: 12,
					KYCStatus: this.state.kycFilter,
					Status: this.state.artistStatus,
				};
			} else {
				body = {
					PageNum: event,
					PageSize: 12,
					KYCStatus: this.state.kycFilter,
					Status: this.state.artistStatus,
					sortOrder: this.state.orderSort,
					userSortOptions: this.state.orderOption,
				};
			}

			this.props.getRoleChangeRequestsAction(body);
		});
	};

	// Search
	onChangeSearch(search) {
		this.setState(
			{
				searchValue: search,
				pageNumValue: 1,
			},
			() => {
				if (this.state.isButtonClicked) {
					let body = {
						PageNum: this.state.pageNumForUserRequest,
						PageSize: 12,
						AlphabetSearch: search,
					};
					this.props.getRoleChangeRequestsAction(body);
				} else {
					this.doChangeUrlWithQuery();
				}
			}
		);
	}

	// Change URL based on filter/search/Page values
	doChangeUrlWithQuery() {
		let searchCriteria = {
			Search: this.state.searchValue,
		};
		const queryString = Util.queryStringHandling(searchCriteria, "STR");
		this.props.history.push("/manage-users/" + this.state.pageNumValue + "?" + queryString);
	}

	// ----------------------- API - Fetch Sellers ----------------------------- //
	doSearchFilterUsers() {
		// POST request body
		let request = {
			pageNum: this.state.pageNumValue,
			pageSize: 12,
			Name: this.state.searchValue || "",
		};
		this.props.getUsersList(request);
	}

	// -------------------------- User Profile -------------------------- //
	toggleUserProfile(path, item) {
		window.location.href = "/#/" + path + "/" + item.userId;
		this.props.setUserProfile(item);
	}
	//----------------Dialog for approve artwork-----------------//
	toggleApproveConfirmationDialog(item, status) {
		this.setState(
			{
				approveconfirmationDialog: !this.state.approveconfirmationDialog,
			},
			() => {
				this.setState({
					userId: item._id,
					status: status,
				});
			}
		);
	}

	async onClickUserKyc(value, item) {
		await this.props.toggleKYCDialog(value);
		await this.props.getUserKycUser(item.userId);
	}

	async approveKYC(userId, isType) {
		await this.props.approveKYC(userId, isType);
		let body = {};
		// let body = {
		// 	PageNum: this.state.pageNumForUserRequest,
		// 	PageSize: 12,
		// }
		// await this.props.getRoleChangeRequestsAction(body);
		if (this.state.orderSort === null && this.state.orderOption === null) {
			body = {
				PageNum: this.state.pageNumForUserRequest,
				PageSize: 12,
				KYCStatus: this.state.kycFilter,
				Status: this.state.artistStatus,
			};
		} else {
			body = {
				PageNum: this.state.pageNumForUserRequest,
				PageSize: 12,
				KYCStatus: this.state.kycFilter,
				Status: this.state.artistStatus,
				sortOrder: this.state.orderSort,
				userSortOptions: this.state.orderOption,
			};
		}

		await this.props.getRoleChangeRequestsAction(body);
	}

	onToggleKycModel(value) {
		// Toggle dialog
		this.props.toggleKYCDialog(value);
	}
	onChangeFilterArtistStatus = (name) => (event) => {
		this.setState(
			{
				artistStatus: event,
			},
			() => {
				let request = {
					PageNum: parseInt(this.state.pageNumForUserRequest),
					PageSize: 12,
					KYCStatus: this.state.kycFilter,
					Status: this.state.artistStatus,
				};
				this.props.getRoleChangeRequestsAction(request);
			}
		);
	};
	onChangeFilterKyc = (name) => (event) => {
		this.setState(
			{
				kycFilter: event,
			},
			() => {
				let request = {
					PageNum: parseInt(this.state.pageNumForUserRequest),
					PageSize: 12,
					KYCStatus: this.state.kycFilter,
					Status: this.state.artistStatus,
				};
				this.props.getRoleChangeRequestsAction(request);
			}
		);
	};
	//-------------Dialog for reject artwork---------------------//
	toggleRejectConfirmationDialog(item, status) {
		this.setState(
			{
				rejectconfirmationDialog: !this.state.rejectconfirmationDialog,
			},
			() => {
				this.setState({
					userId: item._id,
					status: status,
				});
			}
		);
	}
	//-------------Approve User Confirm---------------------//
	async onApproveUser() {
		await this.props.updateRoleChangeAction(this.state.userId, this.state.status);
		await this.handleReset();
	}

	//-------------Reject User Confirm---------------------//
	async onRejectUser() {
		await this.props.updateRoleChangeAction(this.state.userId, this.state.status);
		await this.handleReset();
	}

	// -------------------------- Block / Unblock -------------------------- //
	//Dialog for blocking user
	toggleBlockConfirmationDialog(item) {
		this.setState(
			{
				blockconfirmationDialog: !this.state.blockconfirmationDialog,
			},
			() => {
				if (this.state.blockconfirmationDialog) {
					// set id of rule only when dialog opens
					this.setState({
						blockedUser: item.userId,
					});
				}
			}
		);
	}

	//Dialog for unblocking user
	toggleUnblockConfirmationDialog(item) {
		this.setState(
			{
				unblockconfirmationDialog: !this.state.unblockconfirmationDialog,
			},
			() => {
				if (this.state.unblockconfirmationDialog) {
					// set id of rule only when dialog opens
					this.setState({
						unblockedUser: item.userId,
					});
				}
			}
		);
	}

	// Block User Confirm
	onBlockUser() {
		let request = {
			pageNum: this.state.pageNumValue,
			pageSize: 12,
			Name: this.state.searchValue || "",
		};
		this.props.blockUser(this.state.blockedUser, request);
		this.handleReset();
	}

	// Unblock User Confirm
	onUnblockUser() {
		let request = {
			pageNum: this.state.pageNumValue,
			pageSize: 12,
			Name: this.state.searchValue || "",
		};
		this.props.unblockUser(this.state.unblockedUser, request);
		this.handleReset();
	}

	//Reset fields
	handleReset() {
		this.setState({
			...this.state,
			blockconfirmationDialog: false,
			unblockconfirmationDialog: false,
			approveconfirmationDialog: false,
			rejectconfirmationDialog: false,
			status: null,
			userId: "",
		});
	}

	//----------------Dropdown Visible Change-----------------//
	onVisibleChange(item) {
		this.setState({
			userId: item.userId,
		});
	}
	onButtonClick() {
		this.setState({ isButtonClicked: !this.state.isButtonClicked }, () => {
			if (this.state.isButtonClicked) {
				let body = {
					PageNum: 1,
					PageSize: 12,
					sortOrder: this.state.orderSort,
					userSortOptions: this.state.orderOption,
				};
				this.props.getRoleChangeRequestsAction(body);
			} else {
				this.doChangeUrlWithQuery();
			}
		});
	}
}
const mapStateToProps = (state) => {
	return {
		manageUsersReducer: state.manageUsersReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsersList: (userinput) => {
			dispatch(getUsersList(userinput));
		},
		blockUser: (userId, input) => {
			dispatch(blockUser(userId, input));
		},
		unblockUser: (userId, input) => {
			dispatch(unblockUser(userId, input));
		},
		setUserProfile: (userdata) => {
			dispatch(setUserProfile(userdata));
		},
		getRoleChangeRequestsAction: (body) => {
			dispatch(getRoleChangeRequestsAction(body));
		},
		updateRoleChangeAction: (userId, status) => {
			dispatch(updateRoleChangeAction(userId, status));
		},
		getUserKycUser: (userId) => {
			dispatch(getUserKycUser(userId));
		},
		approveKYC: (userId, isType) => {
			dispatch(approveKYC(userId, isType));
		},
		toggleKYCDialog: (value) => {
			dispatch(toggleKYCDialog(value));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ManageUsers);
