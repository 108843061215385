import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
//Files
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { getNotificationsListAction } from "../../redux/actions/NotificationAction";
// SCSS
import "./Notification.scss";

class Notification extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {
		this.props.getNotificationsListAction();
	}
	render() {
		const { notificationListLoader, notificationList } = this.props.notificationReducer;
		return (
			<Root>
				<Topbar title={"Notification "} />
				{notificationList && notificationList.length !== 0 ? (
					<div className="notification-wrapper">
						{notificationList.map((listItem, listIndex) => (
							<div key={listIndex} className="notification-card" onClick={this.onNotificationClick.bind(this, listItem)}>
								<div className="imagebox">
									{new Date(listItem.notificationTime).toLocaleDateString() === new Date().toLocaleDateString() && (
										<p className="labelImage">N</p>
									)}
									<img className="empty-image" src={require("../../assets/images/common/Empty_User.svg")} alt="profileimage" />
								</div>
								<div className="title-wrapper">
									<p>{listItem.notificationTitle}</p>
									<span className="description-box">
										{listItem.notificationBody}
										{/* <p>Yaacov Heller</p> {listItemnotificationBody} <p>The Dream</p> (Oil, Enamel on Canvas | 130 x 97 cm |
										Stretched canvas),<p>Price $999</p> */}
									</span>
								</div>
								<div className="date-wrapper">
									<p>{this.getTime(listItem.notificationTime) || "Now"}</p>
								</div>
							</div>
						))}
					</div>
				) : notificationListLoader ? (
					<div className="notification-wrapper">
						<CircularProgress className="circular-loader" size={30} />
					</div>
				) : (
					<div className="notification-wrapper">
						<div className="empty-table">No Notifications Found.</div>
					</div>
				)}
			</Root>
		);
	}
	getTime(time) {
		const notTime = new Date(time).getTime();
		const currentTime = new Date().getTime();
		let timeDiff = currentTime - notTime;
		let timeDiffMins = Math.floor(timeDiff / 60000);
		let timeDiffHours = Math.floor(timeDiffMins / 60);
		let timeDiffDays = Math.floor(timeDiffHours / 24);
		let timeDiffMonths = Math.floor(timeDiffDays / 30);
		let timeDiffYears = Math.floor(timeDiffMonths / 12);
		if (timeDiffYears > 0) {
			if (timeDiffYears > 1) return timeDiffYears + " years";
			else return "1 year";
		}
		if (timeDiffMonths > 0) {
			if (timeDiffMonths > 1) return timeDiffMonths + " months";
			else return "1 month";
		}
		if (timeDiffDays > 0) {
			if (timeDiffDays > 1) return timeDiffDays + " days";
			else return "1 day";
		}
		if (timeDiffHours > 0) {
			if (timeDiffHours > 1) return timeDiffHours + " hours";
			else return "1 hour";
		}
		if (timeDiffMins > 0) {
			if (timeDiffMins > 1) return timeDiffMins + " mins";
			else return "1 min";
		}
	}
	// ==================== Notification click and redirection ========================//
	onNotificationClick = (item) => {
		switch (item.notificationType) {
			case 0:
				this.props.history.push("/quotes/1");
				break;
			case 2:
				this.props.history.push("/orders/order-status/" + item.notificationData);
				break;
			case 3:
				this.props.history.push("/orders/order-status/" + item.notificationData);
				break;
			case 4:
				this.props.history.push("/quotes/1");
				break;
			case 6:
				this.props.history.push("/orders/order-status/" + item.notificationData);
				break;
			case 7:
				this.props.history.push("/orders/order-status/" + item.notificationData);
				break;
			case 11:
				this.props.history.push("/manage-artworks/1?Filter=4");
				break;
			case 12:
				this.props.history.push("/manage-artworks/1");
				break;
			case 13:
				this.props.history.push("/manage-artworks/1");
				break;
			case 16:
				this.props.history.push("/manage-users/1");
				break;
			case 17:
				this.props.history.push("/manage-users/1");
				break;
			case 18:
				this.props.history.push("/orders/order-status/" + item.notificationData);
				break;
			default:
				console.log("no action");
				break;
		}
	}
}

const mapStateToProps = state => {
	return { notificationReducer: state.notificationReducer };
};

const mapDispatchToProps = dispatch => {
	return {
		getNotificationsListAction: () => {
			dispatch(getNotificationsListAction());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
