import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Pagination, Table, Icon, Tooltip, Popover, Select } from "antd";
import { isArray, isEmpty, map } from "lodash";
//Files
import Root from "../Root/Root";
import Topbar from "../../components/Topbar/Topbar";
import { Util } from "../../util/util";
import { getGalleryList, setGalleryFeaturedAction } from "../../redux/actions/ManageGalleryAction";
// SCSS
import "./ManageGallery.scss";
import KycDocumentDialog from "../ManageUsers/KycDocumentDialog/KycDocumentDialog";
import { approveKYC, getUserKycUser, toggleKYCDialog, updateRoleFromManageArtistChangeAction } from "../../redux/actions/ManageusersAction";
import ConfirmationMessageDialog from "../../components/ConfirmationMessageDialog/ConfirmationMessageDialog";
import MultiSelectDropdown from "../../components/MultiSelectDropdown/MultiSelectDropdown";
const { Option } = Select;
const kycStatusList = [
	{ label: "KYC All Status", value: null },
	{ label: "Pending", value: 0 },
	{ label: "Completed", value: 1 },
	{ label: "Approved", value: 2 },
	{ label: "Not Applicable", value: 3 },
];

const galleryStatusList = [
	{ label: "Artist All Status", value: null },
	{ label: "Pending", value: 0 },
	{ label: "Approved", value: 1 },
	{ label: "Rejected", value: 2 },
	{ label: "Blocked", value: 3 },
];

class ManageGallery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchValue: "",
			pageNumValue: 1,
			orderSort: null,
			orderOption: null,
			isOpenKYCModal: false,
			unblockconfirmationDialog: false,
			approveconfirmationDialog: false,
			rejectconfirmationDialog: false,
			blockconfirmationDialog: false,
			selectedCoutry: [],
			status: null,
			userId: "",
			kycFilter: null,
			galleryStatus: null,
		};
	}

	componentDidMount() {
		// List Sellers by fetching details from URL - When Page Loads
		this.setSearchParams();
	}

	componentDidUpdate(prevProps) {
		// List Sellers by fetching details from URL - When URL change occur
		const locationChanged = this.props.location !== prevProps.location;
		if (locationChanged) {
			this.setSearchParams();
		}

	}

	render() {
		const { galleryListLoader, savedGalleryListCount, savedGalleryList } = this.props.manageGalleryReducer;
		const { kycDocumentResult, kycDialog } = this.props.manageUsersReducer;
		const options = { year: "numeric", month: "long", day: "numeric" };

		const columns = [
			{
				title: ({ sortOrder, sortColumn, filters }) => {
					if (sortOrder === "descend")
						return <Tooltip title="Click to cancel sort">Name</Tooltip>
					else if (sortOrder === "ascend")
						return <Tooltip title="Click sort by descend">Name</Tooltip>
					else return <Tooltip title="Click sort by ascend">Name</Tooltip>
				},
				className: "sorter-no-tooltip",
				dataIndex: "name",
				key: "name",
				render: (text, record) => (
					<span className="colWrapper" onClick={() => this.props.history.push("/manage-gallery/user-profile/" + record.userId)}>
						{record.profilePhoto ? (
							<img className="colImage" src={record.profilePhoto} alt="productImage" />
						) : (
							<Icon className="icon-image" type="user" />
						)}
						<Tooltip title={text ? text : "Nil"}>
							<p className="colText">{text ? text : "Nil"}</p>
						</Tooltip>
					</span>
				),
				sorter: true,
				sortDirections: ['ascend', 'descend'],
			},
			{
				title: "Gallery ID",
				dataIndex: "userId",
				key: "userId",
				render: (text, record) => <p onClick={() => this.props.history.push("/manage-gallery/user-profile/" + record.userId)}>{text}</p>
			},
			{
				title: ({ sortOrder, sortColumn, filters }) => {
					if (sortOrder === "descend")
						return <Tooltip title="Click to cancel sort">Join Date</Tooltip>
					else if (sortOrder === "ascend")
						return <Tooltip title="Click sort by descend">Join Date</Tooltip>
					else return <Tooltip title="Click sort by ascend">Join Date</Tooltip>
				},
				className: "sorter-no-tooltip",
				dataIndex: "joiningDate",
				key: "joiningDate",
				render: (text, record) => (
					<p className="textBlack" onClick={() => this.props.history.push("/manage-gallery/user-profile/" + record.userId)}>
						{new Date(text).toLocaleDateString("en-IN", options)}
					</p>
				),
				sorter: true,
				sortDirections: ['ascend', 'descend'],
			},
			{
				title: "Owned Artworks",
				dataIndex: "ownedArtWorks",
				key: "ownedArtWorks",
				render: (text, record) => (
					<p className="artworkcountText" onClick={() => this.props.history.push("/manage-gallery/user-profile/" + record.userId)}>
						{text}
					</p>
				)
			},
			{
				title: "Country",
				dataIndex: "place",
				key: "place",
				sorter: true,
				sortDirections: ["ascend", "descend"],
				render: (text, record) => (
					<p className="textBlack" onClick={() => this.props.history.push("/manage-artists/user-profile/" + record.userId)}>
						{text}
					</p>
				),
			},

			{
				title: "Approval Date",
				dataIndex: "approveDate",
				key: "approveDate",
				sorter: true,
				sortDirections: ["ascend", "descend"],
				render: (text, record) => (
					<p className="textBlack" onClick={() => this.props.history.push("/manage-artists/user-profile/" + record.userId)}>
						{text ? new Date(text).toLocaleDateString("en-IN", options) : ""}
					</p>
				),
			},
			{
				title: "Last Featured Date",
				dataIndex: "lastFeaturedDate",
				key: "lastFeaturedDate",
				sorter: true,
				sortDirections: ["ascend", "descend"],
				render: (text, record) => (
					<p className="textBlack" onClick={() => this.props.history.push("/manage-artists/user-profile/" + record.userId)}>
						{text ? new Date(text).toLocaleDateString("en-IN", options) : ""}
					</p>
				),
			},

			{
				title: ({ sortOrder, sortColumn, filters }) => {
					if (sortOrder === "descend")
						return <Tooltip title="Click sort by ascend">Featured</Tooltip>
					else if (sortOrder === "ascend")
						return <Tooltip title="Click to cancel sort">Featured</Tooltip>
					else return <Tooltip title="Click sort by descend">Featured</Tooltip>
				},
				className: "sorter-no-tooltip",
				key: "isFeatured",
				dataIndex: "isFeatured",
				render: (text, record) => {
					if (!text) {
						return (
							<img
								className="star-image"
								src={require("../../assets/images/common/Star.svg")}
								onClick={this.onFeatured.bind(this, record)}
								alt="star"
							/>
						);
					} else {
						return (
							<img
								className="star-image"
								src={require("../../assets/images/common/Star_Red.svg")}
								onClick={this.onUnFeatured.bind(this, record)}
								alt="star"
							/>
						);
					}
				},
				sorter: true,
				sortDirections: ['ascend', 'descend'],
			},
			{
				title: "KYC",
				key: "kycStatus",
				dataIndex: "kycStatus",
				render: (text) => {
					return (
						<div>
							{text === 0 && <p className="textOrange">Pending</p>}
							{text === 1 && <p className="textBrown">Completed</p>}
							{text === 2 && <p className="textGreen">Approved</p>}
							{text === 3 && <p className="textGreen">Not Applicable</p>}
						</div>
					);
				}
			},
			{
				title: "Artist Status",
				key: "status",
				dataIndex: "status",
				sorter: true,
				sortDirections: ["ascend", "descend"],
				render: (text) => {
					return (
						<div>
							{text === 0 && <p className="textOrange">Pending</p>}
							{text === 1 && <p className="textGreen">Approved</p>}
							{text === 2 && <p className="textBrown">Rejected</p>}
							{text === 3 && <p className="textRed">Blocked</p>}
						</div>
					);
				},
			},
			{
				title: "Action",
				key: "action",
				render: record =>
					<Popover
						placement="bottom"
						content={content(record)}
						overlayClassName="manage-artworks-action-popover"
					// onVisibleChange={this.onVisibleChangeOfArtworkRecord.bind(this, record)}
					>
						<img className="action-image" src={require("../../assets/images/common/artworksActionIcon.svg")} alt="action" />
					</Popover>
			}
		];
		const content = record => (
			<div>
				{record.status === 0 && (
					<span className="action-span" onClick={this.toggleApproveConfirmationDialog.bind(this, record, 1)}>
						{/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
						<Icon type="check" />
						<p className="pop-item">Approve</p>
					</span>
				)}
				{record.status === 0 && (
					<span className="action-span" onClick={this.toggleRejectConfirmationDialog.bind(this, record, 2)}>
						{/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
						<Icon type="close" />
						<p className="pop-item">Reject</p>
					</span>
				)}
				{((record.kycStatus !== 2) && (record.kycStatus !== 3)) && (
					<span className="action-span" onClick={this.onClickUserKyc.bind(this, true, record)}>
						{/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
						<Icon type="close" />
						<p className="pop-item">KYC clearance </p>

					</span>
				)}
				{record.kycStatus === 2 && (
					<span className="action-span" onClick={this.onClickUserKyc.bind(this, true, record)}>
						{/* <img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" /> */}
						<Icon type="close" />
						<p className="pop-item">Review KYC</p>

					</span>
				)}

				<div className="action-span" onClick={this.onClickEditArtistInfo.bind(this, true, record)}>
					<img src={require("../../assets/images/common/Edit_New.svg")} alt="edit" />
					<p className="pop-item">Edit</p>
				</div>
				<div className="action-span" onClick={this.onClickBlockArtist.bind(this, true, record)}>
					<img src={require("../../assets/images/common/Edit_New.svg")} alt="Block" />
					<p className="pop-item">Block</p>
				</div>
			</div>
		);
		const { countriesList } = this.props.countriesTaxReducer;
		const countryList = map(countriesList, (o) => o.name) || [];
		return (
			<Root>
				<Topbar title={"Manage Gallery"} showSearch searchValue={this.state.searchValue} onChangeSearch={this.onChangeSearch.bind(this)} />
				<div className="managegallery-wrapper">
					<div className="filter-wrapper">

						<MultiSelectDropdown
							data={countryList}
							showCheckbox={true}
							selectedCoutry={this.state.selectedCoutry} // Preselected value to persist in dropdown
							onSetCountryArray={this.onSetCountryArray.bind(this)} // Function will trigger on select event
							onRemoveCountryArray={this.onRemoveCountryArray.bind(this)} // Function will trigger on remove event
							isObject={false}
							placeholder="Choose Country"
						/>
						<Select className="filter-field" value={this.state.kycFilter} onChange={this.onChangeFilterKyc(this)}>
							{kycStatusList.map((item, index) => (
								<Option key={index} value={item.value}>
									{item.label}
								</Option>
							))}
						</Select>
						<Select className="filter-field" value={this.state.galleryStatus} onChange={this.onChangeFilterArtistStatus(this)}>
							{galleryStatusList.map((item, index) => (
								<Option key={index} value={item.value}>
									{item.label}
								</Option>
							))}
						</Select>
					</div>

					<Table loading={galleryListLoader} rowKey={record => record._id} columns={columns} dataSource={savedGalleryList} showSorterTooltip sticky onChange={this.onChangeForSort.bind(this)} />


				</div>
				<KycDocumentDialog
					open={kycDialog}
					approveKYC={this.approveKYC.bind(this)}
					handleClose={this.onToggleKycModel.bind(this, false)}
					data={kycDocumentResult && kycDocumentResult}
				/>
				<ConfirmationMessageDialog
					open={this.state.blockconfirmationDialog}
					handleClose={this.toggleBlockConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to block this user?"}
					onConfirm={this.onBlockUser.bind(this)}
				/>

				<ConfirmationMessageDialog
					open={this.state.approveconfirmationDialog}
					handleClose={this.toggleApproveConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to approve this user?"}
					onConfirm={this.onApproveUser.bind(this)}
				/>
				<ConfirmationMessageDialog
					open={this.state.rejectconfirmationDialog}
					handleClose={this.toggleRejectConfirmationDialog.bind(this)}
					confirmMessage={"Are you sure you want to reject this user?"}
					onConfirm={this.onRejectUser.bind(this)}
				/>
			</Root>
		);
	}

	onSetCountryArray = (list) => {
		this.setState(
			{
				selectedCoutry: list,
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	};
	onRemoveCountryArray = (list) => {
		this.setState(
			{
				selectedCoutry: list,
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	};

	onChangeFilterKyc = (name) => (event) => {
		this.setState(
			{
				kycFilter: event,
			},
			() => {
				let request = {
					pageNum: this.state.pageNumValue,
					pageSize: 12,
					searchKey: this.state.searchValue || "",
					CountryName: this.state.selectedCoutry || [],
					KYCStatus: this.state.kycFilter,
					GalleryStatus: this.state.galleryStatus,
				};
				this.props.getGalleryList(request, true);
			}
		);
	};

	onChangeFilterArtistStatus = (name) => (event) => {
		this.setState(
			{
				galleryStatus: event,
			},
			() => {
				let request = {
					pageNum: this.state.pageNumValue,
					pageSize: 12,
					searchKey: this.state.searchValue || "",
					CountryName: this.state.selectedCoutry || [],
					KYCStatus: this.state.kycFilter,
					GalleryStatus: this.state.galleryStatus,
				};
				this.props.getGalleryList(request);
			}
		);
	};


	onClickEditArtistInfo = (value, item) => {
		this.setState(
			{
				selectedRecordToPerformAction: item,
			},
			() => {
				// Toggle dialog
				this.props.toggleArtistInfoDialog(value);
			}
		);
	};
	onClickBlockArtist = (value, item) => {
		this.setState(
			{
				selectedRecordToPerformAction: item,
				blockconfirmationDialog: true,
			},
			() => {
				this.setState({
					userId: item.userId,
					status: 3,
				});
			}
		);
	};

	//----------------Dialog for approve artwork-----------------//
	toggleApproveConfirmationDialog(item, status) {
		console.log("🚀 ~ file: ManageArtists.js ~ line 562 ~ ManageArtists ~ toggleApproveConfirmationDialog ~ item", item);
		this.setState(
			{
				approveconfirmationDialog: !this.state.approveconfirmationDialog,
			},
			() => {
				this.setState({
					userId: item.userId,
					status: status,
					userRole: "GALLERY",
				});
			}
		);
	}
	//-------------Dialog for reject artwork---------------------//
	toggleRejectConfirmationDialog(item, status) {
		this.setState(
			{
				rejectconfirmationDialog: !this.state.rejectconfirmationDialog,
			},
			() => {
				this.setState({
					userId: item.userId,
					status: status,
				});
			}
		);
	}

	toggleBlockConfirmationDialog = () => {
		this.setState(
			{
				blockconfirmationDialog: !this.state.blockconfirmationDialog,
			},
			() => {
				// Toggle dialog
			}
		);
	};

	handleReset() {
		this.setState({
			...this.state,
			orderSort: null,
			orderOption: null,
			isOpenKYCModal: false,
			unblockconfirmationDialog: false,
			approveconfirmationDialog: false,
			rejectconfirmationDialog: false,
			blockconfirmationDialog: false,
		});
	}

	async onBlockUser() {
		// this.props.blockArtistAction(this.state.selectedRecordToPerformAction.userId);
		await this.props.updateRoleFromManageArtistChangeAction(this.state.userId, 3, "GALLERY");
		this.handleReset();
		this.doSearchFilterUsers();
	}

	//-------------Approve User Confirm---------------------//
	async onApproveUser() {
		await this.props.updateRoleFromManageArtistChangeAction(this.state.userId, this.state.status, "GALLERY");
		this.handleReset();
		this.doSearchFilterUsers();
	}

	//-------------Reject User Confirm---------------------//
	async onRejectUser() {
		await this.props.updateRoleFromManageArtistChangeAction(this.state.userId, this.state.status, "GALLERY");
		this.handleReset();
		this.doSearchFilterUsers();
	}

	onChangeForSort(pagination, filters, sorter) {
		if (!isEmpty(sorter)) {
			this.setState({
				orderSort: sorter.order === "descend" ? 1 : 0,
				orderOption: sorter.columnKey === "name" ? 0 : sorter.columnKey === "joiningDate" ? 1 : 2
			}, () => {
				let request = {
					pageNum: this.state.pageNumValue,
					pageSize: 12,
					searchValue: this.state.searchValue || "",
					sortOrder: sorter.order === "descend" ? 1 : 0,
					sortOption: sorter.columnKey === "name" ? 0 : sorter.columnKey === "joiningDate" ? 1 : 2
				};
				this.props.getGalleryList(request, true);
			});
		}
		else {
			this.setState({
				orderSort: null,
				orderOption: null
			}, () => {
				let request = {
					pageNum: this.state.pageNumValue,
					pageSize: 12,
					searchValue: this.state.searchValue || ""
				};
				this.props.getGalleryList(request);
			});
		}
	}
	// ------------------------ When Page loads/ URL changes ------------------------- //
	// Call API by fetching details from URL
	setSearchParams() {
		const { location } = this.props;

		// When filter/Search params exist
		if (location.search) {
			let parsedQuery = Util.queryStringHandling(location.search, "PARSE");
			this.setState(
				{
					searchValue: parsedQuery.Search,
					pageNumValue: this.props.match.params.pageNum
				},
				() => {
					this.doSearchFilterUsers(parsedQuery);
				}
			);
		}

		// When there is no filter/Search params
		else {
			this.setState({ pageNumValue: this.props.match.params.pageNum }, () => {
				this.doSearchFilterUsers();
			});
		}
	}

	// --------------------- When filter/search/pagination events trigger --------------------- //

	//Pagination
	onSelectPageNumber = name => event => {
		this.setState({ pageNumValue: event }, () => {
			this.doChangeUrlWithQuery();
		});
	};

	// Search
	onChangeSearch(search) {
		this.setState(
			{
				searchValue: search,
				pageNumValue: 1
			},
			() => {
				this.doChangeUrlWithQuery();
			}
		);
	}

	// Change URL based on filter/search/Page values
	doChangeUrlWithQuery() {
		let searchCriteria = {
			Search: this.state.searchValue
		};
		const queryString = Util.queryStringHandling(searchCriteria, "STR");
		this.props.history.push("/manage-gallery/" + this.state.pageNumValue + "?" + queryString);
	}

	// ----------------------- API - Fetch Sellers ----------------------------- //
	doSearchFilterUsers() {
		// POST request body
		if (!this.state.orderSort && !this.state.orderOption) {
			let request = {
				PageNum: this.state.pageNumValue,
				PageSize: 12,
				SortOrder: 1,
				UserSortOptions: 1
			};
			this.props.getGalleryList(request);

		}
		else {
			let request = {
				PageNum: this.state.pageNumValue,
				PageSize: 12,
				SortOrder: 1,
				UserSortOptions: 1
			};
			this.props.getGalleryList(request, true);
		}
	}

	// -------------------------- User Profile -------------------------- //
	toggleUserProfile(path, item) {
		window.location.href = "/#/" + path + "/" + item.userId;
		this.props.setUserProfile(item);
	}

	async onClickUserKyc(value, item) {
		await this.props.toggleKYCDialog(value);
		await this.props.getUserKycUser(item.userId);

	}
	async approveKYC(userId, isType) {
		await this.props.approveKYC(userId, isType);
		await this.doSearchFilterUsers();

	}
	onToggleKycModel(value) {
		// Toggle dialog
		this.props.toggleKYCDialog(value);
	}

	//Set Featured Artist
	onFeatured(item) {
		let request = {
			userId: item.userId,
			featureStatus: true
		};
		let gallerytData = {
			pageNum: this.state.pageNumValue,
			pageSize: 12,
			searchValue: this.state.searchValue || ""
		};
		this.props.setGalleryFeaturedAction(request, gallerytData);
	}
	//Set Featured Artist
	onUnFeatured(item) {
		let request = {
			userId: item.userId,
			featureStatus: false
		};
		let gallerytData = {
			pageNum: this.state.pageNumValue,
			pageSize: 12,
			searchValue: this.state.searchValue || ""
		};
		this.props.setGalleryFeaturedAction(request, gallerytData);
	}
}
const mapStateToProps = state => {
	return {
		manageGalleryReducer: state.manageGalleryReducer,
		manageUsersReducer: state.manageUsersReducer,
		countriesTaxReducer: state.countriesTaxReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getGalleryList: (galleryinput, isSort) => {
			dispatch(getGalleryList(galleryinput, isSort));
		},
		setGalleryFeaturedAction: (featureData, galleryinput) => {
			dispatch(setGalleryFeaturedAction(featureData, galleryinput));
		},
		getUserKycUser: (userId) => {
			dispatch(getUserKycUser(userId));
		},
		approveKYC: (userId, isType) => {
			dispatch(approveKYC(userId, isType))
		},
		toggleKYCDialog: value => {
			dispatch(toggleKYCDialog(value));
		},
		updateRoleFromManageArtistChangeAction: (userId, status, userRole) => {
			dispatch(updateRoleFromManageArtistChangeAction(userId, status, userRole));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageGallery);
