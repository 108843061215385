import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent, CircularProgress } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { last, isEmpty, includes, map } from "lodash";
// Redux
import { connect } from "react-redux";
// SCSS
import "./AddCollectionDialog.scss";
import { popToast } from "../../../components/Toast/Toast";
import { Cascader, Select, Empty, Pagination, Divider, Checkbox } from "antd";
import {
	getAllCategoriesForCascadeMenu,
	setCollectionReducerStates,
	getAllArtists,
	getFilterCategoryProperties,
	searchArtworks,
	addCollection,
	deleteItemFromCollection,
	updateCollection,
	getCollectionsByIdAction,
} from "../../../redux/actions/CollectionAction";
import FilterDropdown from "./FilterDropdown/FilterDropdown";
import ArtworkCard from "../ArtworkCard/ArtworkCard";
import FilterSlider from "./FilterSlider/FilterSlider";
import { getAllLookUpProperty, deletePropertyValues } from "../../../redux/actions/LookupAction";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const { Option } = Select;

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

// fake data generator
const getItems = (count) =>
	Array.from({ length: count }, (v, k) => k).map((k) => ({
		id: `item-${k}`,
		content: `item ${k}`,
	}));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",
	padding: grid * 2,
	margin: `0 ${grid}px 0 0`,

	// change background colour if dragging
	background: isDragging ? "lightgreen" : "grey",

	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
	background: isDraggingOver ? "lightblue" : "lightgrey",
	display: "flex",
	padding: grid,
	overflow: "auto",
});
class AddCollectionDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			collectionName: "",
			slugId: "",
			description: "",
			// oneLiner: "",
			curatorName: "",
			curatorInfo: "",
			media: "",
			mediaThumb: "",
			sortOptions: [
				{ value: "0", name: "Price (Low to High)" },
				{ value: "1", name: "Price (High to Low)" },
				{ value: "2", name: "Popularity" },
			],

			selectedCategory: "",
			selectedArtists: [],
			selectedFilters: [],
			selectedFiltersIDArray: [],
			priceMin: 0,
			priceMax: 10000000,
			selectedSortValue: "0",
			searchInput: "",
			selectedArtworks: [],
			selectedCollectionArtworkForList: [],

			collectionItems: [],
			collectionBanner: null,
			pageNumValue: 1,
			selectedStatus: "",
			seoTitle: "",
			seoDescription: "",
			collectionCategory: "",
			selectedCollectionCategory: [],
			isFeatured: false,
			editorState: EditorState.createEmpty(),
		};
		this.onDragEnd = this.onDragEnd.bind(this);
	}
	componentDidMount() {
		let body = {
			PropertyNames: ["COLLECTIONCATEGORY"],
		};
		this.props.getAllLookUpProperty(body);
	}
	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.open && this.props.open) {
			const collData = this.props.updatingCollection;
			if (this.props.isUpdating) {
				this.props.getCollectionsByIdAction(collData.slugId);
			}

			// this.props.getAllCategories();
			this.props.getAllArtists();
			this.props.isUpdating
				? this.setState({
						collectionName: collData.collectionName,
						slugId: collData.slugId,
						description: collData.description,
						// oneLiner: collData.oneLiner,
						curatorName: collData.curatorName,
						curatorInfo: collData.curatorInfo,
						media: null,
						mediaThumb: collData.collectionBanner,
						selectedArtworks: [],
						collectionItems: [],
						collectionBanner: collData.collectionBanner,
						selectedStatus: collData.collectionStatus,

						seoTitle: collData.seoTitle,
						seoDescription: collData.seoDescription,
						collectionCategory: collData.collectionCategoty !== null ? collData.collectionCategoty._id : "",
						selectedCollectionCategory: collData.collectionCategoty !== null ? collData.collectionCategoty : [],
						isFeatured: collData.isFeatured,
						editorState:
							(collData.description &&
								EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(collData.description)))) ||
							EditorState.createEmpty(),

						selectedCategory: "",
						selectedArtists: [],
						selectedFilters: [],
						selectedFiltersIDArray: [],
						priceMin: 0,
						priceMax: 10000000,
						selectedSortValue: "0",
						searchInput: "",
				  })
				: this.setState({
						collectionName: "",
						slugId: "",
						description: "",
						// oneLiner: "",
						curatorName: null,
						curatorInfo: null,
						media: "",
						mediaThumb: "",
						selectedArtworks: [],
						collectionItems: [],
						collectionBanner: null,

						selectedCategory: "",
						selectedArtists: [],
						selectedFilters: [],
						selectedFiltersIDArray: [],
						priceMin: 0,
						priceMax: 10000000,
						selectedSortValue: "0",
						searchInput: "",
						selectedStatus: "",

						seoTitle: "",
						seoDescription: "",
						collectionCategory: "",
						isFeatured: false,
						editorState: EditorState.createEmpty(),
				  });

			// if (this.props.isViewing)
			// 	this.setState({
			// 		collectionItems: collData.collectionItems
			// 	});

			this.getSearchFilteredProducts();
		}

		if (prevProps.collectionReducer.deletedItemFromCollection !== this.props.collectionReducer.deletedItemFromCollection) {
			if (this.props.collectionReducer.deletedItemFromCollection) {
				this.setState({
					collectionItems: this.state.collectionItems.filter((item) => item._id !== this.props.collectionReducer.deletedItemFromCollection),
				});
			}
		}
		if (prevProps.collectionReducer.collectionsItemsByIdList !== this.props.collectionReducer.collectionsItemsByIdList) {
			this.setState({
				selectedArtworks: map(this.props.collectionReducer.collectionsItemsByIdList.collectionItems, "_id"),
				selectedCollectionArtworkForList: this.props.collectionReducer.collectionsItemsByIdList.collectionItems,
			});
		}
	}

	onChangeCollectionStatus(e) {
		this.setState({
			selectedStatus: e.target.value,
		});
	}
	onChangeCollectionCategory(e) {
		const { allPropertyList } = this.props.lookupReducer;
		let selectedCollectionCategory = allPropertyList.collectionCategory.filter((item) => item._id === e.target.value);
		let obj = {
			_id: selectedCollectionCategory[0]._id,
			SlugId: selectedCollectionCategory[0].slugId,
			Name: selectedCollectionCategory[0].name,
		};
		console.log(
			"🚀 ~ file: AddCollectionDialog.js ~ line 174 ~ AddCollectionDialog ~ onChangeCollectionCategory ~ selectedCollectionCategory",
			obj
		);
		this.setState({
			collectionCategory: e.target.value,
			selectedCollectionCategory: obj,
		});
	}
	// On Checkbox changes
	handleCheckbox(event, name) {
		this.setState({
			[name]: event.target.checked,
		});
	}
	onEditorStateChange = () => (editorState) => {
		this.setState({
			editorState,
		});
	};

	onDragEnd(result) {
		console.log("🚀 ~ file: CollectionDrag.js ~ line 87 ~ CollectionDrag ~ onDragEnd ~ result", result);
		// dropped outside the list
		if (!result.destination) {
			return;
		}
		const { filteredArtworks } = this.props.collectionReducer;
		const items = reorder(this.state.selectedCollectionArtworkForList, result.source.index, result.destination.index);
		console.log("🚀 ~ file: AddCollectionDialog.js ~ line 263 ~ AddCollectionDialog ~ onDragEnd ~ items", items);
		const selectedArtWorkIdsReorder = reorder(this.state.selectedArtworks, result.source.index, result.destination.index);
		console.log(
			"🚀 ~ file: AddCollectionDialog.js ~ line 264 ~ AddCollectionDialog ~ onDragEnd ~ selectedArtWorkIdsReorder",
			selectedArtWorkIdsReorder
		);
		this.setState({
			items,
			selectedCollectionArtworkForList: items,
			selectedArtworks: selectedArtWorkIdsReorder,
		});
	}

	render() {
		const {
			modalLoader,
			categoryLoader,
			categoryList,
			parentArrayList,
			artistsList,
			categoryPropertyList,
			filteredArtworksLoader,
			filteredArtworks,
			addingCollectionLoader,
		} = this.props.collectionReducer;
		const viewingItem = this.props.updatingCollection;
		const filterArtworksList = (filteredArtworks && filteredArtworks.data && filteredArtworks.data.productList) || [];
		const filterArtworksCount = (filteredArtworks && filteredArtworks.data && filteredArtworks.data.count) || 0;
		const { selectedArtworks, selectedCollectionArtworkForList, editorState } = this.state;
		const { allPropertyList, isLoading } = this.props.lookupReducer;

		return (
			<Dialog
				fullScreen
				open={this.props.open}
				onClose={this.props.handleClose}
				BackdropProps={{ classes: { root: "dialog-backdrop" } }}
				className="add-collection-dialog"
				TransitionComponent={Transition}
			>
				<img className="close-btn" onClick={this.props.handleClose} src={require("../../../assets/images/common/Close_h.svg")} alt="" />
				<DialogTitle className={`collection-dialog-title ${this.props.isViewing ? "hideBorder" : ""}`}>
					{this.props.isViewing ? viewingItem.collectionName || "Collection" : "Collection"}
					<p className="collection-dialog-subtitle">Create Collection by adding your images</p>
				</DialogTitle>
				<DialogContent className="add-collection-content">
					{this.props.isViewing ? (
						// {/* ====================== DISPLAY ========================== */}
						<div className="add-collection-display-body">
							<img src={viewingItem.collectionBanner} alt="banner" />
							<p>{viewingItem.description}</p>
						</div>
					) : (
						// {/* ====================== FORM ========================== */}
						<div className="add-collection-body">
							{this.state.mediaThumb ? (
								<label className="upload-box bg-white" onClick={(e) => this.attach_media.click()}>
									<img className="media-thumb" src={this.state.mediaThumb} alt="Attach media" />
								</label>
							) : (
								<div className="upload-box" onClick={(e) => this.attach_media.click()}>
									<img className="image-upload" src={require("../../../assets/images/common/AddPhoto.svg")} alt="Upload" />
									Upload icon
								</div>
							)}
							<input
								type="file"
								style={{ display: "none" }}
								accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
								onChange={(e) => this.onImageSelect(e)}
								// disabled={modalLoader}
								ref={(ref) => (this.attach_media = ref)}
							/>
							<div className="form-control-wrap">
								<div className="form-control-row-wrap">
									<div className="form-control">
										<label className="form-label">Collection Name</label>
										<input
											type="text"
											autoFocus
											className="form-input"
											value={this.state.collectionName}
											maxLength="50"
											// disabled={modalLoader}
											onChange={this.onHandleInput("collectionName")}
										/>
									</div>
									{/* <div className="form-control">
										<label className="form-label">One Line Description</label>
										<input
											type="text"
											maxLength="50"
											className="form-input"
											value={this.state.oneLiner}
											// disabled={modalLoader}
											onChange={this.onHandleInput("oneLiner")}
										/>
									</div> */}
								</div>
								<div className="form-control">
									<label className="form-label">SlugId</label>
									<input
										type="text"
										className="form-input"
										value={this.state.slugId}
										maxLength="50"
										// disabled={modalLoader}
										onChange={this.onHandleInput("slugId")}
									/>
								</div>

								<div className="form-control">
									<label className="form-label">Description</label>
									<Editor
										toolbar={{
											options: ["blockType", "inline", "list", "image"],
											inline: {
												visible: true,
												inDropdown: false,
												options: ["bold", "italic", "underline"],
											},
											image: {
												urlEnabled: true,
												uploadEnabled: false,
												alignmentEnabled: true,
												previewImage: true,
												inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
												alt: { present: false, mandatory: false },
												defaultSize: {
													height: "auto",
													width: "auto",
												},
											},
											inputAccept:
												"application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel",
										}}
										editorState={editorState}
										onEditorStateChange={this.onEditorStateChange()}
										editorStyle={{
											height: "200px",
											border: "1px solid #E5E5E5",
											padding: "10px",
											fontFamily: "Times New Roman",
											color: "black",
											fontWeight: "100",
											width: "100%",
										}}
										placeholder="Add some contents here"
									/>
									{/* <textarea
										className="form-input"
										value={this.state.description}
										// disabled={modalLoader}
										onChange={this.onHandleInput("description")}
									/> */}
								</div>

								<div className="form-control">
									<label className="form-label">Collection Category</label>
									<select
										className="form-input"
										onChange={this.onChangeCollectionCategory.bind(this)}
										value={this.state.collectionCategory}
									>
										<option value="">Choose Category</option>
										{allPropertyList &&
											allPropertyList.collectionCategory &&
											allPropertyList.collectionCategory.map((data, index) => <option value={data._id}>{data.name}</option>)}
									</select>
								</div>
								<div className="form-control">
									<label className="form-label">SEO Title</label>
									<input
										type="text"
										className="form-input"
										value={this.state.seoTitle}
										// disabled={modalLoader}
										onChange={this.onHandleInput("seoTitle")}
									/>
								</div>
								<div className="form-control">
									<label className="form-label">SEO Description</label>
									<textarea
										className="form-input"
										value={this.state.seoDescription}
										// disabled={modalLoader}
										onChange={this.onHandleInput("seoDescription")}
									/>
								</div>
								<div className="form-control">
									<label className="form-label">Curator Name</label>
									<input
										type="text"
										className="form-input"
										value={this.state.curatorName}
										maxLength="50"
										// disabled={modalLoader}
										onChange={this.onHandleInput("curatorName")}
									/>
								</div>
								<div className="form-control">
									<label className="form-label">Curator Info</label>
									<textarea
										className="form-input"
										value={this.state.curatorInfo}
										// disabled={modalLoader}
										onChange={this.onHandleInput("curatorInfo")}
									/>
								</div>
								<div className="form-control">
									<label className="form-label">Collection Status</label>
									<select
										className="form-input"
										onChange={this.onChangeCollectionStatus.bind(this)}
										value={this.state.selectedStatus}
									>
										<option value="">Choose Status</option>
										<option value="0">Draft</option>
										<option value="1">Exclusive</option>
										<option value="2">Publish</option>
									</select>
								</div>
								<div className="form-control form-checkbox">
									<Checkbox checked={this.state.isFeatured} onChange={(event) => this.handleCheckbox(event, "isFeatured")}>
										Is Featured
									</Checkbox>
								</div>
							</div>
						</div>
					)}

					{/* ====================== Existing Collection Item ========================== */}
					{this.props.isViewing && this.state.collectionItems && this.state.collectionItems.length > 0 && (
						<div className="add-collection-list-body">
							<p className="collection-dialog-subtitle">Items in collection</p>
							<div className="filter-artworks-wrapper">
								{this.state.collectionItems.map((item, index) => (
									<ArtworkCard
										data={item}
										key={index}
										showDelete={true}
										confirmDeleteAction={this.confirmDeleteAction.bind(this)}
									/>
								))}
							</div>
						</div>
					)}

					{/* =========================== Filter Collection Items ========================== */}
					{!this.props.isViewing && (
						<div className="add-collection-filter-body">
							<input
								className="search-input"
								type="text"
								placeholder="What are you looking for?"
								value={this.state.searchInput}
								onChange={(e) => this.onChangeSearchInput(e)}
							/>

							<div className="filter-wrapper">
								{/* -------- Category -------- */}
								{/* TODO : Placeholder */}
								<Cascader
									className="scroll-cascader"
									// allowClear={false}
									style={{ margin: "0 3px 3px 3px" }}
									popupClassName="scroll-cascader-menu"
									options={categoryList || []}
									expandTrigger={document.documentElement.clientWidth < 1000 ? "click" : "hover"}
									changeOnSelect={true}
									value={parentArrayList}
									onChange={(e) => this.onChangeCascaderValue(e)}
									notFoundContent="No subcategories found"
									fieldNames={{
										label: "categoryName",
										value: "_id",
										children: "subCategories",
									}}
									placeholder={categoryLoader ? "Fetching categories..." : "Please select"}
								/>
								{/* -------- Artists -------- */}
								<FilterDropdown
									selectedList={this.state.selectedArtists}
									checkboxList={artistsList && artistsList.artistList}
									idToFetch="userId"
									nameToDisplay="name"
									filterFor="Artist"
									returnBackQueryArray={this.onSetArtistArray.bind(this)}
								/>

								{/* -------- Slider -------- */}
								<FilterSlider
									filterFor="Price"
									title="PRICE"
									defaultMinValue={0}
									defaultMaxValue={10000000}
									minValue={this.state.priceMin}
									maxValue={this.state.priceMax}
									returnBackPriceObj={this.onSetPrice.bind(this)}
								/>

								{/* -------- Filter -------- */}
								{/* {getPropertyLoader && "Fetching filters..."} */}
								{categoryPropertyList &&
									categoryPropertyList.map(
										(property, index) =>
											property.filters &&
											property.filters.length > 0 && (
												<FilterDropdown
													key={index}
													selectedList={this.state.selectedFiltersIDArray}
													checkboxList={property.filters}
													idToFetch="filterId"
													nameToDisplay="displayText"
													isCustomCheckboxFn={true}
													filterFor={property.propertyName}
													returnBackCheckEvent={this.onSetFilter.bind(this)}
												/>
											)
									)}

								{/* -------- Sort -------- */}
								<div className="sort-dropdown">
									<div className="sort-dropdown-label">Sort by : </div>
									<Select
										dropdownClassName="sort-dropdown-select-options"
										className="sort-dropdown-select"
										mode="default"
										value={this.state.selectedSortValue}
										placeholder="Please select one"
										onChange={(e) => this.onHandleSortInput(e)}
									>
										{this.state.sortOptions.map((o, i) => (
											<Option key={o.value}>{o.name}</Option>
										))}
									</Select>
								</div>
							</div>
							{filteredArtworksLoader && (
								<div className="filter-artworks-searchLoader">
									<CircularProgress className="search-loader" />
									Searching...
								</div>
							)}
							{/* {this.props.isUpdating ? ( */}
							<>
								<Divider orientation="left">Selected Artworks</Divider>
								<div
									className={`filter-artworks-wrapper${selectedCollectionArtworkForList.length === 0 ? "filterWrapper-empty" : ""}${
										filteredArtworksLoader ? "filterWrapper-loader" : ""
									}`}
								>
									<DragDropContext onDragEnd={this.onDragEnd}>
										<Droppable droppableId="droppable" direction="horizontal">
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													style={getListStyle(snapshot.isDraggingOver)}
													{...provided.droppableProps}
												>
													{selectedCollectionArtworkForList.length > 0 ? (
														selectedCollectionArtworkForList.map((art, index) => (
															<Draggable key={art.id} draggableId={art._id} index={index}>
																{(provided, snapshot) => (
																	<div
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
																	>
																		<ArtworkCard
																			data={art}
																			key={index}
																			onClickArtwork={this.onClickArtworks.bind(this)}
																			isSelected={includes(this.state.selectedArtworks, art._id)}
																		/>
																	</div>
																)}
															</Draggable>
														))
													) : (
														<Empty description="No artworks found" />
													)}

													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</DragDropContext>
								</div>
							</>
							{/* ) : (
								<></>
							)} */}
							<>
								<Divider orientation="left">All Artworks</Divider>
								<div
									className={`filter-artworks-wrapper${filterArtworksList.length === 0 ? "filterWrapper-empty" : ""}${
										filteredArtworksLoader ? "filterWrapper-loader" : ""
									}`}
								>
									{filterArtworksList.length > 0 ? (
										filterArtworksList.map((art, index) => (
											<ArtworkCard
												data={art}
												key={index}
												onClickArtwork={this.onClickArtworks.bind(this)}
												isSelected={includes(this.state.selectedArtworks, art._id)}
											/>
										))
									) : (
										<Empty description="No artworks found" />
									)}
								</div>
								{!filteredArtworksLoader && (
									<Pagination
										current={parseInt(this.state.pageNumValue)}
										total={filterArtworksCount}
										pageSize={20}
										onChange={this.onSelectPageNumber(this)}
									/>
								)}
							</>
						</div>
					)}

					{/* =========================== Action Footer ========================== */}
					{!this.props.isViewing && (
						<div className="add-collection-footer-container">
							<p>Choose images and click add to collection to create your collection</p>
							<button className="btn-orange" onClick={this.onClickCreate.bind(this)} disabled={addingCollectionLoader}>
								{this.props.isUpdating ? "Update collection" : "Add to collection"}
							</button>
						</div>
					)}
				</DialogContent>
			</Dialog>
		);
	}

	convertToSlug = (Text) => {
		return Text.toLowerCase()
			.replace(/[^\w ]+/g, "")
			.replace(/ +/g, "-");
	};
	// Handle inputs
	onHandleInput = (name) => (event) => {
		if (name === "collectionName") {
			this.setState({
				slugId: this.convertToSlug(event.target.value),
			});
		}
		this.setState({
			[name]: event.target.value,
		});
	};

	// On select image
	onImageSelect(e) {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		let image = "";
		if (file) {
			reader.onloadend = () => {
				this.setState({
					media: file,
					mediaThumb: reader.result,
				});
				image = reader.result;
			};
			reader.readAsDataURL(file);
		}
	}

	isFormValid() {
		if (!this.state.collectionName.trim()) {
			popToast.show("Please enter a collection name");
			return false;
		}
		if (!this.state.slugId.trim()) {
			popToast.show("Please enter a slugId");
			return false;
		}
		// if (!this.state.description.trim()) {
		// 	popToast.show("Please enter a description about your collection");
		// 	return false;
		// }
		if (this.state.selectedStatus === "") {
			popToast.show("Please choose the collection status");
			return false;
		}
		if (this.state.collectionCategory === "") {
			popToast.show("Please choose the collection category");
			return false;
		}

		// if (!this.state.curatorName.trim()) {
		// 	popToast.show("Please enter curator name");
		// 	return false;
		// }
		// if (!this.state.curatorInfo.trim()) {
		// 	popToast.show("Please enter curator information");
		// 	return false;
		// }
		// if (!this.state.oneLiner) {
		// 	popToast.show("Please enter a one line description for your collection");
		// 	return false;
		// }
		if (!this.state.collectionBanner) {
			if (!this.state.media) {
				popToast.show("Please choose a collection image");
				return false;
			}
			if (this.state.media.type && !this.state.media.type.startsWith("image/")) {
				popToast.show("Please choose a valid image file");
				return false;
			}
		}
		if (isEmpty(this.state.collectionItems)) {
			if (isEmpty(this.state.selectedArtworks)) {
				popToast.show("Please add atleast one image into your collection");
				return false;
			}
		}
		return true;
	}
	// 	CuratorName = "Vishnu"' \
	// --form 'CuratorInfo = "Information info
	onClickCreate = async () => {
		// For main category, parentId is hardcoded value
		if (this.isFormValid()) {
			if (this.props.isUpdating) {
				let formBody = new FormData();
				formBody.append("CollectionName", this.state.collectionName);
				formBody.append("SlugId", this.state.slugId);
				// formBody.append("OneLiner", this.state.oneLiner);
				formBody.append("Description", draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));
				formBody.append("CuratorName", this.state.curatorName === null ? "" : this.state.curatorName);
				formBody.append("CuratorInfo", this.state.curatorInfo === null ? "" : this.state.curatorInfo);
				formBody.append("CollectionType", "ProductCollection");
				formBody.append("CollectionStatus", this.state.selectedStatus);
				formBody.append("SeoTitle", this.state.seoTitle === null ? "" : this.state.seoTitle);
				formBody.append("SeoDescription", this.state.seoDescription === null ? "" : this.state.seoDescription);
				formBody.append("IsFeatured", this.state.isFeatured);
				formBody.append("CollectionCategoty[_id]", this.state.selectedCollectionCategory._id);
				formBody.append("CollectionCategoty[Name]", this.state.selectedCollectionCategory.Name);
				formBody.append("CollectionCategoty[SlugId]", this.state.selectedCollectionCategory.SlugId);

				if (this.state.media) {
					formBody.append("collectionBanner", this.state.media);
				}
				for (let index = 0; index < this.state.selectedArtworks.length; index++) {
					formBody.append("Collection", this.state.selectedArtworks[index]);
				}
				for (let index = 0; index < this.state.collectionItems.length; index++) {
					formBody.append("Collection", this.state.collectionItems[index]._id);
				}
				await this.props.updateCollection(formBody, this.props.updatingCollection._id);
				await this.props.handleClose();
			} else {
				let formBody = new FormData();
				formBody.append("CollectionName", this.state.collectionName);
				formBody.append("SlugId", this.state.slugId);
				// formBody.append("OneLiner", this.state.oneLiner);
				formBody.append("Description", draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));
				formBody.append("CuratorName", this.state.curatorName === null ? "" : this.state.curatorName);
				formBody.append("CuratorInfo", this.state.curatorInfo === null ? "" : this.state.curatorInfo);
				formBody.append("CollectionType", "ProductCollection");
				formBody.append("CollectionStatus", this.state.selectedStatus);
				formBody.append("collectionBanner", this.state.media);
				formBody.append("SeoTitle", this.state.seoTitle === null ? "" : this.state.seoTitle);
				formBody.append("SeoDescription", this.state.seoDescription === null ? "" : this.state.seoDescription);
				formBody.append("CollectionCategoty[_id]", this.state.selectedCollectionCategory._id);
				formBody.append("CollectionCategoty[Name]", this.state.selectedCollectionCategory.Name);
				formBody.append("CollectionCategoty[SlugId]", this.state.selectedCollectionCategory.SlugId);
				formBody.append("IsFeatured", this.state.isFeatured);
				for (let index = 0; index < this.state.selectedArtworks.length; index++) {
					formBody.append("Collection", this.state.selectedArtworks[index]);
				}
				await this.props.addCollection(formBody);
				await this.props.handleClose();
			}
		}
	};

	onClickCancel() {
		this.props.handleClose();
	}

	// Set Search input
	onChangeSearchInput = (e) => {
		this.setState(
			{
				searchInput: e.target.value,
			},
			() => {
				this.getSearchFilteredProducts();
			}
		);
	};

	// On select category
	onChangeCascaderValue(itemDetails) {
		// To update the value in reducer // else while updating- value wont change
		this.props.onSetStates("parentArrayList", itemDetails);
		this.setState({ selectedCategory: last(itemDetails), selectedFilters: [], selectedFiltersIDArray: [] }, () => {
			this.getSearchFilteredProducts();
			if (this.state.selectedCategory) {
				this.props.fetchFilterCategoryProperties(this.state.selectedCategory);
			}
		});
	}

	// Set Artists Array
	onSetArtistArray = (list) => {
		this.setState(
			{
				selectedArtists: list,
			},
			() => {
				this.getSearchFilteredProducts();
			}
		);
	};

	// Set filter values
	onSetFilter = (event, filter) => {
		let list = this.state.selectedFilters;
		if (event.target.checked) {
			list = [...list, filter];
		} else {
			list = list.filter((item) => filter.filterId !== item.filterId);
		}
		this.setState(
			{
				selectedFilters: list,
				selectedFiltersIDArray: list.map((o) => o.filterId),
			},
			() => {
				this.getSearchFilteredProducts();
			}
		);
	};

	// Sort
	onHandleSortInput = (value) => {
		this.setState(
			{
				selectedSortValue: value,
			},
			() => {
				this.getSearchFilteredProducts();
			}
		);
	};

	// Set Price
	onSetPrice = (min, max) => {
		this.setState(
			{
				priceMin: min,
				priceMax: max,
			},
			() => {
				this.getSearchFilteredProducts();
			}
		);
	};

	// On click artwork
	onClickArtworks = (item) => {
		let list = this.state.selectedArtworks;
		let tempArtworkList = this.state.selectedCollectionArtworkForList;

		if (list.includes(item._id)) {
			list = list.filter((id) => id !== item._id);
			tempArtworkList = tempArtworkList.filter((dt) => dt._id !== item._id);
		} else {
			list = [...list, item._id];
			tempArtworkList = [...tempArtworkList, item];
		}
		this.setState({ selectedArtworks: list, selectedCollectionArtworkForList: tempArtworkList });
		console.log("🚀 ~ file: AddCollectionDialog.js ~ line 940 ~ AddCollectionDialog ~ tempArtworkList", tempArtworkList);
		console.log("🚀 ~ file: AddCollectionDialog.js ~ line 937 ~ AddCollectionDialog ~ list", list);
	};

	//Pagination
	onSelectPageNumber = (name) => (event) => {
		this.setState({ pageNumValue: event }, () => {
			this.getSearchFilteredProducts();
		});
	};

	getSearchFilteredProducts() {
		// TODO : Pagination
		let request = {
			CategoryId: this.state.selectedCategory || "",
			ProductName: this.state.searchInput,
			Sort: +this.state.selectedSortValue || 0,
			Satus: 0,
			PageNum: this.state.pageNumValue,
			PageSize: 20,
			ArtistId: this.state.selectedArtists || [],
			PriceMin: this.state.priceMin || 0,
			PriceMax: this.state.priceMax || 10000000,
			Filter: this.state.selectedFilters,
		};
		this.props.searchArtworks(request);
	}

	// Delete artworks from a collection
	confirmDeleteAction = async (data) => {
		const { _id } = this.props.updatingCollection;
		const { collectionItems } = this.state;
		if (collectionItems && collectionItems.length < 2) {
			popToast.show("Minimum one image should be in a collection.");
		} else if (data && data._id) {
			await this.props.deleteFromCollection(_id, data._id);
		}
	};
}

const mapStateToProps = (state) => {
	return {
		collectionReducer: state.collectionReducer,
		lookupReducer: state.lookupReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAllCategories: (parentId) => {
			dispatch(getAllCategoriesForCascadeMenu(parentId));
		},
		onSetStates: (state, value) => {
			dispatch(setCollectionReducerStates(state, value));
		},
		getAllArtists: () => {
			dispatch(getAllArtists());
		},
		fetchFilterCategoryProperties: (id) => {
			dispatch(getFilterCategoryProperties(id));
		},
		searchArtworks: (body) => {
			dispatch(searchArtworks(body));
		},
		addCollection: (body) => {
			dispatch(addCollection(body));
		},
		deleteFromCollection: (cId, itemId) => {
			dispatch(deleteItemFromCollection(cId, itemId));
		},
		updateCollection: (body, id) => {
			dispatch(updateCollection(body, id));
		},
		getCollectionsByIdAction: (slugId) => {
			dispatch(getCollectionsByIdAction(slugId));
		},
		getAllLookUpProperty: (body) => {
			dispatch(getAllLookUpProperty(body));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddCollectionDialog);
